import React from 'react'
import "./helps.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

function Helps() {
    return (
        <div className="container-helps">
            <FontAwesomeIcon icon={faQuestionCircle} className="help-icon"/>
            <p>Si vous avez besoin de l'aide, veuillez contacter votre administrateur.</p>
        </div>
    )
}

export default Helps
