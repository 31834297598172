import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Input,
  FormGroup,
  Label,
  Row,
  Col,
  Spinner
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill, faStore } from "@fortawesome/free-solid-svg-icons";
import { useFirestore, useFirebase } from "react-redux-firebase";
import ToastMessage from "../toast/toast";
import "./restaurants.scss";

const NewRestaurantForm = ({ isOpen, toggle, cities }) => {
  // const [uploadLogoProgress, setuploadLogoProgress] = useState(0);
  // const [isUploadLogo, setisUploadLogo] = useState(false);
  // const [uploadBannerProgress, setuploadBannerProgress] = useState(0);
  // const [isUploadBanner, setisUploadBanner] = useState(false);
  // const [logo, setlogo] = useState("");
  // const [banner, setbanner] = useState("");
  const [isSave, setisSave] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [showDanger, setshowDanger] = useState(false);

  const { register, handleSubmit } = useForm();
  const db = useFirestore();
  const firebase = useFirebase();

  const handleShowSuccess = () => {
    setshowSuccess(!showSuccess);
  };
  const handleShowDanger = () => {
    setshowDanger(!showDanger);
  };

  const onSubmit = data => {
    setisSave(true);
    const cityId = cities.find(city => city.city === data.city).id;
    const retaurantData = {
      // name: data.name,
      city: data.city,
      phone: data.phone,
      // email: data.email,
      // owner: data.owner,
      address: data.adresse,
      longLat: [data.longitude, data.latitude],
      // detail: "",
      // userCondition: data.userCondition,
      // slogan: "",
      // banner: banner,
      // logo: logo,
      specialty: "",
      createdAt: db.Timestamp.now().toMillis(),
      dlpsNumber:data.dlpsNumber
      // payementNumberAirtel:data.payementNumberAirtel,
      // payementNumberMpesa: data.payementNumberMpesa
    };
    // firebase
    //   .auth()
    //   .createUserWithEmailAndPassword(data.email, "resto@")
    //   .then(res => {
        db.collection("stores").doc(data.city).set({ ...retaurantData}).then(res => {
            db.update(`cities/${cityId}`, {
              available: true
            }).then(res => {
              setisSave(false);
              // setlogo("");
              // setbanner("");
              // setuploadLogoProgress(0);
              // setuploadBannerProgress(0);
              handleShowSuccess();
              toggle();
            });
          }
        ).catch(err => {
          setisSave(false);
          handleShowDanger();
        });;
      // })
      
  };

  // const onDropBanner = files => {
  //   setisUploadBanner(true);
  //   let uploadTask = firebase
  //     .storage()
  //     .ref()
  //     .child("banners/" + Date.now())
  //     .put(files[0]);

  //   uploadTask.on(
  //     "state_changed",
  //     snapshot => {
  //       var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //       console.log("Upload is " + progress + "% done");
  //       setuploadBannerProgress(progress);
  //     },
  //     error => {
  //       setisUploadBanner(false);
  //       console.log("Erreur d'upLoad");
  //     },
  //     () => {
  //       uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
  //         console.log(downloadURL);
  //         setbanner(downloadURL);
  //         setisUploadBanner(false);
  //       });
  //     }
  //   );
  // };

  // const onDropLogo = files => {
  //   setisUploadLogo(true);
  //   let uploadTask = firebase
  //     .storage()
  //     .ref()
  //     .child("logos/" + Date.now())
  //     .put(files[0]);

  //   uploadTask.on(
  //     "state_changed",
  //     snapshot => {
  //       var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //       console.log("Upload is " + progress + "% done");
  //       setuploadLogoProgress(progress);
  //     },
  //     error => {
  //       console.log("Erreur d'upLoad");
  //       setisUploadLogo(false);
  //     },
  //     () => {
  //       uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
  //         console.log(downloadURL);
  //         setlogo(downloadURL);
  //         setisUploadLogo(false);
  //       });
  //     }
  //   );
  // };

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} size="md" className="modal-new-resto">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={toggle} className="font-weight-normal">
            <FontAwesomeIcon icon={faStore} className="mr-2"/>Nouveau store restaurant
          </ModalHeader>
          <ModalBody>
            {/* <Row form> */}
              {/* <Col sm="12" md="8"> */}
                {/* <FormGroup>
                  <Label size="sm" className="font-weight-bold">
                    <span className="text-success">*</span>
                    Nom du restaurant
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    disabled={isSave ? true : false}
                    innerRef={register({ required: true })}
                  />
                </FormGroup> */}
              {/* </Col> */}
              {/* <Col sm="12" md="4"> */}
                {/* <FormGroup>
                  <Label size="sm" className="font-weight-bold">
                    <span className="text-success">*</span>
                    Ville ou cité
                  </Label>
                  <Input
                    type="select"
                    name="city"
                    disabled={isSave ? true : false}
                    innerRef={register({
                      required: true
                    })}
                  >
                    <option></option>
                    {cities &&
                      cities.map(city => (
                        <option key={city.id} value={city.city}>
                          {city.city}
                        </option>
                      ))}
                  </Input>
                </FormGroup> */}
              {/* </Col> */}
            {/* </Row> */}
            <Row form>
              <Col sm="12" md="6">
              <FormGroup>
                  <Label size="sm" className="font-weight-bold">
                    <span className="text-success">*</span>
                    Ville ou cité
                  </Label>
                  <Input
                    type="select"
                    name="city"
                    disabled={isSave ? true : false}
                    innerRef={register({
                      required: true
                    })}
                  >
                    <option></option>
                    {cities &&
                      cities.map(city => (
                        <option key={city.id} value={city.city}>
                          {city.city}
                        </option>
                      ))}
                  </Input>
                </FormGroup>

                {/* <FormGroup>
                  <Label size="sm" className="font-weight-bold">
                    <span className="text-success">*</span>
                    Email
                  </Label>
                  <Input
                    type="email"
                    name="email"
                    disabled={isSave ? true : false}
                    innerRef={register({
                      required: true,
                      pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    })}
                  />
                </FormGroup> */}
              </Col>
              <Col sm="12" md="6">
                <FormGroup>
                  <Label size="sm" className="font-weight-bold">
                    <span className="text-success">*</span>
                    Téléphone (Contact)
                  </Label>
                  <Input
                    type="tel"
                    name="phone"
                    disabled={isSave ? true : false}
                    innerRef={register({ required: true })}
                  />
                </FormGroup>
              </Col>
            </Row>
            {/* <FormGroup>
              <Label size="sm" className="font-weight-bold">
                <span className="text-success">*</span>
                Nom et post-nom du responsable
              </Label>
              <Input
                type="text"
                name="owner"
                disabled={isSave ? true : false}
                innerRef={register({ required: true })}
              />
            </FormGroup> */}
            <FormGroup>
              <Label size="sm" className="font-weight-bold">
                {/* <span className="text-success">*</span> */}
                Adresse locale du restaurant
              </Label>
              <Input
                type="textarea"
                name="adresse"
                disabled={isSave ? true : false}
                innerRef={register}
                rows="2"
              />
            </FormGroup>
            <Row form>
              <Col>
                <FormGroup>
                  <Label size="sm" className="font-weight-bold">
                    Longitude
                  </Label>
                  <Input
                    type="text"
                    name="longitude"
                    disabled={isSave ? true : false}
                    innerRef={register}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label size="sm" className="font-weight-bold">
                    Latitude
                  </Label>
                  <Input
                    type="text"
                    name="latitude"
                    disabled={isSave ? true : false}
                    innerRef={register}
                  />
                </FormGroup>
              </Col>
            </Row>
            {/* <FormGroup>
              <Label size="sm" className="font-weight-bold">
                <span className="text-success">*</span>
                Bannière{" "}
                {banner !== "" && (
                  <FontAwesomeIcon icon={faCheck} className="text-success" />
                )}
              </Label>
              {isUploadBanner ? (
                <div className="spinner-upload-div">
                  <Spinner
                    color="light"
                    style={{ height: "100px", width: "100px" }}
                  />
                </div>
              ) : (
                <img
                  src={
                    banner !== "" ? banner : "/assets/images/placeholder.png"
                  }
                  width="100%"
                />
              )}
              <Progress
                value={uploadBannerProgress}
                color="success"
                className="progress-upload mt-1"
              />
              <Dropzone
                onDrop={onDropBanner}
                accept="image/png,image/jpeg,image/gif"
                multiple={false}
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragReject
                }) => (
                  <div {...getRootProps()} style={{ width: "80px" }}>
                    <input
                      {...getInputProps()}
                      disabled={isSave || isUploadBanner ? true : false}
                    />
                    <div className="parcourir-btn-file-upload py-0  my-1">
                      Parcourir
                    </div>
                  </div>
                )}
              </Dropzone>
            </FormGroup> */}
            {/* <FormGroup>
              <Label size="sm" className="font-weight-bold">
                <span className="text-success"> </span>
                Logo{" "}
                {logo !== "" && (
                  <FontAwesomeIcon icon={faCheck} className="text-success" />
                )}
              </Label>
              {isUploadLogo ? (
                <div className="spinner-upload-div">
                  <Spinner
                    color="light"
                    style={{ height: "100px", width: "100px" }}
                  />
                </div>
              ) : (
                <img
                  src={logo !== "" ? logo : "/assets/images/placeholder.png"}
                  className="logo-img"
                />
              )}
              <Progress
                value={uploadLogoProgress}
                color="success"
                className="progress-upload mt-1"
              />
              <Dropzone
                onDrop={onDropLogo}
                accept="image/png,image/jpeg,image/gif"
                multiple={false}
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragReject
                }) => (
                  <div {...getRootProps()} style={{ width: "80px" }}>
                    <input
                      {...getInputProps()}
                      disabled={isSave || isUploadLogo ? true : false}
                    />
                    <div className="parcourir-btn-file-upload py-0  my-1">
                      Parcourir
                    </div>
                  </div>
                )}
              </Dropzone>
            </FormGroup> */}
            <Label className="font-weight-bold">
            Numéros de réception de payements
          </Label>
          <div className="information-payement-div mb-2 px-2">
            <Row form>
              <Col>
                <FormGroup>
                  <Label>
                    DLPS phone{" "}
                    <FontAwesomeIcon
                      icon={faMoneyBill}
                      className="text-success"
                    />
                  </Label>
                  <Input
                    type="text"
                    name="dlpsNumber"
                    disabled={isSave ? true : false}
                    innerRef={register({ required: true, pattern: /\+243+([0-9]{9})/ })}
                  />
                </FormGroup>
              </Col>
              <Col>
                {/* <FormGroup>
                  <Label>
                    M-pesa{" "}
                    <FontAwesomeIcon
                      icon={faMoneyBill}
                      className="text-success"
                    />
                  </Label>
                  <Input
                    type="text"
                    name="payementNumberMpesa"
                    disabled={isSave ? true : false}
                    innerRef={register({ required: true })}
                  />
                </FormGroup> */}
              </Col>
            </Row>
          </div>
            {/* <FormGroup>
              <div>
                <CustomInput
                  type="checkbox"
                  name="userCondition"
                  id="condition"
                  disabled={isSave ? true : false}
                  innerRef={register({ required: true })}
                  label="Condition d'utilisation"
                  inline
                />
              </div>
            </FormGroup> */}
          </ModalBody>
          <ModalFooter>
            <div className="text-right">
            <Button
                type="submit"
                className="font-weight-bold px-3 mr-2"
                size="sm"
                disabled={isSave ? true : false}
              >
                {!isSave ? (
                  "Enregistrer"
                ) : (
                  <Spinner color="light" className="spinner-isSaving" />
                )}
              </Button>
              <Button
                className="font-weight-bold px-3"
                onClick={toggle}
                size="sm"
                disabled={isSave ? true : false}
              >
                Annuler
              </Button>
              
            </div>
          </ModalFooter>
        </form>
      </Modal>
      <ToastMessage
        isShow={showSuccess}
        toggle={handleShowSuccess}
        message="Enregistré"
        delay={4000}
        icon="check"
        className="bg-success text-white pr-5 mr-3"
      />
      <ToastMessage
        isShow={showDanger}
        toggle={handleShowDanger}
        message="Ouf, une erreur est survenue!"
        delay={5000}
        icon="check"
        className="bg-danger text-white pr-5 mr-3"
      />
    </React.Fragment>
  );
};

export default NewRestaurantForm;
