import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

function TimerOrderDelivery({ createdAt }) {
  const [timer, settimer] = useState();
  const timeToDeliverTo = createdAt + parseInt(localStorage.getItem("deliveryMinTime"));

  setInterval(() => {
    const currentTime = Date.now();
    if (currentTime > timeToDeliverTo) {
      settimer("00");
    } else {
      const timeInmunite = Math.floor((timeToDeliverTo - currentTime) / 60000);
      settimer(timeInmunite);
    }
  }, 5000);

  return (
    <div className="order-timing">
      <h5 className={`m-0 ${timer === "00" && "text-danger"} `}>
        {timer ? (
          timer
        ) : (
          <FontAwesomeIcon icon={faEye} className="text-white" />
        )}
      </h5>
      <small className="order-time-unit text-center font-weight-bold">
        min
      </small>
    </div>
  );
}

export default TimerOrderDelivery;
