import React, { useState } from "react";
import {
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from "reactstrap";
import { useForm } from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCamera} from "@fortawesome/free-solid-svg-icons"
import { useFirestore,useFirebase } from "react-redux-firebase";
import Dropzone from "react-dropzone"

function CategoryItem({ category }) {
  const [isOpen, setisOpen] = useState(false);

  const toggle = () => {
    setisOpen(!isOpen);
  };

  const db = useFirestore();
  const firebase =useFirebase()
  const { register, handleSubmit } = useForm();
  const [isSave, setisSave] = useState(false);
  const [imageUrl, setimageUrl] = useState()
  const [isUpload, setisUpload] = useState(false)
  const [uploadFileProgress, setuploadFileProgress] = useState(0)

  const saveToDB = (data) => {
    setisSave(true);
    db.update(`categories/${category.id}`, {
      ...data,
      imageUrl:imageUrl?imageUrl:category.imageUrl,
      updatedAt: db.Timestamp.now().toMillis(),
    })
      .then((res) => {
        setisSave(false);
        toggle();
      })
      .catch((err) => {
        setisSave(false);
      });
  };


  const onDropFiles = (files) => {
    setisUpload(true);
    let uploadTask = firebase
      .storage()
      .ref()
      .child("categoriesProducts/" + Date.now())
      .put(files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        setuploadFileProgress(progress);
      },
      (error) => {
        setisUpload(false);
        console.log("Erreur d'upLoad");
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log(downloadURL);
          setimageUrl(downloadURL);
          setisUpload(false);
        });
      }
    );
  };


  return (
    <ListGroupItem className="unit-item py-1" onClick={toggle}>
      <img src={category.imageUrl} alt=""/>
      <div className="unit">{category.category}</div>
      <Modal isOpen={isOpen} className="modal-new-unit">
        <form onSubmit={handleSubmit(saveToDB)}>
          <ModalHeader toggle={toggle}>
            Modification de {category.category}
          </ModalHeader>
          <ModalBody className="modal-new-unit-body">
            <div className="div-input">
              <label>Catégorie</label>
              <input
                type="text"
                name="category"
                defaultValue={category.category}
                ref={register({ required: true })}
                disabled={isSave}
              />
            </div>
            <div className="div-image-category">
              <label>
                Image de la category
              </label>

              <Dropzone
                onDrop={onDropFiles}
                accept="image/jpeg,image/png"
                multiple={false}
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragReject,
                }) => (
                  <div
                    {...getRootProps()}
                    className={`dropzone-image`}
                    style={{
                      backgroundImage: `url(${imageUrl ? imageUrl:category.imageUrl})`,
                      backgroundRepeat: "none",
                      backgroundSize: "cover",
                      backgroundColor: "#f9f9f9",
                    }}
                  >
                    <input
                      {...getInputProps()}
                      disabled={isSave || isUpload ? true : false}
                    />
                    {!isUpload ? (
                      <div className="btn-upload">
                        <p>
                          <FontAwesomeIcon icon={faCamera} />
                        </p>
                        <h6>Upload</h6>
                      </div>
                    ) : (
                      <div className="spinner-progress">
                        <Spinner />
                        <p>{uploadFileProgress.toFixed(0)}%</p>
                      </div>
                    )}
                  </div>
                )}
              </Dropzone>
            </div>
          </ModalBody>
          <ModalFooter>
            <button type="submit" disabled={isSave}>Sauvegarder</button>
            <button type="button" onClick={toggle} disabled={isSave}>
              Annuler
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </ListGroupItem>
  );
}

export default CategoryItem;
