import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Spinner,
  Row,
  Col,
  Alert,
  ListGroupItem,
  ListGroup,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faCamera,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Dropzone from "react-dropzone";
import { useFirebase, useFirestore } from "react-redux-firebase";

function PaymentItem({ network }) {
  const firebase = useFirebase();
  const db = useFirestore();
  const { register, handleSubmit } = useForm();
  const [isOpen, setisOpen] = useState(false);
  const [isSave, setisSave] = useState(false);
  const [imageUrl, setimageUrl] = useState();
  const [isUpload, setisUpload] = useState(false);
  const [uploadFileProgress, setuploadFileProgress] = useState(0);
  const [steps, setsteps] = useState(network.steps);
  const [verb, setverb] = useState("");
  const [actionMessage, setactionMessage] = useState("");
  const [isAddShown, setisAddShown] = useState(true);
  const toggle = () => setisOpen((prev) => !prev);

  const handleChangeStepInput = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "verb":
        setverb(value.trim());
        break;
      case "actionMessage":
        setactionMessage(value.trimLeft());
        break;

      default:
        break;
    }
  };

  const resetFormStep = () => {
    setverb("");
    setactionMessage("");
    setisAddShown(true);
  };

  const addToSteps = () => {
    if (verb.length != 0 && actionMessage.length != 0) {
      setsteps((prev) => [...prev, { verb, actionMessage }]);
      setTimeout(() => {
        resetFormStep();
      }, 100);
    }
  };

  const removeFromSteps = (currentstep)=>{
      const newsteps = steps.filter(step=>step!=currentstep)
      setsteps(newsteps)
  }

  const saveToDB = (data) => {
    setisSave(true);
    db.update(`networks/${network.id}`, {
      ...data,
      imageUrl: imageUrl ? imageUrl : network.imageUrl,
      steps: steps,
      updatedAt: db.Timestamp.now().toMillis(),
    })
      .then((res) => {
        setisSave(false);
        toggle();
      })
      .catch((err) => {
        setisSave(false);
      });
  };

  const onDropFiles = (files) => {
    setisUpload(true);
    let uploadTask = firebase
      .storage()
      .ref()
      .child("telecomslogo/" + Date.now())
      .put(files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        setuploadFileProgress(progress);
      },
      (error) => {
        setisUpload(false);
        console.log("Erreur d'upLoad");
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          setimageUrl(downloadURL);
          setisUpload(false);
        });
      }
    );
  };

  return (
    <div className="col-md-4 mb-2">
      <Card className="card-image-telecom">
        <CardBody onClick={toggle} className="image-telecom">
          <img src={network.imageUrl} width="100%" />
        </CardBody>
        <CardFooter>{network.network}</CardFooter>
      </Card>
      <Modal isOpen={isOpen} centered>
        <form onSubmit={handleSubmit(saveToDB)}>
          <ModalHeader>Réseau {network.network}</ModalHeader>
          <ModalBody>
            <Row form className="mt-2">
              <Col>
                <div className="div-network-logo">
                  <label>Logo du réseau</label>
                  <Dropzone
                    onDrop={onDropFiles}
                    accept="image/jpeg,image/png"
                    multiple={false}
                  >
                    {({
                      getRootProps,
                      getInputProps,
                      isDragActive,
                      isDragReject,
                    }) => (
                      <div
                        {...getRootProps()}
                        className={`dropzone-image`}
                        style={{
                          backgroundImage: `url(${
                            imageUrl ? imageUrl : network.imageUrl
                          })`,
                          backgroundRepeat: "none",
                          backgroundSize: "cover",
                          backgroundColor: "#f9f9f9",
                          backgroundPosition: "center",
                        }}
                      >
                        <input
                          {...getInputProps()}
                          disabled={isSave || isUpload ? true : false}
                        />
                        {!isUpload ? (
                          <div className="btn-upload">
                            <p>
                              <FontAwesomeIcon icon={faCamera} />
                            </p>
                            <h6>Upload</h6>
                          </div>
                        ) : (
                          <div className="spinner-progress">
                            <Spinner />
                            <p>{uploadFileProgress.toFixed(0)}%</p>
                          </div>
                        )}
                      </div>
                    )}
                  </Dropzone>
                </div>
              </Col>
              <Col>
                <div className="div-input">
                  <label>Nom du réseau</label>
                  <input
                    type="text"
                    name="network"
                    defaultValue={network.network}
                    ref={register({ required: true })}
                    disabled={isSave}
                  />
                </div>
                <div className="div-input">
                  <label>Numéro de téléphone</label>
                  <input
                    type="text"
                    name="phone"
                    defaultValue={network.phone}
                    ref={register({ required: true })}
                    disabled={isSave}
                  />
                </div>
                <Alert>
                  La procedure de recharge C'est par ici{" "}
                  <FontAwesomeIcon icon={faArrowDown} />
                </Alert>
              </Col>
            </Row>
            <div className="div-recharge-steps">
              <label className="font-weight-bold">Etapes de recharge</label>
              <ListGroup flush>
                {steps.map((step, index) => (
                  <ListGroupItem key={index} className="step-item">
                      <p className="verb">{step.verb}</p>
                    <p className="action-message">{step.actionMessage}</p>
                      <button className="btn-cancel" type="button" onClick={removeFromSteps.bind(this,step)} disabled={isSave}><FontAwesomeIcon icon={faTimes}/></button>
                  </ListGroupItem>
                ))}
              </ListGroup>
              {isAddShown ? (
                <div className="text-right mt-3">
                  <button
                    type="button"
                    className="btn-add"
                    onClick={() => setisAddShown(false)}
                    disabled={isSave}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Ajouter
                  </button>
                </div>
              ) : (
                <div className="form-add-step">
                  <input
                    type="text"
                    name="verb"
                    value={verb}
                    placeholder="Verbe d'action"
                    onChange={handleChangeStepInput}
                    disabled={isSave}
                  />
                  <textarea
                    name="actionMessage"
                    rows="1"
                    value={actionMessage}
                    placeholder="Message d'action"
                    onChange={handleChangeStepInput}
                    disabled={isSave}
                  />
                  <button
                    className="btn-add"
                    type="button"
                    onClick={addToSteps}
                    disabled={isSave}
                  >
                    Go
                  </button>
                  <button
                    className="btn-cancel"
                    type="button"
                    onClick={resetFormStep}
                    disabled={isSave}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              )}
            </div>
          </ModalBody>
          <ModalFooter className="">
          <button className="btn-cancel-new" type="button" onClick={toggle} disabled={isSave}>
              Annuler
            </button>
            <button className="btn-save" type="submit" disabled={isSave}>
              Sauvegarder
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
}

export default PaymentItem;
