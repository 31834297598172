import React, { useState } from "react";
import {
  ListGroup,
  ListGroupItem,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
  ModalFooter,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Spinner,
  Alert,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFirestore } from "react-redux-firebase";
import {
  faMapMarkerAlt,
  faEdit,
  faTrash,
  faEllipsisV,
  faPhoneAlt,
  faLockOpen,
  faLock,
  faInfoCircle,
  faCircle
} from "@fortawesome/free-solid-svg-icons";
import NoData from "../noData.svg";

const ListLivreurs = ({ riders, setRiders, setcurrentRiderToEdit }) => {
  const [isShowDeleteRider, setisShowDeleteRider] = useState(false);
  const [isShowBloqueRider, setisShowBloqueRider] = useState(false)
  const [isDelete, setisDelete] = useState(false);
  const [isBloque, setisBloque] = useState(false)
  const [currentRiderToOperate, setcurrentRiderToOperate] = useState();
  const db = useFirestore();

const toggleEditForm = (rider) => {
  setcurrentRiderToEdit(rider)
}

  const deleleRider = () => {
    setisDelete(true);
    const newRiders = riders.filter(
      (rider) => rider.id !== currentRiderToOperate.id
    );

    db.delete(`riders/${currentRiderToOperate.id}`)
      .then((res) => {
        setisDelete(false);
        setRiders(newRiders);
        setcurrentRiderToOperate();
        setisShowDeleteRider(false);
      })
      .catch((err) => {
        setisDelete(false);
      });
  };

  const onBloqueRider = () => {
    setisBloque(true);
    const status =
      currentRiderToOperate.status === "bloqued" ? "offline" : "bloqued";
    db.update(`riders/${currentRiderToOperate.id}`, {
      status: status,
    })
      .then((res) => {
        setisBloque(false);
        setisShowBloqueRider(false)
        setcurrentRiderToOperate();
      })
      .catch((err) => {
        setisBloque(false);
      });
  }
  const toggleBloqueModal =(rider) =>{
    setisShowBloqueRider(true);
    setcurrentRiderToOperate(rider);
  }
  const toggleDeleteModal = (rider) => {
    setisShowDeleteRider(true);
    setcurrentRiderToOperate(rider);
  };
  return riders ? (
    <ListGroup flush className="list-livreur">
      {riders.map((rider) => (
        <ListGroupItem key={rider.id} className="list-livreur-item">
          <img
            src={rider.avatar}
            alt=""
            className="rounded-circle"
            width="48px"
            height="48px"
          />
          <div className="info-1 ml-1">
            <h6 className="my-0 font-weight-normal"><FontAwesomeIcon icon={faCircle} className={`${rider.status !="bloqued" ? rider.status==="online" ? "text-success":"text-warning": "text-danger"}`}/> {rider.name}</h6>
            <p className="my-0 text-muted">
              <FontAwesomeIcon icon={faPhoneAlt} className="" /> {rider.phone}
            </p>
          </div>
          <div className="info-2">
            <h6 className="my-0">Ville d'affectation</h6>
            <p className="my-0 text-muted">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="" />{" "}
              {rider.city}
            </p>
          </div>
          <UncontrolledButtonDropdown
            direction="left"
            className="resto-edit-btn"
          >
            <DropdownToggle className="toggle-edit" size="sm">
              <FontAwesomeIcon icon={faEllipsisV} className="" />
            </DropdownToggle>
            <DropdownMenu className="resto-drop-menu py-0">
              <DropdownItem className="px-1 my-0" onClick={toggleEditForm.bind(this, rider)}>
                <FontAwesomeIcon icon={faEdit} className="" /> Modifier
              </DropdownItem>
              <DropdownItem className="px-1 my-0" onClick={toggleBloqueModal.bind(this, rider)}>
                <FontAwesomeIcon
                  icon={rider.status === "bloqued" ? faLockOpen : faLock}
                  className=""
                />{" "}
                {rider.status === "bloqued" ? "Débloquer" : "Bloquer"}
              </DropdownItem>
              <DropdownItem
                className="px-1 my-0"
                onClick={toggleDeleteModal.bind(this, rider)}
              >
                <FontAwesomeIcon icon={faTrash} className="" /> Supprimer
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </ListGroupItem>
      ))}

      <Modal isOpen={isShowBloqueRider} centered>
        <ModalHeader>
        {currentRiderToOperate &&
            (currentRiderToOperate.status === "bloqued"
              ? `Débloquer ${currentRiderToOperate.name}`
              : `Bloquer ${currentRiderToOperate.name}`)}
        </ModalHeader>
        <ModalBody>
        <Alert color="warning">
            <FontAwesomeIcon icon={faInfoCircle} /> En bloquant un livreur,
            vous lui enlevez les responsabités de livrer les packets.
          </Alert>
        </ModalBody>
        <ModalFooter>
        <Button
            disabled={isBloque ? true : false}
            size="sm"
            color="success"
            className="font-weight-bold"
            onClick={onBloqueRider}
          >
            { isBloque && <Spinner style={{height:"16px", width:"16px"}} className="mr-1"/>}
           {currentRiderToOperate && (currentRiderToOperate.status === "bloqued" ? "Débloquer":"Bloquer")}
          </Button>
          <Button
            disabled={isBloque ? true : false}
            size="sm"
            onClick={() => {
              setisShowBloqueRider(false);
            }}
            className="font-weight-bold"
          >
            Annuler
          </Button>
        </ModalFooter>
      </Modal>


      <Modal isOpen={isShowDeleteRider} centered>
      <ModalHeader>Supprimer {currentRiderToOperate && currentRiderToOperate.name}</ModalHeader>
        <ModalBody>
          <Alert color="warning">
            <FontAwesomeIcon icon={faInfoCircle} /> Attention cette opération
            n'est pas reversible. Une fois vous supprimez le livreur, il ne fera
            plus partie de l'équipe tayarifood.
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={isDelete ? true : false}
            size="sm"
            color="success"
            className="font-weight-bold"
            onClick={deleleRider}
          >
            {isDelete && (
              <Spinner
                style={{ height: "16px", width: "16px" }}
                className="mr-1"
              />
            )}
            Supprimer
          </Button>
          <Button
            disabled={isDelete ? true : false}
            size="sm"
            onClick={() => {
              setisShowDeleteRider(false);
            }}
            className="font-weight-bold"
          >
            Annuler
          </Button>
        </ModalFooter>
      </Modal>
    </ListGroup>
  ) : (
    <Col className="empty-list-livreur mt-5">
      <small className=" text-muted font-weight-normal text-center mb-2">
        Aucun resultat
      </small>
      <img src={NoData} alt="" width="30%" />
    </Col>
  );
};

export default ListLivreurs;
