import React from 'react'
import {Container} from 'reactstrap'
import './home.scss'
import HomeImage from './home-image.svg'

const Home = () =>{
    return (
        <Container fluid className="container-home">
            <h1 className="text-muted display-4">Bienvenue!</h1>
            <img src={HomeImage} alt="" height="50%" width="50%"/>
        </Container>
    )
}

export default Home
