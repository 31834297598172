import React from "react";
import { Row } from "reactstrap";
import DeliveryOrderItem from "./deliveryOrderItem";
import EmptyDataList from "../loadingIndicator/emptyDataList";
function DeliveryOrders({ orders }) {
  return orders.length > 0 ? (
    <div className="container-orders">
      <Row className="list-home-orders">
        {orders.map((order) => (
          <div className="col-sm-6 col-md-4 col-xl-3" key={order.id}>
            <DeliveryOrderItem order={order} />
          </div>
        ))}
      </Row>
    </div>
  ) : (
    <EmptyDataList message="Aucune commande en cours de livraison!" />
  );
}

export default DeliveryOrders;
