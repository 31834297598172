import React, { useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useFirestore } from "react-redux-firebase";
import SingleDishView from "./singleDishView";
import ModalDeleteDishImage from "./modalDeleteDishImage";
import EmptyListDishes from "./emptyListDishes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const ListImages = ({ dishes, setDishes }) => {
  const [currentDishToView, setcurrentDishToView] = useState();
  const [isSingleDishView, setisSingleDishView] = useState(false);
  const [isModalDeleteDish, setisModalDeleteDish] = useState(false);
  const [isDeleting, setisDeleting] = useState(false);
  const db = useFirestore();

  const toggleDeleteModal = (dish) => {
    setcurrentDishToView(dish);
    setisModalDeleteDish(true);
  };

  const deleteDish = (id) => {
    setisDeleting(true);
    const newDishes = dishes.filter((dish) => dish.id !== id);
    db.delete(`galleryDishes/${id}`).then((res) => {
      setDishes(newDishes);
      setisModalDeleteDish(false);
      setisDeleting(false);
    });
  };

  const viewSingleDish = (dish) => {
    setcurrentDishToView(dish);
    setisSingleDishView(true);
  };
  return (
    <Row className="mr-0 pl-3 mt-3">
      {dishes ? (
        dishes.length != 0 ? (
          dishes.map((dish) => (
            <Col className="col-3" key={dish.id}>
              <div className="card-image-dish mb-3">
                <div className="text-right pr-1"><FontAwesomeIcon className="btn-delete-dish" icon={faTrashAlt} onClick={toggleDeleteModal.bind(this, dish)}/></div>
                <LazyLoadImage
                  src={dish.imageUrl}
                  width="100%"
                  height="100px"
                  className="dish-image"
                  effect="blur"
                  onClick={viewSingleDish.bind(this, dish)}
                  visibleByDefault={true}
                  placeholderSrc="/assets/images/placeholder.png"
                />
              </div>
            </Col>
          ))
        ) : (
          <EmptyListDishes />
        )
      ) : (
        <EmptyListDishes />
      )}

      <SingleDishView
        isOpen={isSingleDishView}
        dish={currentDishToView}
        setisSingleDishView={setisSingleDishView}
      />
      <ModalDeleteDishImage
        isOpen={isModalDeleteDish}
        deleteDish={deleteDish}
        dish={currentDishToView}
        setisModalDeleteDish={setisModalDeleteDish}
        isDeleting={isDeleting}
      />
    </Row>
  );
};

export default ListImages;
