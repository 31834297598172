import React, { Component } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartArrowDown } from "@fortawesome/free-solid-svg-icons";

export default class Bill extends Component {
  render() {
    const order = this.props.order;
    const orderItems = this.props.orderItems;
    return (
      <div className="container-bill">
        <div className="bill-header">
          <img src="/assets/images/tayari_icon_text.png" alt="logo" />
          <p>Tél: {order.phone}</p>
          <p>contact@tayarifood.com</p>
          <p>www.tayarifood.com</p>
          <h3>Le {moment().format("DD-MM-YYYY")}</h3>
        </div>

        <div className="bill-body">
          <div className="order-content">
            <h3>FACTURE No: {order.orderNumber}</h3>
            <div>
              <h3 className="client-title">Client</h3>
              <p>
                <span>Nom</span>: {order.user.name}
              </p>
              <p>
                <span>Tél</span>: {order.user.phone}
              </p>
              <p>
                <span>Adresse</span>: {order.user.city}
              </p>
            </div>
          </div>
          <h6 className="mb-3">
            <FontAwesomeIcon icon={faCartArrowDown} className="icon-cart" />{" "}
            Panier
          </h6>
          <ListGroup className="body-content-body" flush>
            <div className="list-of-items">
              {orderItems.map((item, index) => (
                <ListGroupItem key={index} className="cart-item">
                  <span className="quantity">{item.count}</span>
                  <span className="product-name">{item.product.name}</span>
                  <span className="price">
                    {item.product.price * item.count}
                  </span>
                  <span className="currency">CDF</span>
                </ListGroupItem>
              ))}
            </div>
            <ListGroupItem className="card-prices">
              <div className="row-price product-div-price">
                <span className="price-text">Produit:</span>
                <span className="price-value">{order.totalPrice} Fc</span>
              </div>
              <div className="row-price delivery-div-price">
                <span className="price-text">Frais de livraison: </span>
                <span className="price-value">
                  {order.deliveryCost ?? 0} Fc
                </span>
              </div>
              <div className="row-price total-div-price">
                <span className="price-text">Total:</span>
                <span className="price-value">
                  {order.totalPrice + (order.deliveryCost ?? 0)} Fc
                </span>
              </div>
            </ListGroupItem>
          </ListGroup>
        </div>
        <div className="delivery-message">
          <p>
            {order.payed ? (
              <span>Payer Cash</span>
            ) : (
              <span>À Payer à la livraison</span>
            )}
          </p>
        </div>
        <p className="bill-footer">Livraison rapide, pratique et garantie!</p>
      </div>
    );
  }
}
