import React from "react";
import { Detector } from "react-detect-offline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignal, faSlash } from "@fortawesome/free-solid-svg-icons";
import "./offline.scss";

function OfflineDetector({storeId}) {

  return (
    <Detector
      render={({ online }) =>
        online ? (
          <div className="card-with-internet">
            <h4>{storeId.toUpperCase()}-STORE</h4>
            <div className="card-with-internet-body">
              <div className="store-icon">
                <FontAwesomeIcon icon={faSignal} className="icon-store" />
              </div>
              <div className="message">
                <p>
                  Vous étes en ligne vous pouvez controler les commandes de
                  clients connectés.
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="card-no-internet-connexion">
            <h6 className="card-no-internet-title">Non connecté</h6>

            <div className="card-no-internet-body">
              <FontAwesomeIcon icon={faSignal} className="icon" size="1x" />
              <FontAwesomeIcon icon={faSlash} className="icon slash" />
              <div className="card-no-internet-content">
                <p className="my-0">
                  Assurez-vous que votre orinateur ait une connexion internet
                  active.
                </p>
              </div>
            </div>
          </div>
        )
      }
    />
  );
}

export default OfflineDetector;
