import React from 'react'
import ListOrders from './listOrders'

function Orders() {
    return (
        <div>
            <ListOrders/>
        </div>
    )
}

export default Orders
