import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faCaretDown,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useHistory,useParams } from "react-router-dom";
import { useFirestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";
import OrderItem from "./orderItem";
import { toast } from "react-toastify";
import { ListGroup, ListGroupItem, CustomInput } from "reactstrap";
import EmptyDataList from "../loadingIndicator/emptyDataList";
import LoadingIndicator from "../loadingIndicator/loadingIndicator";
import "./orders.scss";

function ListOrders() {
  const {storeId}=useParams()
  useFirestoreConnect(() => [
    {
      collection: "orders",
      where: ["user.city", "==", storeId],
      orderBy: ["createdAt", "asc"],
      storeAs: "orders",
    },
  ]);
  const history = useHistory();
  const [oldOrders, setoldOrders] = useState([]);
  const [currentOrders, setcurrentOrders] = useState([]);
  const [searchString, setsearchString] = useState("");
  const orders = useSelector((state) => state.firestore.ordered.orders);
  const goTo = (path) => {
    history.push(path);
  };

  const notifyAsComingFeature = () =>
    toast.success("Oops! Fonctionalité à venir!");

  const onSearch = (e) => {
    const value = e.target.value.replace("+", "");
    setsearchString(value);
    if (value != "") {
      const regexToSearch = RegExp(value, "i");
      const searchResult = oldOrders.filter(
        (order) =>
          order.orderNumber.match(regexToSearch) ||
          order.user.phone.match(regexToSearch) ||
          order.user.name.match(regexToSearch)
      );
      setcurrentOrders(searchResult);
    } else {
      setcurrentOrders(oldOrders);
    }
  };

  useEffect(() => {
    if (orders) {
      setoldOrders(
        orders.filter(
          (order) => order.status === "delivered" || order.status === "rejected"
        )
      );
    }
    return () => {};
  }, [orders]);

  useEffect(() => {
    setcurrentOrders(oldOrders);
    return () => {};
  }, [oldOrders]);

  return (
    <div className="container-list-orders">
      <div className="container-list-orders-header">
        <div className="div-up">
          <h3>Historique des commandes</h3>{" "}
        </div>
        <div className="div-down">
          <button onClick={notifyAsComingFeature}>
            Toutes <FontAwesomeIcon icon={faCaretDown} />
          </button>
          
          <div className="search-bar">
            <form>
              <FontAwesomeIcon icon={faSearch} className="search-icon" />
              <input
                type="search"
                name="productToSearch"
                placeholder="Rechercher ici ..."
                value={searchString}
                onChange={onSearch}
              />
            </form>
          </div>
          <button className="download" onClick={notifyAsComingFeature}>
            <FontAwesomeIcon icon={faDownload} />
          </button>
        </div>
      </div>
      <div className="container-list-orders-body">
        <div className="row">
          {/* <ListGroupItem className="order-item header">
            <CustomInput
              type="checkbox"
              id="header-order-check"
              className="mr-3"
            />
            <div className="div-order">Commande</div>
            <div className="div-client">Client</div>
            <div className="div-status">Status</div>
          </ListGroupItem> */}
          {!isLoaded(orders) ? (
            <LoadingIndicator />
          ) : isEmpty(orders) || oldOrders.length === 0 ? (
            <EmptyDataList message="Aucune commande" />
          ) : currentOrders.length != 0 ? (
            currentOrders.map((order) => (
              <div key={order.id} className="col-sm-6 col-md-4 col-xl-3">
                <OrderItem order={order} />
              </div>
            ))
          ) : (
            <EmptyDataList message="Aucun resultat de recherche!" />
          )}
        </div>
      </div>
    </div>
  );
}

export default ListOrders;
