import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Spinner } from "reactstrap";
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
  isEmpty,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoadingIndicator from "../loadingIndicator/loadingIndicator";
import EmptyDataList from "../loadingIndicator/emptyDataList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";

function Store() {

  const {storeId} = useParams()

  useFirestoreConnect(() => [
    {
      collection: "stores",
      doc: storeId,
    },
  ]);
  const history = useHistory();
  const db = useFirestore();
  const { register, handleSubmit } = useForm();
  const [isSave, setisSave] = useState(false);
  const store = useSelector(
    ({ firestore: { data } }) =>
      data.stores && data.stores[storeId]
  );
  const notifySuccess = (message) => toast.success(message);

  const saveToDB = (data) => {
    setisSave(true);
    db.update(`stores/${storeId}`, {
      phone: data.phone,
      address: data.address,
      longLat: [data.long, data.lat],
    })
      .then((res) => {
        notifySuccess("Sauvegardé");
        setisSave(false);
      })
      .catch((err) => {
        setisSave(false);
      });
  };
  return (
    <div className="container-fluid container-settings-category">
      {!isLoaded(store) ? (
        <LoadingIndicator />
      ) : isEmpty(store) ? (
        <EmptyDataList message="Rien à  afficher!" />
      ) : (
        <form onSubmit={handleSubmit(saveToDB)}>
         <div className="form-container-content-store">
            <h3>Identité du store</h3>
          <div className="row">
            <div className="col-8">
              <div className="div-input">
                <label>Ville</label>
                <input name="city" defaultValue={store.city} disabled />
              </div>
            </div>
            <div className="col-4">
              <div className="div-input">
                <label>Téléphone (Contact local)</label>
                <input
                  name="phone"
                  defaultValue={store.phone}
                  ref={register({ required: true })}
                  disabled={isSave}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">
              <div className="div-input">
                <label>Adresse physique</label>
                <textarea
                  name="address"
                  defaultValue={store.address}
                  rows="4"
                  ref={register}
                  disabled={isSave}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="div-input">
                <label>Longitude</label>
                <input
                  name="long"
                  defaultValue={store.longLat[0]}
                  ref={register}
                  disabled={isSave}
                />
              </div>
              <div className="div-input">
                <label>Latitude</label>
                <input
                  name="lat"
                  defaultValue={store.longLat[1]}
                  ref={register}
                  disabled={isSave}
                />
              </div>
            </div>
          </div>
          <div className="text-center mt-4">
          <button type="submit" className="btn-fresh-login" disabled={isSave}>
              {isSave ? (
                <Spinner className="spinner" />
              ) : (
                <span>Sauvegarder</span>
              )}
            </button>
          </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default Store;
