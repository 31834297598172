import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalBody,
  Spinner,
  CustomInput,
  Nav,
  NavLink,
  NavItem,
  TabPane,
  TabContent,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faPrint,
  faTrashAlt,
  faInfoCircle,
  faTimes,
  faSave
} from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory, useParams } from "react-router-dom";
import { useFirestore, useFirebase } from "react-redux-firebase";
import { useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import {toast} from "react-toastify"
import BikerActivities from "./bikerActivities";

function EditBikerForm() {
  const { bikerId, storeId } = useParams();

  const history = useHistory();
  const firebase = useFirebase();
  const db = useFirestore();
  const { register, handleSubmit } = useForm();
  const [isUpload, setisUpload] = useState(false);
  const [isSave, setisSave] = useState(false);
  const [avatar, setavatar] = useState();
  const [isOpenDeleteModal, setisOpenDeleteModal] = useState(false);
  const [isRequiredImage, setisRequiredImage] = useState(false);
  const [uploadFileProgress, setuploadFileProgress] = useState(0);
  const [activeTab, setactiveTab] = useState("1");

  const biker = useSelector(({ firestore: { data } }) =>
    data.bikers ? data.bikers[bikerId] : history.push(`/store/${storeId}/bikers`)
  );

  const notifyAsComingFeature=()=>toast.success("Oops! Fonctionalité à venir!")

  const goTo = (path) => {
    history.push(path);
  };

  const toggleDeleteModal = () => {
    setisOpenDeleteModal((prev) => !prev);
  };

  const toggleActiveTab = (tab) => {
    if (activeTab !== tab) setactiveTab(tab);
  };

  const saveToDB = (data) => {
    setisSave(true);

    db.update(`users/${bikerId}`, {
      ...data,
      avatar: avatar ? avatar : biker.avatar,
      updatedAt: db.Timestamp.now().toMillis(),
    })
      .then((res) => {
        toast.success("Sauvegardé")
        history.push(`/store/${storeId}/bikers`);
      })
      .catch((err) => {});
  };

  const onDropFiles = (files) => {
    setisUpload(true);
    setisRequiredImage(false);
    let uploadTask = firebase
      .storage()
      .ref()
      .child("bikers/" + Date.now())
      .put(files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        setuploadFileProgress(progress);
      },
      (error) => {
        setisUpload(false);
        console.log("Erreur d'upLoad");
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          setavatar(downloadURL);
          setisUpload(false);
        });
      }
    );
  };

  return (
    <div className="container-edit-product">
      <form onSubmit={handleSubmit(saveToDB)}>
        <div className="edit-product-header">
          <div className="div-titles">
            <h4>
              <Link
                to={`${!isSave ? `/store/${storeId}/bikers` : `/store/${storeId}/bikers/new`}`}
                className="link"
              >
                Livreurs
              </Link>{" "}
              / <span className="name">{biker&&biker.name}</span>
            </h4>
            <div>
              <button disabled={isSave} type="button" onClick={notifyAsComingFeature}>
                <FontAwesomeIcon icon={faPrint} className="icon" />
                Imprimer
              </button>
              <button
                disabled={isSave}
                type="button"
                onClick={toggleDeleteModal}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="icon" />
                Supprimer
              </button>
            </div>
            {/* <div>
              <button>Supprimer</button>
            </div> */}
            {/* <div className="div-availability">
              <label>Autorisé :</label>
              <CustomInput
                type="switch"
                id="status"
                name="allowed"
                defaultChecked={biker && biker.allowed}
                disabled={isSave}
                label=""
                innerRef={register}
              />
            </div> */}
          </div>
          <div className="div-actions">
            <button type="submit" className="btn-save" disabled={isSave}>
              {isSave ? (
                <Spinner className="spinner" />
              ) : (
                <span>
                  <FontAwesomeIcon icon={faSave} /> Sauvegarder
                </span>
              )}
            </button>
            <button
              onClick={goTo.bind(this, `/store/${storeId}/bikers`)}
              disabled={isSave}
            >
              <FontAwesomeIcon icon={faTimes} /> Fermer
            </button>
            <div className="stock-input allowed">
              <label>Autorisé :</label>
              <CustomInput
                type="switch"
                id="status"
                name="allowed"
                defaultChecked={biker && biker.allowed}
                disabled={isSave}
                label=""
                innerRef={register}
              />
              {/* <label>Stock</label>
              <input
                type="number"
                defaultValue="0"
                name="stock"
                ref={register({ required: true })}
                disabled={isSave}
              /> */}
            </div>
          </div>
        </div>
        <div className="edit-product-body">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={`link-tab ${activeTab === "1" && "active"} `}
                onClick={() => {
                  toggleActiveTab("1");
                }}
              >
                Identité du livreur
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`link-tab ${activeTab === "2" && "active"} `}
                onClick={() => {
                  toggleActiveTab("2");
                }}
              >
                Activités
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className="row mt-3">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-8">
                      <div className="div-input">
                        <label>
                          <span>*</span>Noms du livreur
                        </label>
                        <input
                          type="text"
                          name="name"
                          defaultValue={biker && biker.name}
                          disabled={isSave}
                          ref={register({ required: true })}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="div-input">
                        <label>Sexe</label>
                        <select
                          name="sex"
                          defaultValue={biker && biker.sex}
                          disabled={isSave}
                          ref={register({ required: true })}
                        >
                          <option value="M">Homme</option>
                          <option value="F">Femme</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <div className="div-input">
                        <label>Etat civil</label>
                        <select
                          name="stateCivile"
                          defaultValue={biker && biker.stateCivile}
                          ref={register}
                          disabled={isSave}
                        >
                          <option></option>
                          <option value="Marié (e)">Marié (e)</option>
                          <option value="Célibataire">Célibataire</option>
                          <option value="Veuf">Veuf (ve)</option>
                          <option value="Divorcé (e)">Divorcé (e)</option>
                        </select>
                      </div>
                    </div>
                    <div className="col">
                      <div className="div-input">
                        <label>Ville ou cité d'affectation</label>
                        <select
                          name="city"
                          defaultValue={biker && biker.city}
                          disabled={isSave}
                          ref={register}
                        >
                          <option value={storeId}>
                            {storeId}
                          </option>
                          {/* {cities &&
                          cities.map((city) => (
                            <option key={city.id} value={city.city}>
                              {city.city}
                            </option>
                          ))} */}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="div-input">
                        <label>
                          <span>*</span>Téléphone
                        </label>
                        <input
                          name="phone"
                          defaultValue={biker && biker.phone}
                          // ref={register({ required: true })}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="div-input">
                        <label>
                          <span>*</span>Mot de passe
                        </label>
                        <input
                          type="password"
                          name="password"
                          // ref={register({ required: true })}
                          disabled={true}
                          defaultValue="123456"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="div-input">
                    <label>Adresse locale</label>
                    <textarea
                      name="address"
                      rows="2"
                      defaultValue={biker && biker.address}
                      ref={register}
                      disabled={isSave}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-image-product">
                  <label>
                    <span>*</span>Avatar
                  </label>

                  <Dropzone
                    onDrop={onDropFiles}
                    accept="image/jpeg"
                    multiple={false}
                  >
                    {({
                      getRootProps,
                      getInputProps,
                      isDragActive,
                      isDragReject,
                    }) => (
                      <div
                        {...getRootProps()}
                        className={`dropzone-image ${
                          isRequiredImage && "required"
                        }`}
                        style={{
                          backgroundImage: `url(${
                            biker && (avatar ? avatar : biker.avatar)
                          })`,
                          backgroundRepeat: "none",
                          backgroundSize: "cover",
                          backgroundColor: "#f9f9f9",
                        }}
                      >
                        <input
                          {...getInputProps()}
                          disabled={isSave || isUpload ? true : false}
                        />
                        {!isUpload ? (
                          <div className="btn-upload">
                            <p>
                              <FontAwesomeIcon icon={faCamera} />
                            </p>
                            <h6>Upload</h6>
                          </div>
                        ) : (
                          <div className="spinner-progress">
                            <Spinner />
                            <p>{uploadFileProgress.toFixed(0)}%</p>
                          </div>
                        )}
                      </div>
                    )}
                  </Dropzone>
                </div>
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className="row mt-4">
                <div className="col">
                  <BikerActivities bikerId={bikerId}/>
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </form>
      <Modal
        isOpen={isOpenDeleteModal}
        centered
        className="modal-delete-product"
      >
        <ModalBody className="modal-delete-product-body">
          <p className="">Etes-vous sûr de vouloir supprimer ce Livreur?</p>
          <p className="info">
            <FontAwesomeIcon icon={faInfoCircle} /> Desolé vous n'avez pas ce
            pouvoir!
          </p>
          <div>
            <button className="btn-delete" onClick={toggleDeleteModal}>
              Fermer
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default EditBikerForm;
