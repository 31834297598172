import React, { useState } from "react";
import Sidebar from "../sidebar/sidebar";
import Upbar from "../upbar/upbar";
import { Switch, Route } from "react-router-dom";
import Products from "../products/products";
import Home from "../home/home";
import Clients from "../users/clients";
import Helps from "../helps/helps";
import PointOfSale from "../point-of-sale/point-of-sale";
import Orders from "../orders/orders";
import Settings from "../settings/settings";
import Bikers from "../users/bikers";
import "./main.scss";
import Restaurant from "../restaurant/restaurant";
// import NoStore from "../../noStore";

function MainStoreRoute() {
  const [isShowSidebar, setisShowSidebar] = useState(false);
  return (
    <div className="main">
      <Upbar
        setisShowSidebar={setisShowSidebar}
        isShowSidebar={isShowSidebar}
      />
      <Sidebar
        setisShowSidebar={setisShowSidebar}
        isShowSidebar={isShowSidebar}
      />
      <div className={`container-routes ${isShowSidebar && "hide"}`}>
        <Switch>
          {/* <Route exact path="/store" component={NoStore} /> */}
          <Route exact path="/store/:storeId" component={Home} />
          <Route path="/store/:storeId/products" component={Products} />
          <Route path="/store/:storeId/menu" component={Restaurant} />
          <Route path="/store/:storeId/bikers" component={Bikers} />
          <Route path="/store/:storeId/clients" component={Clients} />
          <Route path="/store/:storeId/point-of-sale" component={PointOfSale} />
          <Route path="/store/:storeId/orders_history" component={Orders} />
          <Route path="/store/:storeId/settings" component={Settings} />
          <Route path="/store/:storeId/help" component={Helps} />
        </Switch>
      </div>
    </div>
  );
}

export default MainStoreRoute;
