import React, {useState} from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";
import PaymentForm from "./paymentForm";
import PaymentItem from "./paymentItem";

function Payments() {
  useFirestoreConnect(() => [
    {
      collection: "networks",
    },
  ]);
  const [isOpenPaymentForm, setisOpenPaymentForm] = useState(false)
  const networks = useSelector((state) => state.firestore.ordered.networks);

  const togglePaymentForm = ()=> {
    setisOpenPaymentForm(prev => !prev)
  }

  return (
    <div className="container-payments-settings">
      <div className="payments-header">
        <h4>Réseaux télécoms</h4>
        <button onClick={togglePaymentForm} disabled>Ajouter</button>
      </div>
      <div className="">
        {!isLoaded(networks) ? (
          <div className="loading-data-indicator">
            <p>En cours de chargement ...</p>
          </div>
        ) : isEmpty(networks) ? (
          <div className="empty-list-data">
            <p>Aucun réseau trouvé!</p>
          </div>
        ) : (
          <div className="row mt-3">
            {networks.map((network) => (
              <PaymentItem key={network.id} network={network} />
            ))}
          </div>
        )}
      </div>
      <PaymentForm isOpen={isOpenPaymentForm} toggle={togglePaymentForm}/>
    </div>
  );
}

export default Payments;
