import React, { useState } from "react";
import { ListGroup, Row } from "reactstrap";
import PreparingOrderItem from "./preparingOrderItem";
import EmptyDataList from "../loadingIndicator/emptyDataList";

function PreparingOrders({ orders }) {
  return orders.length > 0 ? (
    <div className="container-orders">
      <Row className="list-home-orders">
        {orders.map((order) => (
          <div key={order.id} className="col-sm-6 col-md-4 col-xl-3">
            <PreparingOrderItem order={order} />
          </div>
        ))}
      </Row>
    </div>
  ) : (
    <EmptyDataList message="Aucune commande en cours de préparation!" />
  );
}

export default PreparingOrders;
