import React, { useEffect, useState } from "react";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import LoadingIndicator from "../loadingIndicator/loadingIndicator";
import EmptyDataList from "../loadingIndicator/emptyDataList";
import BikerActivityItem from "./bikerActivityItem";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useParams } from "react-router-dom";

function BikerActivities({ bikerId }) {
  const {storeId} = useParams()
  useFirestoreConnect(() => [
    {
      collection: "orders",
      where: ["user.city", "==", storeId],
      orderBy: ["createdAt", "asc"],
      storeAs: "orders",
    },
  ]);
  const [searchString, setsearchString] = useState("");
  const [filterDelivery, setfilterDelivery] = useState("Toutes");
  const [bikerActivities, setbikerActivities] = useState([]);
  const [currentBikerActivities, setcurrentBikerActivities] = useState([]);
  const orders = useSelector((state) => state.firestore.ordered.orders);

  const onSearch = (e) => {
    const value = e.target.value.replace("+", "");
    setsearchString(value);
    if (value !== "") {
      const regexToSearch = RegExp(value, "i");
      const searchResult = bikerActivities.filter((activity) =>
        activity.orderNumber.match(regexToSearch)
      );
      setcurrentBikerActivities(searchResult);
    } else {
      setcurrentBikerActivities(bikerActivities);
    }
    setfilterDelivery("Toutes")
  };

  const searchByDelivery = (status) => {
    switch (status) {
      case "delivered":
        setcurrentBikerActivities(
          bikerActivities.filter((activity) => activity.status === "delivered")
        );
        setfilterDelivery("Livrée (s)")
        break;
      case "rejected":
        setcurrentBikerActivities(
          bikerActivities.filter((activity) => activity.status === "rejected")
        );
        setfilterDelivery("Non livrée (s)")
        break;
      default:
        setcurrentBikerActivities(bikerActivities);
        setfilterDelivery("Toutes")
        break;
    }
    
  };

  useEffect(() => {
    orders && setbikerActivities(orders.filter((order) =>order.rider && order.rider.uid === bikerId));

    return () => {};
  }, [orders]);
  useEffect(() => {
    setcurrentBikerActivities(bikerActivities);
    return () => {};
  }, [bikerActivities]);

  return (
    <div className="container-biker-activities">
      {bikerActivities.length != 0 && (
        <div className="header">
          <UncontrolledDropdown>
            <DropdownToggle className="btn-fresh-primary">
              {filterDelivery}{" "}
              <FontAwesomeIcon className="icon" icon={faCaretDown} />
            </DropdownToggle>
            <DropdownMenu className="drop-menu">
              <DropdownItem
                className="item"
                onClick={searchByDelivery.bind(this, "Toutes")}
              >
                Toutes
              </DropdownItem>
              <DropdownItem
                className="item"
                onClick={searchByDelivery.bind(this, "delivered")}
              >
                Livrée(s)
              </DropdownItem>
              <DropdownItem
                className="item"
                onClick={searchByDelivery.bind(this, "rejected")}
              >
                Non livrée(s)
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <div className="search-bar-activities ml-2 ">
            <form>
              <FontAwesomeIcon icon={faSearch} className="search-icon" />
              <input
                type="search"
                name="sellerToSearch"
                placeholder="Rechercher ici ..."
                value={searchString}
                onChange={onSearch}
              />
            </form>
          </div>
        </div>
      )}
      {!isLoaded(orders) ? (
        <LoadingIndicator />
      ) : currentBikerActivities.length === 0 ? (
        <EmptyDataList message="Aucune activité!" />
      ) : (
        <div className="row list-activities">
          {currentBikerActivities.map((activity) => (
            <div key={activity.id} className="col-sm-6 col-md-4 col-xl-3">
              <BikerActivityItem order={activity} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default BikerActivities;
