import React, {useState} from "react";
import { Container, ListGroup, ListGroupItem } from "reactstrap";
import "./settings.scss";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import Units from "./units";
import Categoriesfresh from "./categoriesfresh";
import Categoriesdish from "./categoriesdish";
import Cities from "./cities";
import Payments from "./payments";

const Settings = () => {
  const history = useHistory()
  const location = useLocation()
  const [currentPath, setcurrentPath] = useState(location.pathname);

  const goTo = (path) =>{
    setcurrentPath(path)
    history.push(path)
  }

  return (
    <Container fluid className="container-settings">
      <div>
        <h4 className="font-weight-normal">Paramètres </h4>
        <div className="container">
          <div className="row">
            <div className="col-4">
              <ListGroup flush>
                <ListGroupItem className={`setting-item ${currentPath.match("/d/settings/cities")&&"active-menu"}`} onClick={goTo.bind(this, "/d/settings/cities")}>Villes</ListGroupItem>
                <ListGroupItem className={`setting-item ${currentPath.match("/d/settings/units")&&"active-menu"}`} onClick={goTo.bind(this, "/d/settings/units")}>Unités de mesure</ListGroupItem>
                <ListGroupItem className={`setting-item ${currentPath.match("/d/settings/categories-fresh")&&"active-menu"}`} onClick={goTo.bind(this, "/d/settings/categories-fresh")}>Catégories frais</ListGroupItem>
                <ListGroupItem className={`setting-item ${currentPath.match("/d/settings/categories-dish")&&"active-menu"}`} onClick={goTo.bind(this,"/d/settings/categories-dish")}>Catégories repas</ListGroupItem>
                <ListGroupItem className={`setting-item ${currentPath.match("/d/settings/payments")&&"active-menu"}`} onClick={goTo.bind(this,"/d/settings/payments")}>Paiements</ListGroupItem>
                <ListGroupItem >Autres</ListGroupItem>
              </ListGroup>
            </div>
            <div className="col-8">
              <Switch>
                  <Route exact path="/d/settings/cities" component={Cities}/>
                  <Route path="/d/settings/units" component={Units}/>
                  <Route path="/d/settings/categories-fresh" component={Categoriesfresh}/>
                  <Route path="/d/settings/categories-dish" component={Categoriesdish}/>
                  <Route path="/d/settings/payments" component={Payments}/>
              </Switch>
            </div>
          </div>
        </div>
      </div>
     
    </Container>
  );
};

export default Settings;
