import React, { useEffect, useState } from "react";
import { Row } from "reactstrap";
import UIfx from "uifx";
import {toast} from "react-toastify"
import NewOrderItem from "./newOrderItem";
import EmptyDataList from "../loadingIndicator/emptyDataList";
import fileSound from "../../../../sounds/beep.mp3";

function NewOrders({ orders }) {
  const [oldOrdersLength, setoldOrdersLength] = useState(0);
  const soundNotification = new UIfx(fileSound);

  useEffect(() => {
    if (orders) {
      if (orders.length > oldOrdersLength) {
        toast.success("Nouvelle commande")
        soundNotification.play();
      }
      setTimeout(() => {
        setoldOrdersLength(orders.length);
      }, 100);
    }

    return () => {};
  }, [orders]);

  return orders.length > 0 ? (
    
    <div className="container-orders">
      <Row className="list-home-orders">
        {orders.map((order) => (
          <div className="col-sm-6 col-md-4 col-xl-3" key={order.id}>
            <NewOrderItem order={order}/>
          </div>
        ))}
      </Row>
    </div>
  ) : (
    <EmptyDataList message="Aucune nouvelle commande!" />
  );
}

export default NewOrders;
