import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHistory,
  faArrowRight,
  faBiking,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Badge,
} from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { useFirestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";
import "./home.scss";
import NewOrders from "./newOrders";
import DeliveryOrders from "./deliveryOrders";
import PreparingOrders from "./preparingOrders";
import LoadingIndicator from "../loadingIndicator/loadingIndicator";
import EmptyDataList from "../loadingIndicator/emptyDataList";


function Home() {
  const {storeId}=useParams()
  useFirestoreConnect(() => [
      {
          collection:"stores",
          doc:storeId,
      },
    {
      collection: "orders",
      where: ["user.city", "==", storeId],
      orderBy: ["createdAt", "asc"],
      storeAs: "orders",
    },
    {
      collection: "users",
      where: [
        ["city", "==", storeId],
        ["allowed", "==", true],
        ["status", "==", "free"],
      ],
      storeAs: "freeBikers",
    },
  ]);

  const history = useHistory();
  const [viewFreeBikers, setviewFreeBikers] = useState(false)
  const [activeTab, setactiveTab] = useState("1");
  const [newOrders, setnewOrders] = useState([]);
  const [preparingOrders, setpreparingOrders] = useState([]);
  const [deliveryOrders, setdeliveryOrders] = useState([]);
  const store = useSelector(({ firestore: { data } }) => data.stores && data.stores[storeId])
  const orders = useSelector((state) => state.firestore.ordered.orders);
  const freeBikers = useSelector((state) => state.firestore.ordered.freeBikers);
  const toggleCurrentTab = (index) => {
    activeTab !== index && setactiveTab(index);
  };

  const goTo = (path) => {
    history.push(path);
  };

  const toggleViewFreeBikers=()=>{
    setviewFreeBikers(prev=>!prev)
  }

  useEffect(() => {
    if (orders) {
      setnewOrders(orders.filter((order) => order.status === "sent"));
      setpreparingOrders(orders.filter((order) => order.status === "accepted"));
      setdeliveryOrders(orders.filter((order) => order.status === "delegated"));
    }
    return () => {};
  }, [orders]);

  useEffect(() => {
    store&&localStorage.setItem("deliveryMinTime",`${store.deliveryMinTime}`)
    return () => {
      
    }
  }, [store])

  return (
    <div className="home-container">
      <div className="home-content">
        <Nav className="nav-orders">
          <NavItem className="">
            <NavLink
              className={classnames("link-tab", {
                active: activeTab === "1",
              })}
              onClick={toggleCurrentTab.bind(this, "1")}
            >
              NOUVEAU{" "}
              <Badge color="danger" className="bagde" pill>
                {orders && newOrders.length != 0 && newOrders.length}
              </Badge>
              <FontAwesomeIcon icon={faArrowRight} className="icon" />
            </NavLink>
          </NavItem>
          <NavItem className="tab">
            <NavLink
              className={classnames("link-tab",{ active: activeTab === "2" })}
              onClick={toggleCurrentTab.bind(this, "2")}
            >
              PREPARATION{" "}
              <Badge color="warning" className="bagde" pill>
                {orders &&
                  preparingOrders.length != 0 &&
                  preparingOrders.length}
              </Badge>
              <FontAwesomeIcon icon={faArrowRight} className="icon" />
            </NavLink>
          </NavItem>
          <NavItem className="tab">
            <NavLink
              className={classnames("link-tab", { active: activeTab === "3" })}
              onClick={toggleCurrentTab.bind(this, "3")}
            >
              LIVRAISON{" "}
              <Badge color="success" className="bagde" pill>
                {orders && deliveryOrders.length != 0 && deliveryOrders.length}
              </Badge>
              <FontAwesomeIcon icon={faArrowRight} className="icon" />
            </NavLink>
          </NavItem>
          <NavItem>
            <button
              className="ml-1"
              size="sm"
              onClick={goTo.bind(this, `/store/${storeId}/orders_history`)}
            >
              <FontAwesomeIcon icon={faHistory} />
            </button>
          </NavItem>
        </Nav>

        {!isLoaded(orders) ? (
          <LoadingIndicator />
        ) : isEmpty(orders) ? (
          <EmptyDataList message="Aucune commande trouvée!" />
        ) : (
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {/* <Row> */}
                <Col>
                  <NewOrders orders={newOrders}/>
                </Col>
              {/* </Row> */}
            </TabPane>
            <TabPane tabId="2">
              {/* <Row> */}
                <Col>
                  <PreparingOrders orders={preparingOrders} />
                </Col>
              {/* </Row> */}
            </TabPane>
            <TabPane tabId="3">
              {/* <Row> */}
                <Col>
                  <DeliveryOrders orders={deliveryOrders} />
                </Col>
              {/* </Row> */}
            </TabPane>
            {viewFreeBikers&&<div className="float-bikers">
                {!isLoaded(freeBikers) ? (
                  <LoadingIndicator />
                ) : isEmpty(freeBikers) ? (
                  <div className="empty-bikers-list"><FontAwesomeIcon icon={faBiking}/><p>Aucun</p></div>
                ) : (
                  freeBikers.map((biker) => (
                    <div key={biker.id} className="card-biker">
                      <img src={biker.avatar} />
                      <p>{biker.name}</p>
                    </div>
                  ))
                )}
              </div>}
              <button className="btn-float-bikers" onClick={toggleViewFreeBikers}>
                {" "}
                <FontAwesomeIcon icon={viewFreeBikers?faEyeSlash: faBiking} />{" "}
              </button>
          </TabContent>
        )}
      </div>
    </div>
  );
}

export default Home;
