import React, { useState } from "react";
import {
  ListGroupItem,
  Form,
  Button,
  Input,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
  faEllipsisV,
  faEdit,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { useFirestore } from "react-redux-firebase";
import { useForm } from "react-hook-form";
import ToastMessage from "../toast/toast"

const CategoryItem = ({
  category,
  viewCurrentGalery,
  currentId
}) => {
  const { register, handleSubmit } = useForm();
  const [startUpdate, setstartUpdate] = useState(false);
  const [isSave, setisSave] = useState(false)
  const [showSuccess, setshowSuccess] = useState(false)
  const [showDanger, setshowDanger] = useState(false)
  const db = useFirestore()

  let activemenu = "";
  if (currentId === category.id) {
    activemenu = "active-index";
  }

  const handleShowSuccess = () => {
    setshowSuccess(!showSuccess)
  }
  const handleShowDanger = () => {
    setshowDanger(!showDanger)
  } 

  const updateCategory = data => {
    setisSave(true)
    db.update(`categories/${category.id}`, {category:data.updatecategory})
    .then(res => {
      setisSave(false)
      setstartUpdate(false);
      handleShowSuccess()
    }).catch(err => {
      setisSave(false)
      handleShowDanger()
    })
  };

  const deleteCategory = () => {
    db.delete(`categories/${category.id}`)
  };

  const handleStartUpdate = () => {
    setstartUpdate(true);
  };
  return (
    <React.Fragment>
      <ListGroupItem
        className={`list-categories-item py-1 ${activemenu}`}
        onClick={viewCurrentGalery.bind(this, category.category, category.id)}
      >
        <div className="category-info">
          <p className="my-0"> {category.category} </p>
          <UncontrolledButtonDropdown direction="left">
            <DropdownToggle className="edit-toggle">
              <FontAwesomeIcon icon={faEllipsisV} size="sm" />
            </DropdownToggle>
            <DropdownMenu className="py-0">
              <DropdownItem onClick={handleStartUpdate}>
                <FontAwesomeIcon icon={faEdit} size="sm" /> Modifier
              </DropdownItem>
              <DropdownItem onClick={deleteCategory.bind(this, category.id)}>
                <FontAwesomeIcon icon={faTrash} size="sm" /> Supprimer
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
          <FontAwesomeIcon
            className="icon-left-active-city"
            icon={faChevronLeft}
            size="sm"
          />
          <FontAwesomeIcon
            className="icon-right-active-city"
            icon={faChevronRight}
            size="sm"
          />
        </div>
        {startUpdate && (
          <Form
            onSubmit={handleSubmit(updateCategory)}
            className="form-category-update"
          >
            <Input
              type="text"
              bsSize="sm"
              name="updatecategory"
              defaultValue={category.category}
              disabled={isSave ? true : false}
              innerRef={register({ required: true })}
            />
            <Button
              size="sm"
              type="submit"
              className="ml-1"
              disabled={isSave ? true : false}>
              {!isSave ? (
                "Enregistrer"
              ) : (
                <Spinner color="light" style={{height:"16px", width:"16px"}}  />
              )}
            </Button>
          </Form>
        )}
      </ListGroupItem>
      <ToastMessage
        isShow={showSuccess}
        toggle={handleShowSuccess}
        message="Enregistré"
        delay={4000}
        icon="check"
        className="bg-success text-white pr-5 mr-3"
      />
      <ToastMessage
        isShow={showDanger}
        toggle={handleShowDanger}
        message="Ouf, une erreur est survenue!"
        delay={5000}
        icon="check"
        className="bg-danger text-white pr-5"
      />
    </React.Fragment>
  );
};

export default CategoryItem;
