import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Input,
  Label,
  FormGroup,
  Form,
  Button,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useFirebase, useFirestore } from "react-redux-firebase";

const EditLivreurForm = ({
  cities,
  currentRiderToEdit,
  setcurrentRiderToEdit,
  setshowSuccess
}) => {
  const [isSave, setisSave] = useState(false);
  const [isUpload, setisUpload] = useState(false);
  const [uploadAvatarProgress, setuploadAvatarProgress] = useState(0);
  const [avatar, setAvatar] = useState("");
  const { register, handleSubmit } = useForm();
  const db = useFirestore();
  const firebase = useFirebase();

  

  const onSubmit = (data) => {
    setisSave(true);
    db.update(`riders/${currentRiderToEdit.id}`, {
      ...data,
      avatar: avatar,
    }).then((res) => {
      setuploadAvatarProgress(0);
      setAvatar("");
      setisSave(false);
      setshowSuccess(true);
      setcurrentRiderToEdit();
    });
  };

  const onDropAvatar = (files) => {
    setisUpload(true);
    let uploadTask = firebase
      .storage()
      .ref()
      .child("avatars/" + Date.now())
      .put(files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        setuploadAvatarProgress(progress);
      },
      (error) => {
        setisUpload(false);
        console.log("Erreur d'upLoad");
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log(downloadURL);
          setisUpload(false);
          setAvatar(downloadURL);
        });
      }
    );
  };

  useEffect(() => {
    currentRiderToEdit && setAvatar(currentRiderToEdit.avatar);
    return () => {};
  }, [currentRiderToEdit]);
  return (
    <div className="edit-rider-form p-2">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="header-edit">
          <h5>Modifier {currentRiderToEdit.name}</h5>
        </div>
        <div>
          <Row form>
            <Col className="col-8">
              <FormGroup>
                <Label className="font-weight-bold my-0" size="sm">
                  <span className="text-success">*</span>
                  Noms
                </Label>
                <Input
                  type="text"
                  bsSize="sm"
                  name="name"
                  defaultValue={currentRiderToEdit.name}
                  disabled={isSave ? true : false}
                  innerRef={register({ required: true, minLength: 2 })}
                />
              </FormGroup>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label className="font-weight-bold my-0" size="sm">
                      <span className="text-success">*</span>Sexe
                    </Label>
                    <Input
                      type="select"
                      bsSize="sm"
                      defaultValue={currentRiderToEdit.sex}
                      name="sex"
                      disabled={isSave ? true : false}
                      innerRef={register({ required: true })}
                    >
                      <option></option>
                      <option value="M">M</option>
                      <option value="F">F</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label className="font-weight-bold my-0" size="sm">
                      <span className="text-success">*</span>Etat civil
                    </Label>
                    <Input
                      type="select"
                      bsSize="sm"
                      defaultValue={currentRiderToEdit.stateCivil}
                      name="stateCivil"
                      disabled={isSave ? true : false}
                      innerRef={register({ required: true })}
                    >
                      <option></option>
                      <option value="Marié (e)">Marié (e)</option>
                      <option value="Célibataire">Célibataire</option>
                      <option value="Veuf">Veuf (ve)</option>
                      <option value="Divorcé (e)">Divorcé (e)</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
            <Col>
              <FormGroup>
                <Label className="font-weight-bold my-0" size="sm">
                  <span className="text-success">*</span>
                  Téléphone
                </Label>
                <Input
                  type="tel"
                  name="phone"
                  defaultValue={currentRiderToEdit.phone}
                  disabled={true}
                  innerRef={register({ required: true })}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  className="font-weight-bold my-0"
                  size="sm"
                  disabled={isSave ? true : false}
                >
                  Ville ou cité d'affectation
                </Label>
                <Input
                  type="select"
                  name="city"
                  disabled={isSave ? true : false}
                  defaultValue={currentRiderToEdit.city}
                  innerRef={register({ required: true })}
                >
                  <option></option>
                  {cities &&
                    cities.map((city) => (
                      <option key={city.id} value={city.city}>
                        {city.city}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
            </Col>
            <Col className="col-4">
              <Label className="font-weight-bold text-right my-0" size="sm">
                Avatar{" "}
                {avatar !== "" && (
                  <FontAwesomeIcon icon={faCheck} className="text-success" />
                )}
              </Label>
              <div className="col-avatar-livreur">
                {isUpload ? (
                  <Spinner
                    color="light"
                    className="mb-1"
                    style={{ height: "100px", width: "100px" }}
                  />
                ) : (
                  <img
                    src={
                      avatar !== ""
                        ? avatar
                        : "/assets/images/user-placeholder.png"
                    }
                    className="mb-1"
                    height="165px"
                    width="100%"
                  />
                )}
                <Dropzone
                  onDrop={onDropAvatar}
                  accept="image/png,image/jpeg,image/gif"
                  multiple={false}
                >
                  {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragReject,
                  }) => (
                    <div
                      {...getRootProps()}
                      style={{ width: "80px" }}
                      className="avatar-form"
                    >
                      <input
                        {...getInputProps()}
                        disabled={isSave || isUpload ? true : false}
                      />
                      <div className="parcourir-btn-file-upload py-0">
                        Parcourir
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
            </Col>
          </Row>
          
          <FormGroup>
            <Label className="font-weight-bold my-0" size="sm">
              Adresse locale
            </Label>
            <Input
              type="textarea"
              rows="3"
              name="adresse"
              defaultValue={currentRiderToEdit.adresse}
              disabled={isSave ? true : false}
              innerRef={register({})}
            />
          </FormGroup>
        </div>
        <div className="footer-modal-livreur text-right mb-2">
          <Button
            className="px-3 font-weight-bold mr-2"
            size="sm"
            color="success"
            type="submit"
            disabled={isSave ? true : false}
          >
            {!isSave ? (
              "Enregistrer"
            ) : (
              <Spinner
                color="light"
                className="spinner-isSaving"
                style={{ height: "16px", width: "16px" }}
              />
            )}
          </Button>
          <Button
            className="px-3 font-weight-bold"
            size="sm"
            onClick={() => setcurrentRiderToEdit()}
            disabled={isSave ? true : false}
          >
            Annuler
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EditLivreurForm;
