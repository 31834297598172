import React from "react";
import { Modal } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSave } from "@fortawesome/free-solid-svg-icons";

function NewClientForm({ isOpen, toggle }) {
  return (
    <Modal isOpen={isOpen} centered>
      <div className="modal-header">
        <h5>Nouveau client</h5>
        <button onClick={toggle}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <div className="modal-body"></div>
      <div className="modal-footer">
        <button>
          <FontAwesomeIcon icon={faSave} className="icon" />
          Sauvegarder
        </button>
        <button className="btn-cancel" onClick={toggle}>
          <FontAwesomeIcon icon={faTimes}/> Annuler
        </button>
      </div>
    </Modal>
  );
}

export default NewClientForm;
