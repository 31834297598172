import React from "react";
import { ListGroupItem, CustomInput, Modal, Badge } from "reactstrap";
import { useHistory,useParams } from "react-router-dom";

function BikerItem({ biker }) {
  const {storeId} = useParams()
  const history = useHistory();
  const goTo = (path) => {
    history.push(path);
  };

  return (
    <ListGroupItem
      className="client-item"
      onClick={goTo.bind(this, `/store/${storeId}/bikers/edit/${biker.id}`)}
    >
      <CustomInput type="checkbox" id={`header-${biker.id}`} className="mr-3" />
      <div className="name">
        <img src={biker.avatar} />
        {biker.name}
      </div>
      <div className="phoneNumber">
        <p className="badge">{biker.phone}</p>
      </div>
      <div className="city">
        <Badge className={`badge ${biker.allowed&&"allowed"}`}>{biker.allowed?"Autorisé":"Bloqué"}</Badge>
      </div>
    </ListGroupItem>
  );
}

export default BikerItem;
