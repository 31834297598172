import React, {useState} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import {DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, UncontrolledDropdown} from "reactstrap"
import Dropzone from "react-dropzone";
import { useFirebase, useFirestore } from "react-redux-firebase";


function SellerItem({seller}) {
    const firebase =useFirebase()
    const db=useFirestore()
    const {register, handleSubmit} =useForm()
    const [isOpen, setisOpen] = useState(false)
    const [isSave, setisSave] = useState(false)
    const [isUpload, setisUpload] = useState(false)
    const [isDeleting, setisDeleting] = useState(false)
    const [isOpenDeleteSeller, setisOpenDeleteSeller] = useState(false)
    const [logoUrl, setlogoUrl] = useState()
    const [uploadFileProgress, setuploadFileProgress] = useState(0)

    const toggle=()=>{
        setisOpen(prev=>!prev)
    }
const toggleDeleteSeller=()=>{
    setisOpenDeleteSeller(prev=>!prev)
}
    const saveToDB = (data) => {
        setisSave(true)
        db.update(`sellers/${seller.id}`,{...data, logoUrl:logoUrl?logoUrl:seller.logoUrl}).then(res=>{
          setisSave(false)
          toggle()
        }).catch(err=>{
            setisSave(false)
        })
    };

    const deleteSeller=(id)=>{
        setisDeleting(true)
        db.delete(`sellers/${id}`).then(res=>{
            setisDeleting(true)
            toggleDeleteSeller()
            toggle()
        }).catch(res=>{
            setisDeleting(true)
        })
    }

    const onDropFiles = (files) => {
        setisUpload(true);
        let uploadTask = firebase
          .storage()
          .ref()
          .child("sellersLogos/" + Date.now())
          .put(files[0]);
    
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            setuploadFileProgress(progress);
          },
          (error) => {
            setisUpload(false);
            console.log("Erreur d'upLoad");
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              console.log(downloadURL);
              setlogoUrl(downloadURL);
              setisUpload(false);
            });
          }
        );
      };
    

    return (
        <div className="seller-item" onClick={toggle}>
                        <img src={seller.logoUrl}/>
                        <h6>{seller.name}</h6>
                        <Modal isOpen={isOpen} className="modal-new-seller">
      <form onSubmit={handleSubmit(saveToDB)}>
        <ModalHeader toggle={!isSave&&toggle}>Vendeur {seller.name}</ModalHeader>
        <ModalBody>
          <div className="div-image-category">
            <label>Logo de l'entreprise du vendeur</label>

            <Dropzone
              onDrop={onDropFiles}
              accept="image/jpeg,image/png"
              multiple={false}
            >
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragReject,
              }) => (
                <div
                  {...getRootProps()}
                  className={`dropzone-image`}
                  style={{
                    backgroundImage: `url(${logoUrl?logoUrl:seller.logoUrl})`,
                    backgroundRepeat: "none",
                    backgroundSize: "cover",
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  <input
                    {...getInputProps()}
                    disabled={isSave || isUpload ? true : false}
                  />
                  {!isUpload ? (
                    <div className="btn-upload">
                      <p>
                        <FontAwesomeIcon icon={faCamera} />
                      </p>
                      <h6>Upload</h6>
                    </div>
                  ) : (
                    <div className="spinner-progress">
                      <Spinner />
                      <p>{uploadFileProgress.toFixed(0)}%</p>
                    </div>
                  )}
                </div>
              )}
            </Dropzone>
          </div>
          <div className="div-input">
            <label><span>*</span>Nom de l'entreprise</label>
            <input type="text" name="name" defaultValue={seller.name} ref={register({ required: true })} disabled={isSave}/>
          </div>
          <div className="row">
            <div className="col">
              <div className="div-input">
                <label>Téléphone</label>
                <input  name="phone" ref={register} defaultValue={seller.phone} disabled={isSave} />
              </div>
            </div>
            <div className="col">
              <div className="div-input">
                <label>Email</label>
                <input type="email" name="email" defaultValue={seller.email} ref={register} disabled={isSave}/>
              </div>
            </div>
          </div>
          <Modal isOpen={isOpenDeleteSeller} centered>
                  <ModalHeader toggle={!isDeleting && toggleDeleteSeller}>Suppression</ModalHeader>
                <ModalBody>
                    <p>Etes-vous sûr de vouloir supprimer {seller.name}?</p>
                    <div className="text-center">
                        <button type="button" onClick={deleteSeller.bind(this, seller.id)} disabled={isDeleting}>{isDeleting?<Spinner/>:"Oui"}</button>
                        <button type="button" className="ml-3" onClick={toggleDeleteSeller} disabled={isDeleting}>Non</button>
                    </div>
                </ModalBody>
          </Modal>
        </ModalBody>
        <ModalFooter>
          <button type="submit" className="btn-save" disabled={isSave}>Sauvegarder</button>
          <UncontrolledDropdown>
              <DropdownToggle disabled={isSave}><FontAwesomeIcon icon={faCaretDown}/></DropdownToggle>
              <DropdownMenu>
                  <DropdownItem onClick={deleteSeller.bind(this, seller.id)}>
                      Supprimer
                  </DropdownItem>
              </DropdownMenu>
          </UncontrolledDropdown>
        </ModalFooter>
      </form>
    </Modal>
        </div>
    )
}

export default SellerItem
