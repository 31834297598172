import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Spinner,
  CustomInput,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faImages,
  faTimes,
  faSave,
  faSearch,
  faCheck,
  faCheckCircle,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import {
  useFirebase,
  useFirestore,
  useFirestoreConnect,
  isLoaded,
  isEmpty,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import {toast} from "react-toastify"
import LoadingIndicator from "../loadingIndicator/loadingIndicator";
import EmptyDataList from "../loadingIndicator/emptyDataList";
import ImageItemGallery from "../products/imageItemGallery";


function NewDishForm() {
  const {storeId} =useParams()
    useFirestoreConnect(() => [
        {
          collection: "units",
          orderBy: ["unit", "asc"],
        },
        {
          collection: "categories",
          orderBy: ["category", "asc"],
        },
        {
          collection: "galleryDishes",
        //   orderBy: ["category", "asc"],
          storeAs: "galleryDishes",
        },
        {
          collection: "sellers",
          orderBy: ["name", "asc"],
        },
      ]);
    
      const history = useHistory();
      const firebase = useFirebase();
      const db = useFirestore();
      const { register, handleSubmit } = useForm();
      const [isUpload, setisUpload] = useState(false);
      const [isSave, setisSave] = useState(false);
      const [imageUrl, setimageUrl] = useState();
      const [isRequiredImage, setisRequiredImage] = useState(false);
      const [isOpenGallery, setisOpenGallery] = useState(false);
      const [uploadFileProgress, setuploadFileProgress] = useState(0);
      const [activeTab, setactiveTab] = useState("1");
      const [selectedSeller, setselectedSeller] = useState()
      const [currentSellers, setcurrentSellers] = useState([])
      const [stringToSearch, setstringToSearch] = useState("")
      const units = useSelector((state) => state.firestore.ordered.units);
      const categories = useSelector(
        (state) => state.firestore.ordered.categories
      );
    
      const gallery = useSelector((state) => state.firestore.ordered.galleryDishes);
    
      const sellers = useSelector((state) => state.firestore.ordered.sellers);
    
      const goTo = (path) => {
        history.push(path);
      };
    
      const toggleGallery = () => {
        setisOpenGallery((prev) => !prev);
      };
    
      const toggleActiveTab = (tab) => {
        if (activeTab !== tab) setactiveTab(tab);
      };
    
      const setAsImageUrl = (url) => {
        setimageUrl(url);
        toggleGallery();
      };
    
      const setSellerToProduct = (seller) => {
        setselectedSeller(seller);
        toast.success(`Vendeur ${seller.name} sélectionné`)
      };

      const saveToDB = (data) => {
        if (imageUrl) {
          setisSave(true);
    
          db.add(`stores/${storeId}/products`, {
            ...data,
            imageUrl: imageUrl ? imageUrl : "",
            isFresh: false,
            seller:selectedSeller?selectedSeller: {
              name: "Tayarifood",
              phone: "",
              email: "",
              logoUrl:
                "https://firebasestorage.googleapis.com/v0/b/tayarifood.appspot.com/o/tayari_logo.png?alt=media&token=e9d7d1bb-70b5-4cdc-9c50-e5768df92138",
            },
            createdAt: db.Timestamp.now().toMillis(),
          })
            .then((res) => {
              toast.success("Sauvegardé")
              history.push(`/store/${storeId}/menu`);
            })
            .catch((err) => {});
        } else {
          setisRequiredImage(true);
        }
      };

      const onSearchSeller = (e)=>{
        const value = e.target.value.replace("+","")
        setstringToSearch(value)
        if(value!=""){
          const regexToSearch = RegExp(value, "i")
          const searchResult=sellers.filter(seller=>seller.name.match(regexToSearch))
          setcurrentSellers(searchResult)
        }else{
          setcurrentSellers(sellers)
        }
      }
    
      const onDropFiles = (files) => {
        setisUpload(true);
        setisRequiredImage(false);
        let uploadTask = firebase
          .storage()
          .ref()
          .child("galleryDishes/" + Date.now())
          .put(files[0]);
    
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            setuploadFileProgress(progress);
          },
          (error) => {
            setisUpload(false);
            console.log("Erreur d'upLoad");
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              console.log(downloadURL);
              setimageUrl(downloadURL);
              setisUpload(false);
            });
          }
        );
      };

      useEffect(() => {
        sellers && setcurrentSellers(sellers)
        return () => {
          
        }
      }, [sellers])

    return (
        <div className="container-new-product">
      <form onSubmit={handleSubmit(saveToDB)}>
        <div className="new-product-header">
          <div className="div-titles">
            <h4>
              <Link
                to={`${!isSave ? `/store/${storeId}/menu` : `/store/${storeId}/menu/new`}`}
                className="link"
              >
                Plats
              </Link>{" "}
              / Nouveau
            </h4>
            
          </div>
          <div className="div-actions">
            <button type="submit" className="btn-save" disabled={isSave}>
              {isSave ? (
                <Spinner className="spinner" />
              ) : (
                <span>
                  <FontAwesomeIcon icon={faSave} /> Sauvegarder
                </span>
              )}
            </button>
            <button
              onClick={goTo.bind(this, `/store/${storeId}/menu`)}
              disabled={isSave}
            >
              <FontAwesomeIcon icon={faTimes} /> Fermer
            </button>
            <div className="stock-input">
              <label>Stock</label>
              <input
                type="number"
                defaultValue="0"
                name="stock"
                ref={register({ required: true })}
                disabled={isSave}
              />
            </div>
          </div>
        </div>
        <div className="new-product-body">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={`link-tab ${activeTab === "1" && "active"} `}
                onClick={() => {
                  toggleActiveTab("1");
                }}
              >
                Informations générales
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`link-tab ${activeTab === "2" && "active"} `}
                onClick={() => {
                  toggleActiveTab("2");
                }}
              >
                Vendeur/Fournisseur
              </NavLink>
            </NavItem>
          </Nav>
          {/* <div className="container"> */}
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className="row mt-2">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-8">
                      <div className="div-input">
                        <label>
                          <span>*</span>Nom du produit
                        </label>
                        <input
                          type="text"
                          name="name"
                          ref={register({ required: true })}
                          disabled={isSave}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                        <div className="div-availability">
                          <label>Disponibilité</label>
                          <CustomInput
                             type="switch"
                             id="status"
                             name="available"
                             disabled={isSave}
                             label=""
                             innerRef={register}
                          />
                        </div>
                      </div>
                  </div>
                  <div className="div-input">
                    <label>Catégorie</label>
                    <select name="category" disabled={isSave} ref={register}>
                      <option></option>
                      {categories &&
                        categories.map((category) => (
                          <option value={category.category}>
                            {category.category}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="div-input">
                        <label>
                          <span>*</span>Prix unitaire
                        </label>
                        <input
                          type="number"
                          name="price"
                          ref={register({ required: true })}
                          disabled={isSave}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="div-input">
                        <label>Unité de mesure</label>
                        <select
                          type="number"
                          name="unit"
                          ref={register}
                          disabled={isSave}
                        >
                          <option></option>
                          {units &&
                            units.map((unit) => (
                              <option value={unit.unit}>{unit.unit}</option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="div-input">
                    <label>Description du produit</label>
                    <textarea
                      name="description"
                      rows="3"
                      ref={register}
                      disabled={isSave}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-image-product">
                  <label>
                    <span>*</span>Image du produit
                  </label>

                  <Dropzone
                    onDrop={onDropFiles}
                    accept="image/jpeg"
                    multiple={false}
                  >
                    {({
                      getRootProps,
                      getInputProps,
                      isDragActive,
                      isDragReject,
                    }) => (
                      <div
                        {...getRootProps()}
                        className={`dropzone-image ${
                          isRequiredImage && "required"
                        }`}
                        style={{
                          backgroundImage: `url(${imageUrl && imageUrl})`,
                          backgroundRepeat: "none",
                          backgroundSize: "cover",
                          backgroundColor: "#f9f9f9",
                        }}
                      >
                        <input
                          {...getInputProps()}
                          disabled={isSave || isUpload ? true : false}
                        />
                        {!isUpload ? (
                          <div className="btn-upload">
                            <p>
                              <FontAwesomeIcon icon={faCamera} />
                            </p>
                            <h6>Upload</h6>
                          </div>
                        ) : (
                          <div className="spinner-progress">
                            <Spinner />
                            <p>{uploadFileProgress.toFixed(0)}%</p>
                          </div>
                        )}
                      </div>
                    )}
                  </Dropzone>
                  <div className="div-btn-gallery" onClick={toggleGallery}>
                    <button type="button">
                      <FontAwesomeIcon icon={faImages} /> Galerie
                    </button>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className="row mt-3">
                <div className="col">
                  <div className="div-current-seller">
                    <label>
                      <FontAwesomeIcon icon={faBuilding} /> Vendeur actuel
                    </label>
                    <div className="seller-info">
                      <img
                        src={
                          selectedSeller
                            ? selectedSeller.logoUrl
                            : "https://firebasestorage.googleapis.com/v0/b/tayarifood.appspot.com/o/tayari_logo.png?alt=media&token=e9d7d1bb-70b5-4cdc-9c50-e5768df92138"
                        }
                      />
                      <h6>
                        {selectedSeller ? selectedSeller.name : "Tayarifood"}
                      </h6>
                    </div>
                  </div>
                  <div className="container-list-sellers">
                    <label>
                      <FontAwesomeIcon icon={faCheckCircle} /> Sélectionner un
                      vendeur
                    </label>
                    {isLoaded(sellers) && !isEmpty(sellers) && (
                      <div className="search-bar">
                        <form>
                          <FontAwesomeIcon
                            icon={faSearch}
                            className="search-icon"
                          />
                          <input
                            type="search"
                            name="sellerToSearch"
                            placeholder="Rechercher ici ..."
                            value={stringToSearch}
                            onChange={onSearchSeller}
                          />
                        </form>
                      </div>
                    )}
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4 col-sm-4 col-md-3 col-lg-2 col-md-2 mb-3">
                          <div
                            className="seller-item"
                            onClick={setSellerToProduct.bind(this, {
                              name: "Tayarifood",
                              phone: "",
                              email: "",
                              logoUrl:
                                "https://firebasestorage.googleapis.com/v0/b/tayarifood.appspot.com/o/tayari_logo.png?alt=media&token=e9d7d1bb-70b5-4cdc-9c50-e5768df92138",
                            })}
                          >
                            <img src="/assets/images/tayari_logo.png" />
                            <h6>Tayarifood</h6>
                          </div>
                        </div>
                        {!isLoaded(sellers) ? (
                          <LoadingIndicator />
                        ) : currentSellers.length === 0 ? (
                          <div className="col-4 col-sm-4 col-md-3 col-lg-2 mb-3">
                            <div className="empty-sellers-list">
                              Aucun autre vendeur.
                            </div>
                          </div>
                        ) : (
                          currentSellers.map((seller) => (
                            <div
                              key={seller.id}
                              className="col-4 col-sm-4 col-md-3 col-lg-2 mb-3"
                            >
                              <div
                                className="seller-item"
                                onClick={setSellerToProduct.bind(this, seller)}
                              >
                                <img src={seller.logoUrl} />
                                <h6>{seller.name}</h6>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
          </TabContent>
          {/* ref */}
          {/* </div> */}
        </div>
      </form>
      <Modal isOpen={isOpenGallery} size="xl">
        <div className="modal-header">
          <h5>
            <FontAwesomeIcon icon={faImages} /> Galerie produits
          </h5>
          <button onClick={toggleGallery}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="modal-body">
          <div className="row">
            {!isLoaded(gallery) ? (
              <LoadingIndicator />
            ) : isEmpty(gallery) ? (
              <EmptyDataList message="Aucune image dans la galerie" />
            ) : (
              gallery.map((image) => (
                <div
                  className="col-6 col-lg-2 col-md-3 col-sm-4"
                  key={image.id}
                >
                  <ImageItemGallery
                    image={image}
                    setAsImageUrl={setAsImageUrl}
                  />
                </div>
              ))
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button onClick={toggleGallery}>
            <FontAwesomeIcon icon={faTimes} /> Fermer
          </button>
        </div>
      </Modal>
    </div>
    )
}

export default NewDishForm
