import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";

function SingleDishView({ isOpen, setisSingleDishView, dish }) {
  const toggle = () => {
    setisSingleDishView(false);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered >
      {/* <ModalHeader toggle={toggle} className=""><FontAwesomeIcon icon={faImage}/></ModalHeader> */}
      <ModalBody className="p-0">
        <img src={dish && dish.imageUrl} width="100%" />
      </ModalBody>
    </Modal>
  );
}

export default SingleDishView;
