import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { useHistory, useLocation, Switch, Route, useParams } from "react-router-dom";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faClock,
  faPrint,
  faStore,
  faSimCard,
} from "@fortawesome/free-solid-svg-icons";
import Store from "./store";
import Times from "./times";
import Security from "./security";
import Printer from "./printer";
import Payment from "./payment";

function Settings() {
  const {storeId} = useParams()
  const history = useHistory();
  const location = useLocation();
  const goTo = (path) => {
    history.push(path);
  };
  return (
    <div>
      <Nav className="nav-settings">
        <NavItem className="">
          <NavLink
            className={`link-setting ${
              location.pathname === `/store/${storeId}/settings` && "active"
            }`}
            onClick={goTo.bind(this, `/store/${storeId}/settings`)}
          >
            <FontAwesomeIcon icon={faStore} className="icon" /> Store
          </NavLink>
        </NavItem>
        <NavItem className="">
          <NavLink
            className={`link-setting ${
              location.pathname.match(`/store/${storeId}/settings/payment`) &&
              "active"
            }`}
            onClick={goTo.bind(this, `/store/${storeId}/settings/payment`)}
          >
            <FontAwesomeIcon icon={faSimCard} className="icon" /> Paiement
          </NavLink>
        </NavItem>
        <NavItem className="tab">
          <NavLink
            className={`link-setting ${
              location.pathname.match(`/store/${storeId}/settings/times`) &&
              "active"
            }`}
            onClick={goTo.bind(this, `/store/${storeId}/settings/times`)}
          >
            <FontAwesomeIcon icon={faClock} className="icon" /> Temps
          </NavLink>
        </NavItem>
        <NavItem className="tab">
          <NavLink
            className={`link-setting ${
              location.pathname.match(`/store/${storeId}/settings/security`) &&
              "active"
            }`}
            onClick={goTo.bind(this, `/store/${storeId}/settings/security`)}
          >
            <FontAwesomeIcon icon={faLock} className="icon" /> Sécurité
          </NavLink>
        </NavItem>
        <NavItem className="tab">
          <NavLink
            className={`link-setting ${
              location.pathname.match(`/store/${storeId}/settings/printer`) &&
              "active"
            }`}
            onClick={goTo.bind(this, `/store/${storeId}/settings/printer`)}
          >
            <FontAwesomeIcon icon={faPrint} className="icon" /> Imprimante
          </NavLink>
        </NavItem>
      </Nav>
      <Switch>
        <Route exact path="/store/:storeId/settings" component={Store} />
        <Route path="/store/:storeId/settings/payment" component={Payment} />
        <Route path="/store/:storeId/settings/times" component={Times} />
        <Route
          path="/store/:storeId/settings/security"
          component={Security}
        />
        <Route path="/store/:storeId/settings/printer" component={Printer} />
      </Switch>
    </div>
  );
}

export default Settings;
