import React from 'react'
import {ReactComponent as Launching} from "../point-of-sale/launching.svg"

function NoFeature() {
    return (
        <div className="container-no-feature">
            <Launching className="launching-image"/>
            <p>Fonctionalité à venir</p>
        </div>
    )
}

export default NoFeature
