import React from 'react'

function ImageItemGallery({image, setAsImageUrl}) {
    return (
        <div className="card-image-from-gallery" onClick={setAsImageUrl.bind(this,image.imageUrl)}>
            <img src={image.imageUrl} className="img-fluid"/>
        </div>
    )
}

export default ImageItemGallery
