import React, { useState } from "react";
import {
  Row,
  ListGroupItem,
  ListGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  CustomInput
} from "reactstrap";
import { useForm } from "react-hook-form";
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
  isEmpty,
} from "react-redux-firebase";
import { useSelector } from "react-redux";


const NewUnitForm = ({ isOpen, toggle }) => {
  const db = useFirestore();
  const { register, handleSubmit } = useForm();
  const [isSave, setisSave] = useState(false);

  const saveToDB = (data) => {
    setisSave(true);
    db.add("units", { ...data, createdAt: db.Timestamp.now().toMillis() })
      .then((res) => {
        setisSave(false);
        toggle();
      })
      .catch((err) => {
        setisSave(false);
      });
  };

  return (
    <Modal isOpen={isOpen} centered className="modal-new-unit">
      <form onSubmit={handleSubmit(saveToDB)}>
        <ModalHeader>Nouvelle unité de mesure</ModalHeader>
        <ModalBody className="modal-new-unit-body">
          <Row form>
            <div className="col-8">
              <div className="div-input">
                <label>Unité</label>
                <input
                  type="text"
                  name="unit"
                  ref={register({ required: true })}
                  disabled={isSave}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="div-input">
                <label>Catégorie</label>
                <select
                  disabled={isSave}
                  name="category"
                  ref={register({ required: true })}
                >
                  <option></option>
                  <option value="Unité">Unité</option>
                  <option value="Volume">Volume</option>
                  <option value="Poids">Poids</option>
                  <option value="Longueur/distance">Longueur/distance</option>
                  <option value="Temps">Temps</option>
                </select>
              </div>
            </div>
          </Row>
        </ModalBody>
        <ModalFooter className="modal-new-unit-footer">
          <button type="submit" className="btn-save" disabled={isSave}>
            {isSave ? <Spinner className="spinner" /> : "Sauvegarder"}
          </button>
          <button onClick={toggle} disabled={isSave}>
            Annuler
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

function Units() {
  useFirestoreConnect(() => [
    { collection: "units", orderBy: ["unit", "asc"] },
  ]);
  const [isOpenNewUnitForm, setisOpenNewUnitForm] = useState(false);
  const units = useSelector((state) => state.firestore.ordered.units);
  const toggleNewUnitForm = () => {
    setisOpenNewUnitForm((prev) => !prev);
  };
  return (
    <div className="units-container">
      <div className="units-container-header">
        <h4>Unités de mesure</h4>
        <button onClick={toggleNewUnitForm}>Créer</button>
      </div>
      <ListGroup className="list-units">
        <ListGroupItem className="unit-item header">
            <CustomInput type="checkbox" id="header-check-city" className="mr-3"/>
          <div className="unit">Unité de mesure</div>
          <div className="category">Catégorie</div>
        </ListGroupItem>
        {!isLoaded(units) ? (
          <div className="loading-data-indicator">
            <p>En cours de chargement ...</p>
          </div>
        ) : isEmpty(units) ? (
          <div className="empty-list-data">
            <p>Aucune unité trouvée!</p>
          </div>
        ) : (
          units.map((unit) => (
            <ListGroupItem key={unit.id} className="unit-item py-1">
                <CustomInput type="checkbox" name="userCondition" className="mr-3"
                  id={`check${unit.id}`}/>
              <div className="unit">{unit.unit}</div>
              <div className="category">{unit.category}</div>
            </ListGroupItem>
          ))
        )}
      </ListGroup>

      <NewUnitForm isOpen={isOpenNewUnitForm} toggle={toggleNewUnitForm} />
    </div>
  );
}

export default Units;
