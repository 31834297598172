import React from 'react'
import NoFeature from './noFeature'

function Printer() {
    return (
        <div>
            <NoFeature/>
        </div>
    )
}

export default Printer
