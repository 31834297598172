import React, { useState } from "react";
import "./sideBar.scss";
import { ListGroup, ListGroupItem, Toast, ToastHeader, ToastBody } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Detector, online } from "react-detect-offline";
import {
  faHome,
  faQuestionCircle,
  faCog,
  faListAlt,
  faChartLine,
  faBicycle,
  faImages,
  faHeadphonesAlt,
  faUserAlt,
  faWifi,
  faStoreAlt, faBuilding
} from "@fortawesome/free-solid-svg-icons";
import CopyRight from "../copyRight/copyRight";

const SideBar = props => {
  const [activeMenuIndex, setActiveMenuIndex] = useState(1);
  const [currentPath, setcurrentPath] = useState(props.location.pathname);

  let sidebarClasses = "sidebar";
  
  if (props.show) {
    sidebarClasses = "sidebar open";
  }

  const handleActiveMenu = (index, path) => {
    setActiveMenuIndex(index);
    setcurrentPath(path);
    props.history.push(path);
    props.setViewSidebar(false);
  };

  return (
    <div className={`${sidebarClasses} overflow-auto`}>
      <nav>
        <div className="resto-banner pl-2 pt-1">
          <img
            src="/assets/images/logo.png"
            className="img-logo"
            alt=""
            width="50%"
          />{" "}
        </div>
        <Detector render ={({online}) =>(
          !online && <ListGroupItem className="no-internet-connexion bg-warning pl-3">
          <div className="header font-weight-normal">
            <h6 className="font-weight-normal my-0">L'orinateur n'est pas connecté</h6>
          </div>
          <div className="body">
            <FontAwesomeIcon icon={faWifi} className="text-muted" size="1x"/>
            <div className="message ml-2">
            <p className="my-0">
              Assurez-vous que votre orinateur ait une connexion internet active.
            </p>
            </div>
          </div>
        </ListGroupItem>
        )}
        />
        
        <ListGroup flush>
          <ListGroupItem
            action
            className={`side-menu-item ${
              currentPath === "/d" && activeMenuIndex === 1
                ? "active-menu"
                : undefined
            }`}
            onClick={handleActiveMenu.bind(this, 1, "/d")}
          >
            <FontAwesomeIcon icon={faHome} className="icon-menu mr-3" />
            Accueil
          </ListGroupItem>

          <ListGroupItem
            action
            className={`side-menu-item ${
              currentPath === "/d/stores"
                ? "active-menu"
                : activeMenuIndex === 2
                ? "active-menu"
                : undefined
            }`}
            onClick={handleActiveMenu.bind(this, 2, "/d/stores")}
          >
            <FontAwesomeIcon icon={faStoreAlt} className="icon-menu mr-3" />
            Stores
          </ListGroupItem>

          <ListGroupItem
            action
            className={`side-menu-item ${
              currentPath.includes("/d/galery")
                ? "active-menu"
                : activeMenuIndex === 3
                ? "active-menu"
                : undefined
            }`}
            onClick={handleActiveMenu.bind(this, 3, "/d/galery")}
          >
            <FontAwesomeIcon icon={faImages} className="icon-menu mr-3" />
            Gallerie
          </ListGroupItem>

          <ListGroupItem
            action
            className={`side-menu-item ${
              currentPath.includes("/d/livreurs")
                ? "active-menu"
                : activeMenuIndex === 5
                ? "active-menu"
                : undefined
            }`}
            onClick={handleActiveMenu.bind(this, 5, "/d/livreurs")}
          >
            <FontAwesomeIcon icon={faBicycle} className="icon-menu mr-3" />
            Livreurs
          </ListGroupItem>
          <ListGroupItem
            action
            className={`side-menu-item ${
              currentPath.includes("/d/vendeurs")
                ? "active-menu"
                : activeMenuIndex === 8
                ? "active-menu"
                : undefined
            }`}
            onClick={handleActiveMenu.bind(this, 8, "/d/vendeurs")}
          >
            <FontAwesomeIcon icon={faBuilding} className="icon-menu mr-3" />
            Vendeurs
          </ListGroupItem>

          <ListGroupItem
            action
            className={`side-menu-item ${
              currentPath.includes("/d/settings")
                ? "active-menu"
                : activeMenuIndex === 4
                ? "active-menu"
                : undefined
            }`}
            onClick={handleActiveMenu.bind(this, 4, "/d/settings")}
          >
            <FontAwesomeIcon icon={faCog} className="icon-menu mr-3" />
            Paramètres
          </ListGroupItem>

          <ListGroupItem
            action
            className={`side-menu-item ${
              currentPath === "/d/gallery"
                ? "active-menu"
                : activeMenuIndex === 7
                ? "active-menu"
                : undefined
            }`}
            onClick={handleActiveMenu.bind(this, 7, "/d/gallery")}
          >
            <FontAwesomeIcon icon={faImages} className="icon-menu mr-3" />
            Gallerie fresh
          </ListGroupItem>

          <ListGroupItem
            action
            className={`side-menu-item ${
              currentPath === "/d/users"
                ? "active-menu"
                : activeMenuIndex === 6
                ? "active-menu"
                : undefined
            }`}
            onClick={handleActiveMenu.bind(this, 6, "/d/users")}
          >
            <FontAwesomeIcon icon={faUserAlt} className="icon-menu mr-3" />
            Utilisateurs
          </ListGroupItem>
        </ListGroup>
      </nav>
      <div className="sidebar-blank-space" />
      <CopyRight color="#c3c3c3" />
    </div>
  );
};

export default withRouter(SideBar);
