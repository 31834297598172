import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Badge,
  ListGroup,
  ListGroupItem,
  Button,
  Input,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faSearch,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import NewLivreurForm from "./newLivreurForm";
import ListLivreurs from "./listLivreurs";
import CityItem from "./cityItem";
import "./livreurs.scss";
import EditLivreurForm from "./editLivreurForm";
import ToastMessage from "../toast/toast";

const Livreurs = () => {
  const [isOpenLivreurForm, setisOpenLivreurForm] = useState(false);
  const [currentRiderToEdit, setcurrentRiderToEdit] = useState();
  const [showSuccess, setshowSuccess] = useState(false);

  useFirestoreConnect(() => [
    { collection: "cities" },
    { collection: "riders" },
  ]);
  const cities = useSelector((state) => state.firestore.ordered.cities);
  const riders = useSelector((state) => state.firestore.ordered.riders);
  const [currentCityLivreurs, setcurrentCityLivreurs] = useState();
  const [currentCityId, setcurrentCityId] = useState("0");

  const handleShowSuccess = () => {
    setshowSuccess(!showSuccess);
  };

  const toggleNewLivreurForm = () => {
    setisOpenLivreurForm(!isOpenLivreurForm);
  };

  const viewCurrentCityLivreurs = (cityName, cityId) => {
    const currentCityLivreurs = riders.filter(
      (rider) => cityName === rider.city
    );
    setcurrentCityLivreurs(currentCityLivreurs);
    setcurrentCityId(cityId);
  };

  const viewAllLivreurs = () => {
    setcurrentCityLivreurs(riders);
    setcurrentCityId("0");
  };

  const onSearch = (e) => {
    if (e.target.value !== "") {
      const searchSting = RegExp(e.target.value, "i");
      const newListLivreurs = riders.filter(
        (rider) =>
          rider.name.match(searchSting) || rider.phone.match(searchSting)
      );
      setcurrentCityLivreurs(newListLivreurs);
      setcurrentCityId("0");
    } else {
      setcurrentCityLivreurs(riders);
    }
  };

  useEffect(() => {
    setcurrentCityLivreurs(riders);
  }, [riders]);
  return (
    <Container className="container-livreurs" fluid>
      <div className="container-livreurs-header">
        <h4 className="font-weight-normal">
          {riders && <Badge>{riders.length}</Badge>} Livreurs
        </h4>
        <p className="my-0" onClick={toggleNewLivreurForm}>
          <FontAwesomeIcon icon={faPlusCircle} /> Ajouter
        </p>
      </div>
      <Row>
        <Col className="col-md-3">
          <ListGroup flush className="list-cities-livreurs">
            <ListGroupItem className="list-city-item-header">
              Par ville d'affectation
            </ListGroupItem>
            {cities &&
              cities.map((city) => (
                <CityItem
                  key={city.id}
                  city={city}
                  viewCurrentCityLivreurs={viewCurrentCityLivreurs}
                  currentCityId={currentCityId}
                />
              ))}
          </ListGroup>
        </Col>
        <Col className="col-md-9">
          <ListGroup flush className="search-bar">
            { !currentRiderToEdit && <ListGroupItem className="search-bar-content py-2">
              <Button
                className="font-weight-bold px-3"
                size="sm"
                onClick={viewAllLivreurs}
              >
                Tous
              </Button>
              <Input
                type="text"
                placeholder="Rechercher ..."
                bsSize="sm"
                onChange={onSearch}
              />
              <Button size="sm">
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </ListGroupItem>}
          </ListGroup>
          {currentRiderToEdit ? (
            <EditLivreurForm currentRiderToEdit={currentRiderToEdit} setcurrentRiderToEdit={setcurrentRiderToEdit} cities={cities} setshowSuccess={setshowSuccess} />
          ) : (
            <ListLivreurs
              riders={currentCityLivreurs}
              setRiders={setcurrentCityLivreurs}
              setcurrentRiderToEdit={setcurrentRiderToEdit}
            />
          )}
        </Col>
      </Row>
      <NewLivreurForm
        isOpen={isOpenLivreurForm}
        toggle={toggleNewLivreurForm}
        cities={cities}
      />
      <ToastMessage
        isShow={showSuccess}
        toggle={handleShowSuccess}
        message="Enregistré"
        delay={4000}
        icon="check"
        className="bg-success text-white pr-5 mr-3"
      />
    </Container>
  );
};

export default Livreurs;
