import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Spinner, CustomInput, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faTimes, faSave } from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  useFirestore,
  useFirebase,
  useFirestoreConnect,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import axios from "axios";
import {toast} from "react-toastify"

function NewBikerForm() {
  const {storeId}=useParams()
  useFirestoreConnect(() => [
    {
      collection: "cities",
      orderBy: ["city", "asc"],
    },
  ]);
  const history = useHistory();
  const firebase = useFirebase();
  const db = useFirestore();
  const { register, handleSubmit } = useForm();
  const [isUpload, setisUpload] = useState(false);
  const [isSave, setisSave] = useState(false);
  const [avatar, setavatar] = useState();
  const [isRequiredImage, setisRequiredImage] = useState(false);
  const [uploadFileProgress, setuploadFileProgress] = useState(0);
  const cities = useSelector((state) => state.firestore.ordered.cities);
  const [activeTab, setactiveTab] = useState("1");

  const goTo = (path) => {
    history.push(path);
  };

  const toggleActiveTab = (tab) => {
    if (activeTab !== tab) setactiveTab(tab);
  };

  const saveToDB = (data) => {
    if (avatar) {
      setisSave(true);

      axios
        .post(
          "https://cors-anywhere.herokuapp.com/https://us-central1-tayarifood.cloudfunctions.net/deliveryIdentity",
          {
            ...data,
            avatar: avatar ? avatar : "",
            status: "busy",
            role: "rider"
          }
        )
        .then((res) => {
          toast.success("Sauvegardé")
          history.push(`/store/${storeId}/bikers`);
        })
        .catch((err) => {
          setisSave(false);
        });
      // db.add(`users`, {
      //   ...data,
      //   avatar: avatar ? avatar : "",
      //   status: "unknown",
      //   role:"biker",
      //   createdAt: db.Timestamp.now().toMillis(),
      // })
      //   .then((res) => {
      //     history.push("/store/bikers");
      //   })
      //   .catch((err) => {});
    } else {
      setisRequiredImage(true);
    }
  };

  const onDropFiles = (files) => {
    setisUpload(true);
    setisRequiredImage(false);
    let uploadTask = firebase
      .storage()
      .ref()
      .child("bikers/" + Date.now())
      .put(files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        setuploadFileProgress(progress);
      },
      (error) => {
        setisUpload(false);
        console.log("Erreur d'upLoad");
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log(downloadURL);
          setavatar(downloadURL);
          setisUpload(false);
        });
      }
    );
  };

  return (
    <div className="container-new-product">
      <form onSubmit={handleSubmit(saveToDB)}>
        <div className="new-product-header">
          <div className="div-titles">
            <h4>
              <Link
                to={`${!isSave ? `/store/${storeId}/bikers` : `/store/${storeId}/bikers/new`}`}
                className="link"
              >
                Livreurs
              </Link>{" "}
              / Nouveau
            </h4>
            {/* <div className="div-availability">
              <label>Autorisé :</label>
              <CustomInput
                type="switch"
                id="status"
                name="allowed"
                disabled={isSave}
                label=""
                innerRef={register}
              />
            </div> */}
          </div>
          <div className="div-actions">
            <button type="submit" className="btn-save" disabled={isSave}>
              {isSave ? (
                <Spinner className="spinner" />
              ) : (
                <span>
                  <FontAwesomeIcon icon={faSave} /> Sauvegarder
                </span>
              )}
            </button>
            <button
              onClick={goTo.bind(this, `/store/${storeId}/bikers`)}
              disabled={isSave}
            >
              <FontAwesomeIcon icon={faTimes} /> Fermer
            </button>
            <div className="stock-input allowed">
            <label>Autorisé :</label>
              <CustomInput
                type="switch"
                id="status"
                name="allowed"
                disabled={isSave}
                label=""
                innerRef={register}
              />
              {/* <label>Stock</label>
              <input
                type="number"
                defaultValue="0"
                name="stock"
                ref={register({ required: true })}
                disabled={isSave}
              /> */}
            </div>
          </div>
        </div>
        <div className="new-product-body">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={`link-tab ${activeTab === "1" && "active"} `}
                onClick={() => {
                  toggleActiveTab("1");
                }}
              >
                Identité du livreur
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                className={`link-tab ${activeTab === "2" && "active"} `}
                onClick={() => {
                  toggleActiveTab("2");
                }}
              >
                Activités
              </NavLink>
            </NavItem> */}
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
            <div className="row mt-3">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-8">
                    <div className="div-input">
                      <label>
                        <span>*</span>Noms du livreur
                      </label>
                      <input
                        type="text"
                        name="name"
                        disabled={isSave}
                        ref={register({ required: true })}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="div-input">
                      <label>Sexe</label>
                      <select
                        name="sex"
                        disabled={isSave}
                        ref={register({ required: true })}
                      >
                        <option value="M">Homme</option>
                        <option value="F">Femme</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="div-input">
                      <label>Etat civil</label>
                      <select
                        name="stateCivile"
                        ref={register}
                        disabled={isSave}
                      >
                        <option></option>
                        <option value="Marié (e)">Marié (e)</option>
                        <option value="Célibataire">Célibataire</option>
                        <option value="Veuf">Veuf (ve)</option>
                        <option value="Divorcé (e)">Divorcé (e)</option>
                      </select>
                    </div>
                  </div>
                  <div className="col">
                    <div className="div-input">
                      <label>Ville ou cité d'affectation</label>
                      <select name="city" disabled={isSave} ref={register}>
                        <option value={storeId}>
                          {storeId}
                        </option>
                        {/* {cities &&
                          cities.map((city) => (
                            <option key={city.id} value={city.city}>
                              {city.city}
                            </option>
                          ))} */}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="div-input">
                      <label>
                        <span>*</span>Téléphone
                      </label>
                      <input
                        name="phone"
                        ref={register({
                          required: true,
                          pattern: /\+243+([0-9]{9})/,
                        })}
                        disabled={isSave}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="div-input">
                      <label>
                        <span>*</span>Mot de passe
                      </label>
                      <input
                        type="password"
                        name="password"
                        ref={register({ required: true })}
                        disabled={isSave}
                        defaultValue="123456"
                      />
                    </div>
                  </div>
                </div>
                <div className="div-input">
                  <label>Adresse locale</label>
                  <textarea
                    name="address"
                    rows="2"
                    ref={register}
                    disabled={isSave}
                  />
                </div>
              </div>
              <div className="col-md-4 col-image-product">
                <label>
                  <span>*</span>Avatar
                </label>

                <Dropzone
                  onDrop={onDropFiles}
                  accept="image/jpeg"
                  multiple={false}
                >
                  {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragReject,
                  }) => (
                    <div
                      {...getRootProps()}
                      className={`dropzone-image ${
                        isRequiredImage && "required"
                      }`}
                      style={{
                        backgroundImage: `url(${avatar && avatar})`,
                        backgroundRepeat: "none",
                        backgroundSize: "cover",
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      <input
                        {...getInputProps()}
                        disabled={isSave || isUpload ? true : false}
                      />
                      {!isUpload ? (
                        <div className="btn-upload">
                          <p>
                            <FontAwesomeIcon icon={faCamera} />
                          </p>
                          <h6>Upload</h6>
                        </div>
                      ) : (
                        <div className="spinner-progress">
                          <Spinner />
                          <p>{uploadFileProgress.toFixed(0)}%</p>
                        </div>
                      )}
                    </div>
                  )}
                </Dropzone>
              </div>
            </div>
            </TabPane>
            {/* <TabPane tabId="2">

            </TabPane> */}
            </TabContent>
        </div>
      </form>
    </div>
  );
}

export default NewBikerForm;
