import React from 'react'
import "./products.scss"
import { Switch, Route } from 'react-router-dom'
import ListProducts from './listProducts'
import NewProductForm from './newProductForm'
import EditProductForm from './editProductForm'

function Products() {
    return (
        <div className="">
            <Switch>
                <Route exact path="/store/:storeId/products" component={ListProducts}/>
                <Route path="/store/:storeId/products/new" component={NewProductForm}/>
                <Route path="/store/:storeId/products/edit/:productId" component={EditProductForm}/>
            </Switch>
        </div>
    )
}

export default Products
