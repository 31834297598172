import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import Dropzone from "react-dropzone";
import { useFirebase, useFirestore } from "react-redux-firebase";
function NewSellerForm({ isOpen, toggle }) {
  const db = useFirestore();
  const firebase = useFirebase();
  const { register, handleSubmit } = useForm();
  const [logoUrl, setlogoUrl] = useState();
  const [isSave, setisSave] = useState(false);
  const [isUpload, setisUpload] = useState(false);
  const [uploadFileProgress, setuploadFileProgress] = useState(0);

  const saveToDB = (data) => {
      setisSave(true)
      db.add("sellers",{...data, logoUrl}).then(res=>{
        setisSave(false)
        setlogoUrl()
        toggle()
      }).catch(err=>{
          setisSave(false)
      })
  };

  const onDropFiles = (files) => {
    setisUpload(true);
    let uploadTask = firebase
      .storage()
      .ref()
      .child("sellersLogos/" + Date.now())
      .put(files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        setuploadFileProgress(progress);
      },
      (error) => {
        setisUpload(false);
        console.log("Erreur d'upLoad");
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log(downloadURL);
          setlogoUrl(downloadURL);
          setisUpload(false);
        });
      }
    );
  };

  return (
    <Modal isOpen={isOpen} className="modal-new-seller">
      <form onSubmit={handleSubmit(saveToDB)}>
        <ModalHeader toggle={!isSave&&toggle}>Nouveau vendeur</ModalHeader>
        <ModalBody>
          <div className="div-image-category">
            <label>Logo de l'entreprise du vendeur</label>

            <Dropzone
              onDrop={onDropFiles}
              accept="image/jpeg,image/png"
              multiple={false}
            >
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragReject,
              }) => (
                <div
                  {...getRootProps()}
                  className={`dropzone-image`}
                  style={{
                    backgroundImage: `url(${logoUrl})`,
                    backgroundRepeat: "none",
                    backgroundSize: "cover",
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  <input
                    {...getInputProps()}
                    disabled={isSave || isUpload ? true : false}
                  />
                  {!isUpload ? (
                    <div className="btn-upload">
                      <p>
                        <FontAwesomeIcon icon={faCamera} />
                      </p>
                      <h6>Upload</h6>
                    </div>
                  ) : (
                    <div className="spinner-progress">
                      <Spinner />
                      <p>{uploadFileProgress.toFixed(0)}%</p>
                    </div>
                  )}
                </div>
              )}
            </Dropzone>
          </div>
          <div className="div-input">
            <label><span>*</span>Nom de l'entreprise</label>
            <input type="text" name="name" ref={register({ required: true })} disabled={isSave}/>
          </div>
          <div className="row">
            <div className="col">
              <div className="div-input">
                <label>Téléphone</label>
                <input  name="phone" ref={register} disabled={isSave} />
              </div>
            </div>
            <div className="col">
              <div className="div-input">
                <label>Email</label>
                <input type="email" name="email" ref={register} disabled={isSave}/>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button type="submit" className="btn-save" disabled={isSave}>Sauvegarder</button>
          <button type="button" disabled={isSave} onClick={toggle}>
            Annuler
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

export default NewSellerForm;
