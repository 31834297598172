import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Label,
  Row,
  Col,
  Spinner
} from "reactstrap";
import { useFirebase, useFirestore, useFirestoreConnect } from "react-redux-firebase";
import {useSelector} from "react-redux"
import ToastMessage from '../toast/toast'
import "./users.scss";

const NewUserAdmin = ({ isOpen, toggle }) => {
  useFirestoreConnect(()=>[
    {
      collection:"cities"
    }
  ])
  const { register, handleSubmit } = useForm();
  const [isSave, setisSave] = useState();
  const [showSuccess, setshowSuccess] = useState(false)
  const [showDanger, setshowDanger] =useState(false)
  const db = useFirestore();
  const firebase = useFirebase();
  const cities =useSelector(state=>state.firestore.ordered.cities)

  const handleShowSuccess = () => {
    setshowSuccess(!showSuccess)
  }
  const handleShowDanger = () => {
    setshowDanger(!showDanger)
  }

  const onSubmit = data => {
    const adminData = {
      email: data.email,
      role: data.role,
      city:data.city,
      name: data.name
    };
    if (data.password === data.confimPassword) {
      setisSave(true);
      firebase
        .auth()
        .createUserWithEmailAndPassword(data.email, data.confimPassword)
        .then(res => {
          db.collection("users").doc(res.user.uid).set({ ...adminData, uid: res.user.uid }).then(res => {
            setisSave(false);
            handleShowSuccess()
            toggle();
          });
        })
        .catch(err => {
          setisSave(false);
          handleShowDanger()
        });
    } else {
      console.log("Verifier votre mot de passe");
    }
  };
  return (
    <React.Fragment>
    <Modal isOpen={isOpen}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={toggle}>Nouveau administrateur</ModalHeader>
        <ModalBody>
          <Row form>
            <Col>
          <FormGroup>
            <Label className="font-weight-bold my-0" size="sm">
              Noms
            </Label>
            <Input
              type="text"
              bsSize="sm"
              name="name"
              disabled={isSave ? true : false}
              innerRef={register({ required: true, minLength: 2 })}
            />
          </FormGroup>
          </Col>
          <Col>
          <FormGroup>
            <Label className="font-weight-bold my-0" size="sm">
              Email
            </Label>
            <Input
              type="email"
              bsSize="sm"
              name="email"
              disabled={isSave ? true : false}
              innerRef={register({
                required: true,
                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              })}
            />
          </FormGroup>
          </Col>
          </Row>
          <Row form>
            <Col>
            <FormGroup>
            <Label className="font-weight-bold my-0" size="sm">
            Ville d'affectation
            </Label>
            
            <Input
              type="select"
              bsSize="sm"
              name="city"
              disabled={isSave ? true : false}
              innerRef={register({ required: true})}
            >
              <option></option>
              {
                cities&&cities.map(city=>(
                <option key={city.id} value={city.city}>{city.city}</option>
                ))
              }
            </Input>
          </FormGroup>
            </Col>
          <FormGroup>
            <Label className="font-weight-bold my-0" size="sm">
            Rôle
            </Label>
            
            <Input
              type="select"
              bsSize="sm"
              name="role"
              disabled={isSave ? true : false}
              innerRef={register({ required: true})}
            >
              <option></option>
              <option value="admin">Admin</option>
              <option value="agent">Agent</option>
            </Input>
          </FormGroup>
          </Row>
          <Row form>
            <Col>
              <FormGroup>
                <Label className="font-weight-bold my-0" size="sm">
                  Mot de passe
                </Label>
                <Input
                  type="password"
                  bsSize="sm"
                  name="password"
                  disabled={isSave ? true : false}
                  innerRef={register({
                    required: true,
                    minLength: 6,
                    maxLength: 14
                  })}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label className="font-weight-bold my-0" size="sm">
                  Confirmer le mot de passe
                </Label>
                <Input
                  type="password"
                  bsSize="sm"
                  name="confimPassword"
                  disabled={isSave ? true : false}
                  innerRef={register({
                    required: true,
                    minLength: 6,
                    maxLength: 14
                  })}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="footer-form-new-admin">
          <Button
            className="font-weight-bold px-3"
            onClick={toggle}
            size="sm"
            disabled={isSave ? true : false}
          >
            Annuler
          </Button>
          <Button
            className="font-weight-bold px-3"
            type="submit"
            size="sm"
            disabled={isSave ? true : false}
          >
            {!isSave ? (
              "Enregistrer"
            ) : (
              <Spinner color="light" className="spinner-isSaving" />
            )}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
    <ToastMessage
        isShow={showSuccess}
        toggle={handleShowSuccess}
        message="Enregistré"
        delay={4000}
        icon="check"
        className="bg-success text-white pr-5 mr-3"
      />
      <ToastMessage
        isShow={showDanger}
        toggle={handleShowDanger}
        message="Ouf, une erreur est survenue!"
        delay={5000}
        icon="check"
        className="bg-danger text-white pr-5"
      />
    </React.Fragment>
  );
};

export default NewUserAdmin;
