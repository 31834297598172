import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLayerGroup,
  faList,
  faTh,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory, useParams } from "react-router-dom";
import { useFirestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoadingIndicator from "../loadingIndicator/loadingIndicator";
import EmptyDataList from "../loadingIndicator/emptyDataList";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";



function Menu() {
    const {storeId} =useParams()
    useFirestoreConnect(() => [
        {
          collection: "stores",
          doc: storeId,
          subcollections: [
            {
              collection: "products",
              where: ["isFresh", "==", false],
              orderBy: ["name", "asc"],
            },
          ],
          storeAs: "menu",
        },
        {
          collection: "units",
          orderBy: ["unit", "asc"],
        },
        {
          collection: "categories",
          orderBy: ["category", "asc"],
        },
      ]);

      const history = useHistory();
      const [searchString, setsearchString] = useState("")
  const menu = useSelector((state) => state.firestore.ordered.menu);
  const categories = useSelector(
    (state) => state.firestore.ordered.categories
  );
  const [currentMenu, setcurrentMenu] = useState([]);
  const goTo = (path) => {
    history.push(path);
  };

  const notifyAsComingFeature = () =>
    toast.success("Oops! Fonctionalité à venir!");

    const onSearch = (e) => {
        const value = e.target.value.replace("+","");
        setsearchString(value)
        if (value != "") {
          const regexToSearch = RegExp(value, "i");
          const searchResult = menu.filter(
            (dish) =>
              dish.name.match(regexToSearch) ||
              dish.category.match(regexToSearch)
          );
          setcurrentMenu(searchResult);
        }else{
          setcurrentMenu(menu)
        }
      };


      const searchByCategory = (category) => {
        setsearchString(category)
        const searchResult = menu.filter((dish) =>
          dish.category.match(category)
        );
        setcurrentMenu(searchResult);
      };

      const viewAllDishes =()=>{
        menu&&setcurrentMenu(menu)
        setsearchString("Tous")
      }
    
      const viewUnCategorizedDishes=()=>{
        const searchResult = menu.filter((dish) =>
          dish.category===""
        );
        setcurrentMenu(searchResult);
        setsearchString("Non categorisé")
      }

      useEffect(() => {
        menu && setcurrentMenu(menu);
        return () => {};
      }, [menu]);
    

    return (
        <div className="container-list-products">
      <div className="container-list-products-header">
        <div className="div-left">
          <h3>Plats {menu && `(${menu.length})`}</h3>
          <div className="div-btns-actions">
            <button
              className="btn-create"
              onClick={goTo.bind(this, `/store/${storeId}/menu/new`)}
            >
              Créer
            </button>
            <button onClick={notifyAsComingFeature}>Importer</button>
            <button onClick={notifyAsComingFeature}>Exporter</button>
          </div>
        </div>
        <div className="div-right">
          <div className="div-btns">
            <div className="filters-btn">
              <UncontrolledDropdown>
                <DropdownToggle className="btn-dropdown-toggle" caret>
                  <FontAwesomeIcon icon={faLayerGroup} className="left-icon" />{" "}
                  Categorie
                </DropdownToggle>
                <DropdownMenu className="dropmenu-list">
                  
                  <DropdownItem className="category-item fixe" onClick={viewAllDishes}>Tous</DropdownItem>
                  <DropdownItem className="category-item fixe" onClick={viewUnCategorizedDishes}>Non categorisé</DropdownItem>
                  {categories &&
                    categories.map((category) => (
                      <DropdownItem
                        className="category-item"
                        onClick={searchByCategory.bind(this, category.category)}
                      >
                        {/* <FontAwesomeIcon icon={faObjectGroup}/> */}
                        <img src={category.imageUrl}/>{category.category}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <div>
              <button onClick={notifyAsComingFeature}>
                <FontAwesomeIcon icon={faList} />
              </button>
              <button onClick={notifyAsComingFeature}>
                <FontAwesomeIcon icon={faTh} />
              </button>
            </div>
          </div>
          <div className="search-bar">
            <form>
              <FontAwesomeIcon icon={faSearch} className="search-icon" />
              <input
                type="search"
                name="productToSearch"
                placeholder="Rechercher ici ..."
                onChange={onSearch}
                value={searchString}
              />
            </form>
          </div>
        </div>
      </div>
      <div className="container-list-products-body">
        <div className="container-fluid">
          <div className="row">
            {!isLoaded(menu) ? (
              <LoadingIndicator />
            ) : isEmpty(menu) ? (
              <EmptyDataList message="Aucun plat trouvé!" />
            ) : (
              currentMenu.length!=0?currentMenu.map((dish) => (
                <div
                  key={dish.id}
                  className="col-card-product col-sm-6 col-md-4 col-lg-3"
                  onClick={goTo.bind(
                    this,
                    `/store/${storeId}/menu/edit/${dish.id}`
                  )}
                >
                  <div className="card-product">
                    <div
                      className={`product-available ${
                        dish.available && "available"
                      }`}
                    />
                    <img src={dish.imageUrl} />
                    <div className="product-info">
                      <h6>{dish.name}</h6>
                      <p>Prix: {dish.price} CDF</p>
                      <p>
                        Stock: {dish.stock} {dish.unit}
                      </p>
                    </div>
                  </div>
                </div>
              )):<EmptyDataList message="Aucun resultat de recherche!"/>
            )}
          </div>
        </div>
      </div>
    </div>
    )
}

export default Menu
