import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faSearch,
  faUpload
} from "@fortawesome/free-solid-svg-icons";
import Dropzone from "react-dropzone";
// import ImageItem from "./imageItem";
import "./galery.scss";
import CardImageImport from "./CardImageImport";

const NewGalleryMoreImages = () => {
  const [isSave, setisSave] = useState();
  const [isUpload, setisUpload] = useState();
  const [images, setimages] = useState([]);

  const onDropImages = files => {
    setimages(files);
  };

  return (
    <Container fluid className="container-import-images pb-2">
      <div className="container-import-images-header">
        <h4 className="font-weight-normal">Importation d'images de plats</h4>
      </div>
      <Row>
        <Col md="4">
          <Dropzone
            onDrop={onDropImages}
            accept="image/png,image/jpeg,image/gif"
            multiple={true}
          >
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
              <div {...getRootProps()} className="dropzone-import">
                <input
                  {...getInputProps()}
                  disabled={isSave || isUpload ? true : false}
                />
                <div className="parcourir-btn-file-upload py-0 my-1">
                  <FontAwesomeIcon
                    icon={faUpload}
                    className="text-muted "
                    size="10x"
                  />
                  <p className="my-0 text-center display-4">Upload</p>
                </div>
              </div>
            )}
          </Dropzone>
        </Col>
        <Col>
          <Row>
            {images.map((image, index) => (
            //   <ImageItem  />
              <CardImageImport image={image} key={index}/>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
export default NewGalleryMoreImages;
