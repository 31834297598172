import React from "react";
import { withRouter } from "react-router-dom";
import {useFirebase} from "react-redux-firebase"
import "./navBar.scss";
import { Button } from "reactstrap";
import BurgerButton from "./burgerButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

const Header = props => {
  const firebase = useFirebase()
  const onSignOut = () => {
    firebase.logout().then(() => {
      localStorage.removeItem("isAdmin")
      props.history.push("/");
    });
  };
  return (
    <header className="bg-white">
      <BurgerButton toggleSidebar={props.toggleSidebar} />
      <h5 className="ml-2"></h5>
      <div className="blank-space" />
      <div className="pr-3">
        <p className="mb-0 btn-deconnexion" ><FontAwesomeIcon icon={faSignOutAlt} onClick={onSignOut}/></p>
      </div>
    </header>
  );
};

export default withRouter(Header);
