import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartArrowDown,
  faTimes,
  faTruck,
  faCaretDown,
  faMapMarkerAlt,
  faPrint,
  faCheck,
  faUser,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  ListGroup,
  ListGroupItem,
  Modal,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Alert,
} from "reactstrap";
import moment from "moment";
import "moment/locale/fr";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
} from "react-redux-firebase";
import Bill from "../orders/bill";
import { useSelector } from "react-redux";
import { isValidURL } from "../../utils";

function BikerActivityItem({ order }) {
  const db = useFirestore();
  useFirestoreConnect(() => [
    {
      collection: "users",
      doc: order.user.uid,
    },
  ]);
  const [orderItems, setorderItems] = useState([]);
  const [isOpen, setisOpen] = useState(false);
  const [isOpenUserModal, setisOpenUserModal] = useState(false);
  const [isSave, setisSave] = useState(false);
  const [isPrinting, setisPrinting] = useState(false);
  const billRef = useRef();

  const user = useSelector(
    ({ firestore: { data } }) => data.users && data.users[order.user.uid]
  );

  const toggle = () => {
    setisOpen((prev) => !prev);
  };
  const toggleUserModal = () => {
    setisOpenUserModal((prev) => !prev);
  };

  const printReal = useReactToPrint({
    content: () => billRef.current,
    onAfterPrint: () => setisPrinting(false),
  });
  const notifySuccess = (message) => toast.success(message);

  const printBill = () => {
    setisPrinting(true);
    setTimeout(() => {
      printReal();
    }, 100);
  };

  const allowUserToPay = (data) => {
    setisSave(true);
    db.update(`users/${order.user.uid}`, {
      cashOnDelivery: user.cashOnDelivery ? false : true,
    })
      .then((res) => {
        setisSave(false);
        notifySuccess(
          `Client ${user.name} ${
            user.cashOnDelivery
              ? "bloqué à payer à la livraison"
              : "autorisé à payer à la livraison"
          }`
        );
        toggleUserModal();
        toggle();
      })
      .catch((err) => {
        setisSave(false);
      });
  };

  useEffect(() => {
    let orderItems = [];

    order.package.forEach((item) => {
      orderItems.push(JSON.parse(item));
    });
    setorderItems(orderItems);

    return () => {};
  }, []);

  return (
    <ListGroupItem className="order-item" onClick={toggle}>
      <div className="order-info">
        <div className="div-icon">
          <FontAwesomeIcon icon={faTruck} />
        </div>
        <div className="">
          <h6>
            #{order.orderNumber}{" "}
            <Badge
              className={`badge ${order.status === "delivered" && "delivered"}`}
            >
              {order.status === "delivered" ? "Livrée" : "Non livrée"}
            </Badge>
          </h6>
          <p>le {moment(order.createdAt).format("DD-MM-YYYY à HH:mm")}</p>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        size="md"
        centered
        contentClassName="modal-content"
        backdropClassName="modal-backdrop"
      >
        <div className="modal-header">
          <h5>
            Commande{" "}
            <Badge className={`badge-payement ${order.payed && "paid"}`}>
              {order.payed ? "Payée" : "Non payée"}
            </Badge>
            {" et "}
            <Badge
              className={`badge ${order.status === "delivered" && "delivered"}`}
            >
              {order.status === "delivered" ? "Livrée" : "Rejetée"}
            </Badge>
          </h5>
          <button onClick={toggle}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="modal-body">
          {order.status === "rejected" && (
            <Alert color="danger">
              <div>
                <p>
                  <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                  Cette commande a été rejeter à cause :<br />
                  <span className="reject-reason-message ">
                    {order.rejectReason}
                  </span>
                </p>
              </div>
            </Alert>
          )}
          <div className="body-content-header">
            <div className="client-content">
              <img
                src={
                  isValidURL(order.user.avatar)
                    ? order.user.avatar
                    : "/assets/images/avatar.png"
                }
                alt=""
              />
              <div>
                <h6>{order.user.name}</h6>
                <p className="badge-phone-number">{order.user.phone}</p>
              </div>
            </div>
            <div className="order-info">
              <h6>
                <Badge className="badge">#{order.orderNumber}</Badge>
              </h6>
              <p>Le {moment(order.createdAt).format("DD-MM-YYYY")}</p>
            </div>
          </div>
          <ListGroup className="body-content-body" flush>
            <h6>
              <FontAwesomeIcon icon={faCartArrowDown} className="icon-cart" />
              Panier
            </h6>
            <div className="list-of-items">
              {orderItems.map((item) => (
                <ListGroupItem key={item.product.name} className="cart-item">
                  <span className="quantity">{item.count}</span>
                  <span className="product-name">{item.product.name}</span>
                  <span className="price">
                    {item.product.price * item.count}
                  </span>
                  <span className="currency">Fc</span>
                </ListGroupItem>
              ))}
            </div>
            <ListGroupItem className="card-prices">
              <div className="row-price product-div-price">
                <span className="price-text">Produit:</span>
                <span className="price-value">{order.totalPrice} Fc</span>
              </div>
              <div className="row-price delivery-div-price">
                <span className="price-text">Frais de livraison: </span>
                <span className="price-value">
                  {order.deliveryCost ?? 0} Fc
                </span>
              </div>
              <div className="row-price total-div-price">
                <span className="price-text">Total:</span>
                <span className="price-value">
                  {order.totalPrice + (order.deliveryCost ?? 0)} Fc
                </span>
              </div>
            </ListGroupItem>
          </ListGroup>

          <div className="card-delivery-address">
            <h6>
              <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
              Adresse de livraison
            </h6>
            <p>{order.user.address}</p>
          </div>
        </div>

        <Modal isOpen={isOpenUserModal}>
          <div className="modal-header">
            <h5
              className={`${
                isLoaded(user) &&
                (user.cashOnDelivery ? "text-success" : "text-danger")
              }`}
            >
              <FontAwesomeIcon icon={faUser} />{" "}
              {isLoaded(user) &&
                (user.cashOnDelivery
                  ? "Ce client peut payer à la livraison."
                  : "Ce client ne peut pas payer à la livraison.")}
            </h5>
            <button type="button" onClick={toggleUserModal} disabled={isSave}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="modal-body">
            {!isLoaded(user) ? (
              <div>
                <p>Chargement en cours ...</p>
              </div>
            ) : (
              <div className="div-client-info-delivery">
                <img
                  src={
                    isValidURL(user.avatar)
                      ? user.avatar
                      : "/assets/images/avatar.png"
                  }
                  alt=""
                />
                <div>
                  <h6>{user.name}</h6>
                  <p>{user.phone}</p>
                </div>
              </div>
            )}
          </div>
          {isLoaded(user) && (
            <div className="modal-footer">
              <button onClick={allowUserToPay} disabled={isSave}>
                {!isSave ? (
                  <span>
                    <FontAwesomeIcon icon={faCheck} />{" "}
                    {user.cashOnDelivery
                      ? "Bloquer les paiements à la livraison"
                      : "Autoriser les paiements à la livraison"}
                  </span>
                ) : (
                  <Spinner className="spinner" />
                )}
              </button>
              <button
                className="btn-cancel"
                disabled={isSave}
                onClick={toggleUserModal}
              >
                <FontAwesomeIcon icon={faTimes} /> Fermer
              </button>
            </div>
          )}
        </Modal>

        <div className="modal-footer">
          <UncontrolledDropdown direction="down">
            <DropdownToggle className="btn-cancel">
              Gérer <FontAwesomeIcon icon={faCaretDown} />
            </DropdownToggle>
            <DropdownMenu className="dropmenu-container">
              <DropdownItem className="btn-drop-item" onClick={printBill}>
                <FontAwesomeIcon icon={faPrint} className="mr-1" />
                Imprimer
              </DropdownItem>
              <DropdownItem className="btn-drop-item" onClick={toggleUserModal}>
                <FontAwesomeIcon icon={faUser} className="mr-1" />
                Client
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        {isPrinting && (
          <Bill ref={billRef} order={order} orderItems={orderItems} />
        )}
      </Modal>
    </ListGroupItem>
  );
}

export default BikerActivityItem;
