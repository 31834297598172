import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Badge,
  Card
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from 'react-redux'
import { useFirestoreConnect} from 'react-redux-firebase'
import NewUserAdmin from "./newUserAdmin";
import ListAdmins from "./listAdmins";
import "./users.scss";

const Users = () => {
  const [indexMenu, setindexMenu] = useState(0);
  const [isOpenModal, setisOpenModal] = useState(false);
  
  useFirestoreConnect(() => [
    {collection: 'users'}
  ])
  // const riders = useSelector(state => state.firestore.ordered.riders)
  // const restaurants = useSelector(state => state.firestore.ordered.restaurants)
  const users = useSelector(state => state.firestore.ordered.users)

  const toggleModal = () => {
    setisOpenModal(!isOpenModal);
  };

  const handleCategory = index => {
    setindexMenu(index);
  };

  return (
    <Container className="container-users" fluid>
      <div className="container-header-user">
        <h4 className="font-weight-normal">
          {users && <Badge>{(users.length)}</Badge> } Utilisateurs
        </h4>
        <p className="my-0" onClick={toggleModal}>
          <FontAwesomeIcon icon={faPlusCircle} /> Ajouter
        </p>
      </div>
      <Row className="container-dashboard-user">
        <Col className="col-xs-12 col-md-3 mb-2">
          <Card className="pt-3">
            <p className="font-weight-bold user-value-number text-center mb-1">{users && users.filter(user=>user.role==="client").length }</p>
            <p className="text-center text-muted">Clients</p>
          </Card>
        </Col>
        <Col className="col-xs-12 col-md-3 mb-2">
          <Card className="pt-3">
            <p className="font-weight-bold user-value-number text-center mb-1">{users && users.filter(user=>user.role==="agent").length}</p>
            <p className="text-center text-muted">Agents</p>
          </Card>
        </Col>
        <Col className="col-xs-12 col-md-3 mb-2">
          <Card className="pt-3">
            <p className="font-weight-bold user-value-number text-center mb-1">{users && users.filter(user =>user.role === "rider").length}</p>
            <p className="text-center text-muted">Livreurs</p>
          </Card>
        </Col>
        <Col className="col-xs-12 col-md-3 mb-2">
          <Card className="pt-3">
            <p className="font-weight-bold user-value-number text-center mb-1">{users && users.filter(user => user.role === "admin").length }</p>
            <p className="text-center text-muted">Administrateurs</p>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className="">
          <ListAdmins users={users}/>
        </Col>
      </Row>
      <NewUserAdmin isOpen={isOpenModal} toggle={toggleModal} />
    </Container>
  );
};

export default Users;
