import React, { useState} from "react";
import { Switch, Route } from "react-router-dom";
import NavBar from "./navBar/navBar";
import SideBar from "./sidebar/sideBar";
import BackDrop from "./backDrop/backDrop";
import Home from "./home/home";
import Galery from "./galery/galery";
import NewDishesImages from "./galery/newDishesImages"
import Stores from "./stores/stores";
import Settings from "./settings/settings";
import Livreurs from "./livreurs/livreurs";
import Users from "./users/users";
import "./main.scss";
import Gallery from "./galery/gallery";
import NewGalleryMoreImages from "./galery/newGalleryMoreImage";
import Sellers from "./sellers/sellers";

const Main = () => {
  const [viewSidebar, setViewSidebar] = useState(false);
  const toggleSidebar = () => {
    setViewSidebar(!viewSidebar);
  };
  return (
       localStorage.getItem("isAdmin") && <div className="main">
          <NavBar toggleSidebar={toggleSidebar} />
          {viewSidebar ? <BackDrop toggleSidebar={toggleSidebar} /> : null}
          <SideBar show={viewSidebar} setViewSidebar={setViewSidebar} />
          <main className="main-container">
            <Switch>
              <Route exact path="/d" component={Home} />
              <Route path="/d/stores" component={Stores} />
              <Route path="/d/galery" component={Galery} />
              <Route path="/d/galery-import" component={NewDishesImages} />
              <Route path="/d/gallery-import" component={NewGalleryMoreImages} />
              <Route path="/d/settings" component={Settings} />
              <Route path="/d/livreurs" component={Livreurs} />
              <Route path="/d/users" component={Users} />
              <Route path="/d/gallery" component={Gallery}/>
              <Route path="/d/vendeurs" component={Sellers}/>
            </Switch>
          </main>
        </div>
  );
};

export default Main;
