import React, { useState } from "react";
import {
  ListGroupItem,
  Form,
  Input,
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faChevronRight,
  faChevronLeft,
  faEllipsisV,
  faEdit,
  faTrash
} from "@fortawesome/free-solid-svg-icons";

const CityItem = ({city, viewCurrentCityLivreurs, currentCityId }) => {

  let activeMenu = "";
  if (currentCityId === city.id) {
    activeMenu = "active-index";
  }

  return (
    <ListGroupItem
      className={`list-cities-item ${activeMenu}`}
      onClick={viewCurrentCityLivreurs.bind(this, city.city, city.id)}
    >
        <p className="my-0"> {city.city} </p>
        <FontAwesomeIcon
          className="icon-left-active-city"
          icon={faChevronLeft}
          size="sm"
        />
        <FontAwesomeIcon
          className="icon-right-active-city"
          icon={faChevronRight}
          size="sm"
        />
    </ListGroupItem>
  );
};

export default CityItem;
