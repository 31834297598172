import React, { useState, useEffect } from "react";
import { Col, Progress } from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useFirebase, useFirestore } from "react-redux-firebase";

const ImageItem = ({ image }) => {
  const [imageUrl, setimageUrl] = useState("");
  const [uploadProgress, setuploadProgress] = useState(0);
  const [isUpload, setisUpload] = useState(true);
  const firebase = useFirebase();
  const db = useFirestore();

  const upLoad = () => {
    setisUpload(true)
    let uploadTask = firebase
      .storage()
      .ref()
      .child("galleryDishes/" + Date.now())
      .put(image);

    uploadTask.on(
      "state_changed",
      snapshot => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        setuploadProgress(progress);
      },
      error => {
        console.log("Erreur d'upLoad");
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          setimageUrl(downloadURL);
          db.add("galleryDishes", { imageUrl: downloadURL , categories: [] }).then(
            res => {
              setisUpload(false);
            }
          );
        });
      }
    );
  };

  useEffect(() => {
    upLoad();
  }, []);

  return (
    <Col md="3">
      <LazyLoadImage
        src={imageUrl !== "" ? imageUrl : undefined}
        width="100%"
        height="100px"
        className={!isUpload && "mb-2"}
        effect="blur"
        visibleByDefault={true}
        placeholderSrc="/assets/images/placeholder.png"
      />
      {isUpload && (
        <Progress
          color="success"
          value={uploadProgress}
          className="mb-2  mt-1"
          style={{ height: "3px" }}
        />
      )}
    </Col>
  );
};

export default ImageItem;
