import React, { useState, useEffect } from "react";
import { Container, Row, Col, Badge, Card, CardBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faStore } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFirestoreConnect } from "react-redux-firebase";
import "./restaurants.scss";
import NewRestaurantForm from "./newStoreForm";
import ToastMessage from "../toast/toast";

const Stores = () => {
  const history = useHistory();
  const [isOpenNewResto, setisOpenNewResto] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [showDanger, setshowDanger] = useState(false);

  useFirestoreConnect(() => [
    { collection: "cities", orderBy: ["city", "asc"] },
    { collection: "stores" },
  ]);
  const stores = useSelector((state) => state.firestore.ordered.stores);
  const cities = useSelector((state) => state.firestore.ordered.cities);
  const handleShowSuccess = () => {
    setshowSuccess(!showSuccess);
  };

  const handleShowDanger = () => {
    setshowDanger(!showDanger);
  };

  const toggleNewResto = () => {
    setisOpenNewResto(!isOpenNewResto);
  };

  return (
    <Container className="container-restaurants p-5" fluid>
      <div className="container-header-resto">
        <h4 className="font-weight-normal">
          {stores && <Badge>{stores.length}</Badge>} Stores
        </h4>
        <p className="my-0" onClick={toggleNewResto}>
          <FontAwesomeIcon icon={faPlusCircle} /> Ajouter
        </p>
      </div>
      <Row>
        {stores &&
          stores.map((store) => (
            <Col className="col-md-3 col-sm-12" key={store.id}>
              <Card className="card-store mb-2">
                <CardBody
                  onClick={() =>
                    history.push(`/store/${store.id}`)
                  }
                >
                  <FontAwesomeIcon icon={faStore}/> {store.id}
                </CardBody>
              </Card>
            </Col>
          ))}
      </Row>
      <NewRestaurantForm
        isOpen={isOpenNewResto}
        toggle={toggleNewResto}
        cities={cities && cities.filter((city) => city.available === false)}
      />
      <ToastMessage
        isShow={showSuccess}
        toggle={handleShowSuccess}
        message="Enregistré"
        delay={4000}
        icon="check"
        className="bg-success text-white pr-5 mr-3"
      />
      <ToastMessage
        isShow={showDanger}
        toggle={handleShowDanger}
        message="Ouf, une erreur est survenue!"
        delay={5000}
        icon="times"
        className="bg-danger text-white pr-5 mr-3"
      />
    </Container>
  );
};

export default Stores;
