import React, {useState} from "react";
import { Modal, ModalBody, Spinner } from "reactstrap";
import {useFirestore} from "react-redux-firebase"
import { useHistory, useParams } from "react-router-dom";

function DeleteProductModal({ productId, isOpen, toggle }) {
    const {storeId} = useParams()
  const db = useFirestore()
    const history =useHistory()
    const [isDelete, setisDelete] = useState(false)
    const deleteProduct=()=>{
        setisDelete(true)
        db.delete(`stores/${storeId}/products/${productId}`).then(res=>{
            setisDelete(false)
            history.push(`/store/${storeId}/products`)
        }).catch(err=>{
            setisDelete(false)
        })
    }
  return (
    <Modal isOpen={isOpen} centered className="modal-delete-product">
      <ModalBody className="modal-delete-product-body">
        <p>Etes-vous sûr de vouloir supprimer ce produit?</p>
        <div>
          <button className="btn-delete" onClick={deleteProduct} disabled={isDelete}>{isDelete ? <Spinner className="spinner"/>:"Oui"}</button>
          <button onClick={toggle} disabled={isDelete}>Non</button>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default DeleteProductModal;
