import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartArrowDown,
  faTimes,
  faTruck,
  faCaretDown,
  faMapMarkerAlt,
  faBiking,
  faPrint,
  faCheck,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  ListGroup,
  ListGroupItem,
  Modal,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Alert,
} from "reactstrap";
import moment from "moment";
import "moment/locale/fr";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useFirestore } from "react-redux-firebase";
import TimerOrderDelivery from "./timerOrderDelivery";
import Bill from "../orders/bill";
import { isValidURL } from "../../utils";

function DeliveryOrderItem({ order }) {
  const db = useFirestore();

  const [orderItems, setorderItems] = useState([]);
  const [isOpen, setisOpen] = useState(false);
  const [isOpenRejectModal, setisOpenRejectModal] = useState(false);
  const [isOpenConfirmDeliveryModal, setisOpenConfirmDeliveryModal] = useState(
    false
  );
  const [isRejecting, setisRejecting] = useState(false);
  const [isPrinting, setisPrinting] = useState(false);
  const billRef = useRef();
  const { register, handleSubmit } = useForm();

  const toggle = () => {
    setisOpen((prev) => !prev);
  };
  const toggleRejectOrderModal = () => {
    setisOpenRejectModal((prev) => !prev);
  };

  const toggleConfirmOrderDeliveryModal = () => {
    setisOpenConfirmDeliveryModal((prev) => !prev);
  };

  const printReal = useReactToPrint({
    content: () => billRef.current,
    onAfterPrint: () => setisPrinting(false),
  });
  const notifySuccess = (message) => toast.success(message);
  const printBill = () => {
    setisPrinting(true);
    setTimeout(() => {
      printReal();
    }, 100);
  };

  const rejectOrder = (data) => {
    setisRejecting(true);
    db.update(`orders/${order.id}`, {
      status: "rejected",
      rejectReason: data.rejectReason,
    })
      .then((res) => {
        setisRejecting(false);
        notifySuccess(`Commande #${order.orderNumber} annulée`);
        toggleRejectOrderModal();
        toggle();
      })
      .catch((err) => {
        setisRejecting(false);
      });
  };

  const confirmOrderDelivery = (data) => {
    if (order.orderNumber === data.orderNumber) {
      setisRejecting(true);
      db.update(`orders/${order.id}`, {
        status: "delivered",
      })
        .then((res) => {
          setisRejecting(false);
          notifySuccess(`Commande #${order.orderNumber} livrée`);
          toggleConfirmOrderDeliveryModal();
          toggle();
        })
        .catch((err) => {
          setisRejecting(false);
        });
    } else {
      toast.error("Numéro de commande invalide");
    }
  };

  useEffect(() => {
    let orderItems = [];

    order.package.forEach((item) => {
      orderItems.push(JSON.parse(item));
    });
    setorderItems(orderItems);
    return () => {};
  }, []);

  return (
    <ListGroupItem className="order-item" onClick={toggle}>
      <div className="order-info">
        <div className="div-icon">
          <FontAwesomeIcon icon={faTruck} />
        </div>
        <div className="">
          <h6>
            #{order.orderNumber}{" "}
            <Badge className={`badge ${order.payed && "paid"}`}>
              {order.payed ? "Payée" : "Non payée"}
            </Badge>
          </h6>
          <p>le {moment(order.createdAt).format("DD-MM-YYYY à HH:mm")}</p>
        </div>
      </div>
      {/* <div className="order-user">
        <img src="/assets/images/avatar.jpg" />
        <div>
          <h6>{order.user.name}</h6>
          <p>{order.user.phone}</p>
        </div>
      </div> */}
      <TimerOrderDelivery createdAt={order.createdAt} />
      <Modal
        isOpen={isOpen}
        size="md"
        centered
        contentClassName="modal-content"
        backdropClassName="modal-backdrop"
      >
        <div className="modal-header">
          <h5>
            <FontAwesomeIcon icon={faTruck} /> En cours de livraison{" "}
            <Badge className={`badge-payement ${order.payed && "paid"}`}>
              {order.payed ? "Payée" : "Non payée"}
            </Badge>
          </h5>
          <button onClick={toggle}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="modal-body">
          <div className="body-content-header">
            <div className="client-content">
              <img
                src={`${
                  isValidURL(order.user.avatar)
                    ? order.user.avatar
                    : "/assets/images/avatar.png"
                }`}
                alt=""
              />
              <div>
                <h6>{order.user.name}</h6>
                <p className="badge-phone-number">{order.user.phone}</p>
              </div>
            </div>
            <div className="order-info">
              <h6>
                <Badge className="badge">#{order.orderNumber}</Badge>
              </h6>
              <p>Le {moment(order.createdAt).format("DD-MM-YYYY")}</p>
            </div>
          </div>
          <ListGroup className="body-content-body" flush>
            <h6>
              <FontAwesomeIcon icon={faCartArrowDown} className="icon-cart" />
              Panier
            </h6>
            <div className="list-of-items">
              {orderItems.map((item) => (
                <ListGroupItem key={item.product.name} className="cart-item">
                  <span className="quantity">{item.count}</span>
                  <span className="product-name">{item.product.name}</span>
                  <span className="price">
                    {item.product.price * item.count}
                  </span>
                  <span className="currency">Fc</span>
                </ListGroupItem>
              ))}
            </div>
            <ListGroupItem className="card-prices">
              <div className="row-price product-div-price">
                <span className="price-text">Produit:</span>
                <span className="price-value">{order.totalPrice} Fc</span>
              </div>
              <div className="row-price delivery-div-price">
                <span className="price-text">Frais de livraison: </span>
                <span className="price-value">
                  {order.deliveryCost ?? 0} Fc
                </span>
              </div>
              <div className="row-price total-div-price">
                <span className="price-text">Total:</span>
                <span className="price-value">
                  {order.totalPrice + (order.deliveryCost ?? 0)} Fc
                </span>
              </div>
            </ListGroupItem>
          </ListGroup>

          <div className="card-delivery-address">
            <h6>
              <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
              Adresse de livraison
            </h6>
            <p>{order.user.address}</p>
          </div>
          <div className="card-delivery-biker">
            <h6>
              <FontAwesomeIcon icon={faBiking} className="icon" />
              Livreur
            </h6>
            <div className="biker-content">
              <img src={order.rider.avatar} alt="" />
              <div>
                <h6>{order.rider.name}</h6>
                <p>{order.rider.phone}</p>
              </div>
            </div>
          </div>
        </div>

        {/*Modal to confirm delevery */}
        <Modal isOpen={isOpenConfirmDeliveryModal}>
          <form onSubmit={handleSubmit(confirmOrderDelivery)}>
            <div className="modal-header">
              <h5>
                <FontAwesomeIcon icon={faCheck} /> Confirmation de livraison
              </h5>
              <button
                type="button"
                onClick={toggleConfirmOrderDeliveryModal}
                disabled={isRejecting}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <div className="modal-body">
              <p className="confirm-delivery-message">
                Etes-vous sûr que cette commande a été livrer?
              </p>
              <div className="div-input">
                <label>Numéro de la commande</label>
                <input
                  name="orderNumber"
                  ref={register({ required: true, minLength: 6, maxLength: 6 })}
                  placeholder={order.orderNumber}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" disabled={isRejecting}>
                {!isRejecting ? (
                  <span>
                    <FontAwesomeIcon icon={faCheck} /> Confirmer
                  </span>
                ) : (
                  <Spinner className="spinner" />
                )}
              </button>
              <button
                type="button"
                className="btn-cancel"
                onClick={toggleConfirmOrderDeliveryModal}
              >
                Fermer
              </button>
            </div>
          </form>
        </Modal>

        {/* Modal to reject or cancel an order*/}
        <Modal isOpen={isOpenRejectModal}>
          <form onSubmit={handleSubmit(rejectOrder)}>
            <div className="modal-header">
              <h5>
                <FontAwesomeIcon icon={faTimes} /> Annuler la commande
              </h5>
              <button
                type="button"
                onClick={toggleRejectOrderModal}
                disabled={isRejecting}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <div className="modal-body">
              <Alert color="info">
                <FontAwesomeIcon icon={faInfoCircle} /> Il faut avoir une bonne
                raison d'annuler une commande. Veillez fournir la{" "}
                <span className="font-weight-bold text-dark">raison</span> afin
                de notifier le client{" "}
                <span className="font-weight-bold text-dark">{order.name}</span>{" "}
                de l'annulation de sa commande.
              </Alert>

              <div className="div-input">
                <label>Raison d'annulation</label>
                <select name="rejectReason" ref={register({ required: true })}>
                  <option></option>
                  <option value="Complication du client à la livraison">
                    Complication du client à la livraison
                  </option>
                  <option value="L'adresse de livraison hors zone">
                    Adresse de livraison hors zone d'intervention
                  </option>
                  <option value="L'adresse de livraison n'est pas précise">
                    Adresse de livraison non precise
                  </option>
                  <option value="Produit non disponible en stock">
                    Produit non disponible en stock
                  </option>
                  <option value="Le climat est non favorable">
                    Climat non favorable.
                  </option>
                  <option value="Le client est non sûr et mal noté">
                    Client non sûr et mal noté
                  </option>
                </select>
              </div>
              {/* <div className="div-input">
                <label>Ce client peut-il encore payer à la livraison?</label>
                  <select defaultValue={order.user.cashOnDelivery ? "true" : "false"} name="cashOnDelivery" ref={register}>
                    <option value="true">Oui</option>
                    <option value="false">Non</option>
                  </select>
              </div> */}
              {/* <div className="div-input">
                <label>Autre précision</label>
                <textarea name="info" ref={register} />
              </div> */}
            </div>
            <div className="modal-footer">
              <button type="submit" disabled={isRejecting}>
                {!isRejecting ? (
                  <span>
                    <FontAwesomeIcon icon={faCheck} /> Confirmer
                  </span>
                ) : (
                  <Spinner className="spinner" />
                )}
              </button>
            </div>
          </form>
        </Modal>

        <div className="modal-footer">
          {/* <button onClick={toggle}>
            <FontAwesomeIcon icon={faTimes} className="icon" />
            Fermer
          </button> */}
          <button className="" onClick={printBill}>
            <FontAwesomeIcon icon={faPrint} />
          </button>
          {/* <button className="btn-cancel">
            <FontAwesomeIcon icon={faCaretDown} />
          </button> */}
          <UncontrolledDropdown direction="down">
            <DropdownToggle className="btn-cancel">
              <FontAwesomeIcon icon={faCaretDown} />
            </DropdownToggle>
            <DropdownMenu className="dropmenu-container">
              <DropdownItem
                className="btn-drop-item"
                onClick={toggleConfirmOrderDeliveryModal}
              >
                <FontAwesomeIcon icon={faCheck} className="mr-1" /> Marquer
                comme livrée
              </DropdownItem>
              <DropdownItem
                className="btn-drop-item"
                onClick={notifySuccess.bind(
                  this,
                  "Oops! Fonctionalité à venir!"
                )}
              >
                <FontAwesomeIcon icon={faBiking} className="mr-1" /> Changer de
                livreur
              </DropdownItem>
              <DropdownItem
                className="btn-drop-item"
                onClick={toggleRejectOrderModal}
              >
                <FontAwesomeIcon icon={faTimes} className="mr-1" /> Annuler la
                livraison
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        {isPrinting && (
          <Bill ref={billRef} order={order} orderItems={orderItems} />
        )}
      </Modal>
    </ListGroupItem>
  );
}

export default DeliveryOrderItem;
