import React, { useEffect, useState } from "react";
import { ListGroup, ListGroupItem, CustomInput } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faUpload,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useFirestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";
import {toast} from "react-toastify"
import ClientItem from "./clientItem";
import NewClientForm from "./newClientForm";
import LoadingIndicator from "../loadingIndicator/loadingIndicator";
import EmptyDataList from "../loadingIndicator/emptyDataList";
import { useParams } from "react-router-dom";

function ListClients() {
  const {storeId}=useParams()
  useFirestoreConnect(() => [
    {
      collection: "users",
      where: [
        ["role", "==", "client"],
        ["city", "==", storeId],
      ],
      orderBy: ["name", "asc"],
      storeAs: "clients",
    },
  ]);
  const clients = useSelector((state) => state.firestore.ordered.clients);
  const [currentClients, setcurrentClients] = useState([])
  const [searchString, setsearchString] = useState("")
  const [isOpenNewClientForm, setisOpenNewClientForm] = useState(false);

  const toggleNewClientForm = () => {
    setisOpenNewClientForm((prev) => !prev);
  };
  const notifyAsComingFeature=()=>toast.success("Oops! Fonctionalité à venir!")

  const onSearch = (e) => {
    const  value = e.target.value.replace("+", "");
    setsearchString(value)
    if (value != "") {
      const regexToSearch = RegExp(value, "i");
      const searchResult = clients.filter(
        (client) =>
          client.name.match(regexToSearch) || client.phone.match(regexToSearch)
      );
      setcurrentClients(searchResult);
    } else {
      setcurrentClients(clients);
    }
  };

  useEffect(() => {
    clients && setcurrentClients(clients)
    return () => {
      
    }
  }, [clients])

  return (
    <div className="container-clients">
      <div className="container-list-clients-header">
        <div className="div-up">
          <h3>Clients</h3>
        </div>
        <div className="div-down">
          <button onClick={toggleNewClientForm}>Créer</button>
          <div className="search-bar">
            <form>
              <FontAwesomeIcon icon={faSearch} className="search-icon" />
              <input
                type="search"
                name="productToSearch"
                placeholder="Rechercher ici ..."
                value={searchString}
                onChange={onSearch}
              />
            </form>
          </div>
          <button className="download" onClick={notifyAsComingFeature}>
            <FontAwesomeIcon icon={faUpload} />
          </button>
          <button className="download" onClick={notifyAsComingFeature}>
            <FontAwesomeIcon icon={faDownload} />
          </button>
        </div>
      </div>

      <div className="container-list-clients-body">
        <ListGroup className="" flush>
          <ListGroupItem className="client-item header">
            <CustomInput
              type="checkbox"
              id="header-clients-check"
              className="mr-3"
            />
            <div className="name">Client</div>
            <div className="phoneNumber">Téléphone</div>
            <div className="city">Adresse</div>
          </ListGroupItem>
          {!isLoaded(clients) ? (
            <LoadingIndicator />
          ) : isEmpty(clients) || clients.length === 0 ? (
            <EmptyDataList message="Aucun client!" />
          ) : (
            currentClients.length!=0?currentClients.map((client) => (
              <ClientItem key={client.id} client={client} />
            )):<EmptyDataList message="Aucun resultat de recherche!"/>
          )}
        </ListGroup>
      </div>
      <NewClientForm
        isOpen={isOpenNewClientForm}
        toggle={toggleNewClientForm}
      />
    </div>
  );
}

export default ListClients;
