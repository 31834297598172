import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Spinner,
} from "reactstrap";
import { useFirebase, useFirestore } from "react-redux-firebase";
import "./login.scss";
import CopyRight from "../copyRight/copyRight";
import ToastMessage from "../toast/toast";

const Login = (props) => {
  const { register, handleSubmit } = useForm();
  const [isLogin, setisLogin] = useState(false);
  const [showDanger, setshowDanger] = useState(false);
  const [errorMessage, seterrorMessage] = useState(
    "Ouf, une erreur est survenue! Veillez ressayer"
  );
  const firebase = useFirebase();
  const db = useFirestore();

  const handleShowDanger = () => {
    setshowDanger(!showDanger);
  };

  const onSubmit = (data) => {
    setisLogin(true);
    firebase
      .login(data)
      .then((res) => {
        const uid = res.user.user.uid;

        db.collection("users")
          .doc(uid)
          .get()
          .then((res) => {
            if (res.exists && res.data().role==="admin") {
              setisLogin(false);
              localStorage.setItem("isAdmin", `${true}`);
              props.history.push("/d") 
            } else {
              setisLogin(false);
              seterrorMessage("Ouf, une erreur est survenue! Veillez ressayer")
              handleShowDanger();
              firebase.logout()
            }
          }).catch(err=>{
            seterrorMessage("Ouf, une erreur est survenue! Veillez ressayer")
            handleShowDanger();
          });
      })
      .catch((err) => {
        setisLogin(false);
        switch (err.code) {
          case "auth/user-not-found":
            seterrorMessage("Adresse mail non reconnu par tayarifood");
            break;
          case "auth/network-request-failed":
            seterrorMessage("Vérifier votre connexion internet");
            break;
          case "auth/wrong-password":
            seterrorMessage("Mot de passe incorrecte");
            break;
          default:
            break;
        }
        handleShowDanger();
      });
  };

  return (
    <Container className="container-login" fluid>
      <Row>
        <Col className="col-md-4 col-sm-12 col-login-form">
          <div className="form-div">
            <img
              src="/assets/images/logo.png"
              alt=""
              width="40%"
              className="mb-4"
            />
            <h4 className="display-4 mb-3">Connexion</h4>
            <Form className="form" onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <Label className="font-weight-bold my-0">Email</Label>
                <Input
                  type="email"
                  name="email"
                  disabled={isLogin ? true : false}
                  innerRef={register({
                    required: true,
                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                />
              </FormGroup>
              <FormGroup>
                <Label className="font-weight-bold my-0">Mot de passe</Label>
                <Input
                  type="password"
                  name="password"
                  disabled={isLogin ? true : false}
                  innerRef={register({
                    required: true,
                    minLength: 6,
                    maxLength: 14,
                  })}
                />
              </FormGroup>
              <div>
                <Button
                  className="font-weight-bold px-3 mt-2"
                  type="submit"
                  disabled={isLogin ? true : false}
                >
                  {!isLogin ? (
                    "Connexion"
                  ) : (
                    <Spinner color="light" className="spinner-login" />
                  )}
                </Button>
              </div>
            </Form>
            <Label
              className="password-forgoted"
              size="sm"
              disabled={isLogin ? true : false}
            >
              Mot de passe oublié?
            </Label>
          </div>
          <CopyRight color="rgba(0,0,0,0.3)" />
        </Col>
        <Col className="col-md-8 col-xs-12 col-login-empty-space"></Col>
      </Row>
      <ToastMessage
        message={errorMessage}
        icon="times"
        delay={5000}
        toggle={handleShowDanger}
        isShow={showDanger}
        className="bg-danger text-white"
      />
    </Container>
  );
};

export default Login;
