import React, { useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  ListGroup,
  ListGroupItem,
  Badge,
  Button,
  Input,
  DropdownItem,
  Popover,
  Spinner,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup, faUpload } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
} from "react-redux-firebase";
import "./galery.scss";
import NewGaleryCategoryForm from "./newGaleryCategoryForm";
import NewPlanImage from "./newPlanImage";
import ListImages from "./listImages";
import CategoryItem from "./categoryItem";

const Galery = (props) => {
  const [isOpenNewPlanImage, setisOpenNewPlanImage] = useState(false);
  const [isOpenPopOver, setisOpenPopOver] = useState(false);
  const db = useFirestore();
  useFirestoreConnect(() => [
    { collection: "categories" },
    { collection: "galleryDishes" },
  ]);
  const categories = useSelector((state) => state.firestore.ordered.categories);
  const [isSaveCategory, setisSaveCategory] = useState(false);
  const [isCategoryFormVisible, setisCategoryFormVisible] = useState(false);
  const galleryDishes = useSelector(
    (state) => state.firestore.ordered.galleryDishes
  );
  const [currentIdCategory, setcurrentIdCategory] = useState("0");

  const [currentGalery, setcurrentGalery] = useState();

  const viewCurrentGalery = (category, currentId) => {
    const currentGalery = galleryDishes.filter((dish) =>
      dish.categories.includes(category)
    );
    setcurrentGalery(currentGalery);
    setcurrentIdCategory(currentId);
  };

  const viewUncategoriesDish = () => {
    const newDishes = galleryDishes.filter(
      (dish) => dish.categories.length === 0
    );
    setcurrentGalery(newDishes);
    setcurrentIdCategory(galleryDishes.length);
  };

  const viewAllDishes = () => {
    setcurrentGalery(galleryDishes);
    setcurrentIdCategory("0");
  };

  const toggleNewPlanImage = () => {
    setisOpenNewPlanImage(!isOpenNewPlanImage);
    setisOpenPopOver(false);
  };

  const onSearch = (e) => {
    if (e.target.value) {
      const searchString = RegExp(e.target.value, "i");
      const newListdishes = galleryDishes.filter((dish) =>
        dish.categories.match(searchString)
      );
      setcurrentGalery(newListdishes);
      setcurrentIdCategory("0");
    } else {
      setcurrentGalery(galleryDishes);
    }
  };

  const togglePopOverUpload = () => {
    setisOpenPopOver(!isOpenPopOver);
  };

  useEffect(() => {
    setcurrentGalery(galleryDishes);
  }, [galleryDishes]);

  return (
    <Container fluid className="container-galery pb-2">
      <div className="container-galery-header">
        <h4 className="font-weight-normal">
          {galleryDishes && <Badge>{galleryDishes.length}</Badge>} Images de
          plats
        </h4>
        <div>
          <button
            id="UncontrolledPopoverUpload"
            type="button"
            className="btn-upload-image bg-transparent"
          >
            <FontAwesomeIcon icon={faUpload} /> Importer
          </button>
          <Popover
            className="py-0"
            isOpen={isOpenPopOver}
            placement="bottom"
            target="UncontrolledPopoverUpload"
            toggle={togglePopOverUpload}
          >
            <DropdownItem onClick={toggleNewPlanImage}>Une image</DropdownItem>
            <DropdownItem
              onClick={() => props.history.push("/d/galery-import")}
            >
              Plusieurs images
            </DropdownItem>
          </Popover>
        </div>
      </div>
      {isLoaded(galleryDishes) ? (
        <Row>
          <Col sm="12" md="3">
            <ListGroup flush className="list-categories">
              <ListGroupItem className="list-categories-header">
                <FontAwesomeIcon icon={faLayerGroup} /> Par categorie
              </ListGroupItem>
              <ListGroupItem
                className={`list-categories-item py-1 ${
                  currentIdCategory === galleryDishes.length && "active-index"
                }`}
                onClick={viewUncategoriesDish}
              >
                Non categorisées
              </ListGroupItem>
              <ListGroupItem
                className={`list-categories-item py-1 ${
                  currentIdCategory === "0" && "active-index"
                }`}
                onClick={viewAllDishes}
              >
                Toutes
              </ListGroupItem>

              <div className="col-categories-scroll overflow-auto">
                {categories &&
                  categories.map((category) => (
                    <CategoryItem
                      key={category.id}
                      category={category}
                      currentId={currentIdCategory}
                      viewCurrentGalery={viewCurrentGalery}
                    />
                  ))}
              </div>
              <NewGaleryCategoryForm viewCurrentGalery={viewCurrentGalery} />
            </ListGroup>
          </Col>
          <Col md="9">
            {/* <ListGroup flush className="search-bar">
              <ListGroupItem className="search-bar-content py-2">
                <Button
                  className="font-weight-bold px-3"
                  size="sm"
                  onClick={viewAllDishes}
                >
                  Tous
                </Button>
                <Input
                  type="text"
                  placeholder="Rechercher ..."
                  bsSize="sm"
                  onChange={onSearch}
                />
                <Button size="sm">
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              </ListGroupItem>
            </ListGroup> */}
            <div className="row-list-dish overflow-auto">
              <ListImages dishes={currentGalery} setDishes={setcurrentGalery} />
            </div>
          </Col>
        </Row>
      ) : (
        <div
          style={{
            height: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner color="warning" />
        </div>
      )}
      <NewPlanImage
        isOpen={isOpenNewPlanImage}
        toggle={toggleNewPlanImage}
        categories={categories}
      />
    </Container>
  );
};

export default Galery;
