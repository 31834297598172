import React from "react";
import { Provider } from "react-redux";
import { Spinner } from "reactstrap";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { ReactReduxFirebaseProvider, isLoaded } from "react-redux-firebase";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { store, rrfProps } from "../store";
import Login from "./login/login";
import Main from "./main";
import MainStoreRoute from "./stores/components/routes/mainStoreRoute";
import PrivateRoute from "./privateRoute";

function AuthIsLoaded({ children }) {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth))
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner
          type="grow"
          style={{ height: "64px", width: "64px", backgroundColor: "orange" }}
        />
      </div>
    );
  return children;
}

const Routes = () => {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <AuthIsLoaded>
              <PrivateRoute path="/store">
                <MainStoreRoute />
              </PrivateRoute>
              <PrivateRoute path="/d">
                <Main />
              </PrivateRoute>
            </AuthIsLoaded>
          </Switch>
        </Router>
      </ReactReduxFirebaseProvider>
      <ToastContainer
        className="foo"
        toastClassName="toast-wrapper"
        hideProgressBar
        closeButton={false}
      />
    </Provider>
  );
};

export default Routes;
