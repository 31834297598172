import React, { useEffect, useState } from "react";
import { ListGroup, ListGroupItem, CustomInput } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faUpload,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { useFirestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import LoadingIndicator from "../loadingIndicator/loadingIndicator";
import EmptyDataList from "../loadingIndicator/emptyDataList";
import BikerItem from "./bikerItem";
import { useHistory, useParams } from "react-router-dom";
import {toast} from "react-toastify"

function BikersList() {
  const {storeId} =useParams()
  useFirestoreConnect(() => [
    {
      collection: "users",
      where: [
        ["city", "==", storeId],
        ["role", "==", "rider"],
      ],
      orderBy: ["name", "asc"],
      storeAs: "bikers",
    },
  ]);
  const history = useHistory();
  // const [isOpenNewBikerForm, setisOpenNewBikerForm] = useState(false);
  const bikers = useSelector((state) => state.firestore.ordered.bikers);
const [currentBikers, setcurrentBikers] = useState([])
const [searchString, setsearchString] = useState("")
  // const toggleNewBikerForm = () => {
  //   setisOpenNewBikerForm((prev) => !prev);
  // };

  const goTo = (path) => {
    history.push(path);
  };

  const notifyAsComingFeature= ()=>toast.success("Oops! Fonctionalité à venir!")
const onSearch = (e)=>{
  const value = e.target.value.replace("+","")
  setsearchString(value)
  if(value!=""){
    const regexToSearch = RegExp(value, "i");
    const searchResult = bikers.filter(
      (biker) =>
      biker.name.match(regexToSearch)||biker.phone.match(regexToSearch)
    );
    setcurrentBikers(searchResult);
  }else{
    setcurrentBikers(bikers)
  }
}

useEffect(() => {
  bikers && setcurrentBikers(bikers)
  return () => {
    
  }
}, [bikers])
  return (
    <div className="container-clients">
      <div className="container-list-clients-header">
        <div className="div-up">
          <h3>Livreurs</h3>
        </div>
        <div className="div-down">
          <button onClick={goTo.bind(this, `/store/${storeId}/bikers/new`)}>Créer</button>
          <div className="search-bar">
            <form>
              <FontAwesomeIcon icon={faSearch} className="search-icon" />
              <input
                type="search"
                name="productToSearch"
                placeholder="Rechercher ici ..."
                value={searchString}
                onChange={onSearch}
              />
            </form>
          </div>
          <button className="download" onClick={notifyAsComingFeature}>
            <FontAwesomeIcon icon={faUpload} />
          </button>
          <button className="download" onClick={notifyAsComingFeature}>
            <FontAwesomeIcon icon={faDownload} />
          </button>
        </div>
      </div>
      <div className="container-list-clients-body">
        <ListGroup className="" flush>
          <ListGroupItem className="client-item header">
            <CustomInput
              type="checkbox"
              id="header-clients-check"
              className="mr-3"
            />
            <div className="name">Livreur</div>
            <div className="phoneNumber">Téléphone</div>
            <div className="city">Status</div>
          </ListGroupItem>
          {!isLoaded(bikers) ? (
            <LoadingIndicator />
          ) : isEmpty(bikers) || bikers.length === 0 ? (
            <EmptyDataList message="Aucun livreur!" />
          ) : (
            currentBikers.length!=0?currentBikers.map((biker) => <BikerItem key={biker.id} biker={biker} />)
            :<EmptyDataList message="Aucun resultat de recherche!"/>
          )}
        </ListGroup>
      </div>
    </div>
  );
}

export default BikersList;
