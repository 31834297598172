import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "./livreurs.scss";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormGroup,
  Form,
  Label,
  Input,
  Button,
  CustomInput,
  Progress,
  Spinner
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useFirebase, useFirestore } from "react-redux-firebase";
import Dropzone from "react-dropzone";
import ToastMessage from "../toast/toast";

const NewLivreurForm = ({ isOpen, toggle, cities }) => {
  const { register, handleSubmit } = useForm();
  const [uploadAvatarProgress, setuploadAvatarProgress] = useState(0);
  const [avatar, setAvatar] = useState("");
  const [isSave, setisSave] = useState(false);
  const [isUpload, setisUpload] = useState(false);
  const firebase = useFirebase();
  const db = useFirestore();
  const [showSuccess, setshowSuccess] = useState(false)
  const [showDanger, setshowDanger] = useState(false)

  const handleShowSuccess = () => {
    setshowSuccess(!showSuccess)
  }
  const handleShowDanger = () => {
    setshowDanger(!showDanger)
  }

  const onSubmit = data => {
    setisSave(true);
    const emailRider = `${data.phone}@damaje.com`;
    firebase
      .auth()
      .createUserWithEmailAndPassword(emailRider, "livreur")
      .then(res => {
        db.add(`stores/${data.city}/riders`, {
          ...data,
          avatar: avatar,
          user: res.user.uid,
          createAt: db.Timestamp.now().toMillis()
        }).then(res => {
          setuploadAvatarProgress(0);
          setAvatar("");
          setisSave(false);
          handleShowSuccess()
          toggle();
        });
      })
      .catch(err => {
        handleShowDanger()
        setisSave(false);
        console.log(err.message);
      });
  };

  const onDropAvatar = files => {
    setisUpload(true);
    let uploadTask = firebase
      .storage()
      .ref()
      .child("avatars/" + Date.now())
      .put(files[0]);

    uploadTask.on(
      "state_changed",
      snapshot => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        setuploadAvatarProgress(progress);
      },
      error => {
        setisUpload(false);
        console.log("Erreur d'upLoad");
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          console.log(downloadURL);
          setisUpload(false);
          setAvatar(downloadURL);
        });
      }
    );
  };

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} className="new-modal-livreur-form">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={toggle}>Nouveau livreur</ModalHeader>
          <ModalBody>
            <Row form>
              <Col className="col-8">
                <FormGroup>
                  <Label className="font-weight-bold my-0" size="sm">
                    <span className="text-success">*</span>
                    Noms
                  </Label>
                  <Input
                    type="text"
                    bsSize="sm"
                    name="name"
                    disabled={isSave ? true : false}
                    innerRef={register({ required: true, minLength: 2 })}
                  />
                </FormGroup>
                <Row form>
                  <Col>
                    <FormGroup>
                      <Label className="font-weight-bold my-0" size="sm">
                        <span className="text-success">*</span>Sexe
                      </Label>
                      <Input
                        type="select"
                        bsSize="sm"
                        name="sex"
                        disabled={isSave ? true : false}
                        innerRef={register({ required: true })}
                      >
                        <option></option>
                        <option value="M">M</option>
                        <option value="F">F</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label className="font-weight-bold my-0" size="sm">
                        <span className="text-success">*</span>Etat civil
                      </Label>
                      <Input
                        type="select"
                        bsSize="sm"
                        name="stateCivil"
                        disabled={isSave ? true : false}
                        innerRef={register({ required: true })}
                      >
                        <option></option>
                        <option value="Marié (e)">Marié (e)</option>
                        <option value="Célibataire">Célibataire</option>
                        <option value="Veuf">Veuf (ve)</option>
                        <option value="Divorcé (e)">Divorcé (e)</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col className="col-4">
                <Label className="font-weight-bold text-right my-0" size="sm">
                  Avatar{" "}
                  {avatar !== "" && (
                    <FontAwesomeIcon icon={faCheck} className="text-success" />
                  )}
                </Label>
                <div className="col-avatar-livreur">
                  {isUpload ? (
                    <Spinner
                      color="light"
                      className="mb-1"
                      style={{ height: "100px", width: "100px" }}
                    />
                  ) : (
                    <img
                      src={
                        avatar !== ""
                          ? avatar
                          : "/assets/images/user-placeholder.png"
                      }
                      className="mb-1"
                      height="100px"
                      width="100px"
                    />
                  )}
                  <Dropzone
                    onDrop={onDropAvatar}
                    accept="image/png,image/jpeg,image/gif"
                    multiple={false}
                  >
                    {({
                      getRootProps,
                      getInputProps,
                      isDragActive,
                      isDragReject
                    }) => (
                      <div
                        {...getRootProps()}
                        style={{ width: "80px" }}
                        className="avatar-form"
                      >
                        <input
                          {...getInputProps()}
                          disabled={isSave || isUpload ? true : false}
                        />
                        <div className="parcourir-btn-file-upload py-0">
                          Parcourir
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </Col>
            </Row>
            <FormGroup>
              <Label className="font-weight-bold my-0" size="sm">
                <span className="text-success">*</span>
                Téléphone
              </Label>
              <Input
                type="tel"
                name="phone"
                disabled={isSave ? true : false}
                innerRef={register({ required: true })}
              />
            </FormGroup>
            <FormGroup>
              <Label
                className="font-weight-bold my-0"
                size="sm"
                disabled={isSave ? true : false}
              >
                Ville ou cité d'affectation
              </Label>
              <Input
                type="select"
                name="city"
                innerRef={register({ required: true })}
              >
                <option></option>
                {cities &&
                  cities.map(city => (
                    <option key={city.id} value={city.city}>
                      {city.city}
                    </option>
                  ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label className="font-weight-bold my-0" size="sm">
                Adresse locale
              </Label>
              <Input
                type="textarea"
                rows="3"
                name="adresse"
                disabled={isSave ? true : false}
                innerRef={register({})}
              />
            </FormGroup>
            <FormGroup>
              <div>
                <CustomInput
                  type="checkbox"
                  name="userCondition"
                  id="condition"
                  disabled={isSave ? true : false}
                  innerRef={register({ required: true })}
                  label="Condition d'utilisation"
                  inline
                />
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter className="footer-modal-livreur">
            <Button
              className="px-3 font-weight-bold"
              size="sm"
              onClick={toggle}
              disabled={isSave ? true : false}
            >
              Annuler
            </Button>
            <Button
              className="px-3 font-weight-bold"
              size="sm"
              type="submit"
              disabled={isSave ? true : false}
            >
              {!isSave ? (
                "Enregistrer"
              ) : (
                <Spinner color="light" className="spinner-isSaving" />
              )}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <ToastMessage
        isShow={showSuccess}
        toggle={handleShowSuccess}
        message="Enregistré"
        delay={4000}
        icon="check"
        className="bg-success text-white pr-5 mr-3"
      />
      <ToastMessage
        isShow={showDanger}
        toggle={handleShowDanger}
        message="Ouf, une erreur est survenue!"
        delay={5000}
        icon="check"
        className="bg-danger text-white pr-5 mr-3"
      />
    </React.Fragment>
  );
};

export default NewLivreurForm;
