import React, { useState } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faCog,
  faQuestion,
  faUserFriends,
  faTimes,
  faHistory,
  faCarrot,
  faCashRegister,
  faBiking,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory, useLocation, useParams,withRouter } from "react-router-dom";
import "./sidebar.scss";
import OfflineDetector from "../offline/offlineDetector";
import { ReactComponent as Mango } from "./mango.svg";

function Sidebar({ isShowSidebar, setisShowSidebar }) {
 
  const history = useHistory();
  const location = useLocation();
  const [activeMenuIndex, setActiveMenuIndex] = useState(1);
  let  storeId= ""

  if(location.pathname.split("/").length>2){
    storeId=location.pathname.split("/")[2];
  }else{
    history.push("/d/stores")
  }

  const goTo = (indexMenu, path) => {
    setActiveMenuIndex(indexMenu);
    history.push(path);
  };


  const toggleSidebar = () => {
    setisShowSidebar((prev) => !prev);
  };

  return (
    <div className={`sidebar ${isShowSidebar && "hide"}`}>
      <nav>
          <Link to="/d/stores" className="sidebar-header">
          <Mango height="24px" />
          <h1>
            Tayari<span>food</span>
          </h1>
          <button onClick={toggleSidebar}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          </Link>
        <div className="sidebar-content">
          <OfflineDetector storeId={storeId} />
          <div className="sidebar-body">
            <ListGroup flush>
              <ListGroupItem
                className={`sidebar-menu-item ${
                  location.pathname === `/store/${storeId}/` && "active"
                }`}
                onClick={goTo.bind(this, 1, `/store/${storeId}/`)}
              >
                {/* <FontAwesomeIcon icon={faHome} className="icon" />
                Accueil */}
                <FontAwesomeIcon icon={faCashRegister} className="icon" />
                Point de vente
              </ListGroupItem>
              {/* <ListGroupItem
                className={`sidebar-menu-item ${
                  (activeMenuIndex === 2 ||
                    location.pathname === `/store/${storeId}/point-of-sale`) &&
                  "active"
                }`}
                onClick={goTo.bind(this, 2, `/store/${storeId}/point-of-sale`)}
              >
                <FontAwesomeIcon icon={faCashRegister} className="icon" />
                Point de vente
              </ListGroupItem> */}
              <ListGroupItem
                className={`sidebar-menu-item ${
                  (activeMenuIndex === 3 ||
                    location.pathname === `/store/${storeId}/orders_history`) &&
                  "active"
                }`}
                onClick={goTo.bind(this, 3, `/store/${storeId}/orders_history`)}
              >
                <FontAwesomeIcon icon={faHistory} className="icon" />
                Historique
              </ListGroupItem>
              <ListGroupItem
                className={`sidebar-menu-item ${
                  (activeMenuIndex === 4 ||
                    location.pathname.match(`/store/${storeId}/products`)) &&
                  "active"
                }`}
                onClick={goTo.bind(this, 4, `/store/${storeId}/products`)}
              >
                <FontAwesomeIcon icon={faCarrot} className="icon" />
                Produits
              </ListGroupItem>
              <ListGroupItem
                className={`sidebar-menu-item ${
                  (activeMenuIndex === 9 ||
                    location.pathname.match(`/store/${storeId}/menu`)) &&
                  "active"
                }`}
                onClick={goTo.bind(this, 9, `/store/${storeId}/menu`)}
              >
                <FontAwesomeIcon icon={faUtensils} className="icon" />
                Restaurant
              </ListGroupItem>
              <ListGroupItem
                className={`sidebar-menu-item ${
                  (activeMenuIndex === 8 ||
                    location.pathname.match(`/store/${storeId}/bikers`)) &&
                  "active"
                }`}
                onClick={goTo.bind(this, 8, `/store/${storeId}/bikers`)}
              >
                <FontAwesomeIcon icon={faBiking} className="icon" />
                Livreurs
              </ListGroupItem>
              <ListGroupItem
                className={`sidebar-menu-item ${
                  (activeMenuIndex === 5 ||
                    location.pathname.match(`/store/${storeId}/clients`)) &&
                  "active"
                }`}
                onClick={goTo.bind(this, 5, `/store/${storeId}/clients`)}
              >
                <FontAwesomeIcon icon={faUserFriends} className="icon" />
                Clients
              </ListGroupItem>
              <ListGroupItem
                className={`sidebar-menu-item ${
                  location.pathname.match(`/store/${storeId}/settings`) &&
                  "active"
                }`}
                onClick={goTo.bind(this, 6, `/store/${storeId}/settings`)}
              >
                <FontAwesomeIcon icon={faCog} className="icon" />
                Paramètres
              </ListGroupItem>
              {/* <ListGroupItem
                className={`sidebar-menu-item ${
                  (activeMenuIndex === 7 || location.pathname === `/store/${storeId}/help`) &&
                  "active"
                }`}
                onClick={goTo.bind(this, 7, `/store/${storeId}/help`)}
              >
                <FontAwesomeIcon icon={faQuestion} className="icon" />
                Aide
              </ListGroupItem> */}
            </ListGroup>
          </div>
          <div className="sidebar-footer">
            <p>© 2020 Tayarifood. Tous droits réservés.</p>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default withRouter(Sidebar) ;
