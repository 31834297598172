import React, { useState } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser
} from "@fortawesome/free-solid-svg-icons";

const ListAdmins = ({users}) => {
  const adminUsers = users ? users.filter(user => user.role === "admin") : []
  return (
    <ListGroup flush>
      <ListGroupItem className="list-admin-users-header">
        Utilisateurs administrateurs
      </ListGroupItem>
      {adminUsers.map(user => (
        <ListGroupItem key={user.id} className="list-admin-users-item">
            <FontAwesomeIcon icon={faUser} size="2x"/>
          <div className="ml-2">
            <h6 className="my-0 font-weight-normal">{user.name}</h6>
            <p className="my-0 text-muted">{user.email}</p>
          </div>
        </ListGroupItem>
      ))}
    </ListGroup>
  );
};

export default ListAdmins;
