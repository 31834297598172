import React from 'react'
import {Col} from "reactstrap"
import NoData from "../noData.svg";

function EmptyListDishes() {
    return (
        <Col className="empty-list-images mt-5">
        <small className=" text-muted font-weight-normal text-center mb-2">
          Aucun resultat
        </small>
        <img src={NoData} alt="" width="30%" />
      </Col>
    )
}

export default EmptyListDishes
