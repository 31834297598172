import React from 'react'
import {Switch, Route} from "react-router-dom"
import EditDishForm from './editDishForm'
import Menu from './menu'
import NewDishForm from './newDishForm'
import "../products/products.scss"

function Restaurant() {
    return (
        <div className="">
        <Switch>
            <Route exact path="/store/:storeId/menu" component={Menu}/>
            <Route path="/store/:storeId/menu/new" component={NewDishForm}/>
            <Route path="/store/:storeId/menu/edit/:dishId" component={EditDishForm}/>
        </Switch>
    </div>
    )
}

export default Restaurant
