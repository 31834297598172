import React, { useState } from "react";
import {
  Alert,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { useFirestore, useFirebase } from "react-redux-firebase";
import { useForm } from "react-hook-form";

function PaymentForm({ isOpen, toggle }) {
  const db = useFirestore();
  const firebase = useFirebase();
  const { register, handleSubmit } = useForm();
  const [isSave, setisSave] = useState(false);
  const [imageUrl, setimageUrl] = useState();
  const [isUpload, setisUpload] = useState(false);
  const [uploadFileProgress, setuploadFileProgress] = useState(0);
  const [isThereImage, setisThereImage] = useState(true);

  const toggleForm = () => {
    setimageUrl();
    setisThereImage(true);
    toggle();
  };

  const saveToDB = (data) => {
    if (imageUrl) {
      setisSave(true);
      db.add(`networks`, {
        ...data,
        imageUrl: imageUrl,
        steps: [],
        updatedAt: db.Timestamp.now().toMillis(),
      })
        .then((res) => {
          setisSave(false);
          toggleForm();
        })
        .catch((err) => {
          setisSave(false);
        });
    } else {
      setisThereImage(false);
    }
  };

  const onDropFiles = (files) => {
    setisUpload(true);
    setisThereImage(true);
    let uploadTask = firebase
      .storage()
      .ref()
      .child("telecomslogo/" + Date.now())
      .put(files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        setuploadFileProgress(progress);
      },
      (error) => {
        setisUpload(false);
        console.log("Erreur d'upLoad");
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          setimageUrl(downloadURL);
          setisUpload(false);
        });
      }
    );
  };

  return (
    <Modal isOpen={isOpen} centered>
      <form onSubmit={handleSubmit(saveToDB)}>
        <ModalHeader>Nouveau réseau</ModalHeader>
        <ModalBody>
          <Row form className="mt-2">
            <Col>
              <div className="div-network-logo">
                <label>Logo du réseau</label>
                <Dropzone
                  onDrop={onDropFiles}
                  accept="image/jpeg,image/png"
                  multiple={false}
                >
                  {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragReject,
                  }) => (
                    <div
                      {...getRootProps()}
                      className={`dropzone-image ${
                        !isThereImage && "no-image"
                      }`}
                      style={{
                        backgroundImage: `url(${imageUrl})`,
                        backgroundRepeat: "none",
                        backgroundSize: "cover",
                        backgroundColor: "#f9f9f9",
                        backgroundPosition: "center",
                      }}
                    >
                      <input
                        {...getInputProps()}
                        disabled={isSave || isUpload ? true : false}
                      />
                      {!isUpload ? (
                        <div className="btn-upload">
                          <p>
                            <FontAwesomeIcon icon={faCamera} />
                          </p>
                          <h6>Upload</h6>
                        </div>
                      ) : (
                        <div className="spinner-progress">
                          <Spinner />
                          <p>{uploadFileProgress.toFixed(0)}%</p>
                        </div>
                      )}
                    </div>
                  )}
                </Dropzone>
              </div>
            </Col>
            <Col>
              <div className="div-input">
                <label>Nom du réseau</label>
                <input
                  type="text"
                  name="network"
                  ref={register({ required: true })}
                  disabled={isSave}
                />
              </div>
              <div className="div-input">
                <label>Numéro de téléphone</label>
                <input
                  type="text"
                  name="phone"
                  ref={register({ required: true })}
                  disabled={isSave}
                />
              </div>
              <Alert>
            La procedure de recharge après sauvegarde.
          </Alert>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          
          <button className="btn-cancel-new" type="button" onClick={toggleForm} disabled={isSave}>
            Annuler
          </button>
          <button className="btn-save" type="submit" disabled={isSave}>
            Sauvegarder
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

export default PaymentForm;
