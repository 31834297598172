import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Spinner } from "reactstrap";
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
  isEmpty,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoadingIndicator from "../loadingIndicator/loadingIndicator";
import EmptyDataList from "../loadingIndicator/emptyDataList";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Times() {
  const { storeId } = useParams();
  useFirestoreConnect(() => [
    {
      collection: "stores",
      doc: storeId,
    },
  ]);
  const history = useHistory();
  const db = useFirestore();
  const { register, handleSubmit } = useForm();
  const [isSave, setisSave] = useState(false);
  const store = useSelector(
    ({ firestore: { data } }) => data.stores && data.stores[storeId]
  );
  const notifySuccess = (message) => toast.success(message);

  const saveToDB = (data) => {
    setisSave(true);
    db.update(`stores/${storeId}`, {
      closingTime: data.closingTime,
      openingTime: data.openingTime,
      deliveryMinTime: parseInt(data.deliveryMinTime),
    })
      .then((res) => {
        notifySuccess("Sauvegardé");
        localStorage.setItem("deliveryMinTime", `${data.deliveryMinTime}`);
        setisSave(false);
      })
      .catch((err) => {
        setisSave(false);
      });
  };

  return (
    <div className="container-fluid container-settings-category">
      {!isLoaded(store) ? (
        <LoadingIndicator />
      ) : isEmpty(store) ? (
        <EmptyDataList message="Rien à afficher!" />
      ) : (
        <form onSubmit={handleSubmit(saveToDB)}>
          <div className="form-container-content">
          <h3>Paramètres temps</h3>
            <div className="row">
              <div className="col-6">
                <div className="div-input">
                  <label>Heure d'ouverture</label>
                  <input
                    type="time"
                    name="openingTime"
                    defaultValue={store.openingTime}
                    ref={register({ required: true })}
                    disabled={isSave}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="div-input">
                  <label>Heure de fermeture</label>
                  <input
                    type="time"
                    name="closingTime"
                    defaultValue={store.closingTime}
                    ref={register({ required: true })}
                    disabled={isSave}
                  />
                </div>
              </div>
            </div>
                <div className="div-input">
                  <label>Durée minimale de livraison</label>
                  <select
                    name="deliveryMinTime"
                    defaultValue={store.deliveryMinTime}
                    ref={register({ required: true })}
                    disabled={isSave}
                  >
                    <option value="1200000">20 min</option>
                    <option value="1500000">25 min</option>
                    <option value="1800000">30 min</option>
                    <option value="2100000">35 min</option>
                    <option value="2400000">40 min</option>
                    <option value="2700000">45 min</option>
                    <option value="3000000">50 min</option>
                    <option value="3300000">55 min</option>
                    <option value="3600000">1 h</option>
                    <option value="7200000">2 h</option>
                    <option value="10800000">3 h</option>
                    <option value="14400000">4 h</option>
                    <option value="18000000">5 h</option>
                    <option value="21600000">6 h</option>
                  </select>
                </div>
            <div className="text-center mt-4">
            <button
              type="submit"
              className="btn-fresh-login"
              disabled={isSave}
            >
              {isSave ? (
                <Spinner className="spinner" />
              ) : (
                <span>
                Sauvegarder
                </span>
              )}
            </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default Times;
