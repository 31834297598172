import React from 'react'
import "./styles.scss"
import {ReactComponent as NoData} from "./nodata.svg"

function EmptyDataList({message}) {
    return (
        <div className="empty-data-list">
            <NoData className="nodata"/>
            {message}
        </div>
    )
}

export default EmptyDataList
