import React, { useState } from "react";
import {
  ListGroupItem,
  ListGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  CustomInput,
} from "reactstrap";
import { useForm } from "react-hook-form";
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
  isEmpty,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import CategoryItem from "./categoryItem";

const NewCategoryForm = ({ isOpen, toggle }) => {
  const db = useFirestore();
  const { register, handleSubmit } = useForm();
  const [isSave, setisSave] = useState(false);

  const saveToDB = (data) => {
    setisSave(true);
    db.add("categories", {
      ...data,
      createdAt: db.Timestamp.now().toMillis(),
    })
      .then((res) => {
        setisSave(false);
        toggle();
      })
      .catch((err) => {
        setisSave(false);
      });
  };
  return (
    <Modal isOpen={isOpen} centered className="modal-new-unit">
      <form onSubmit={handleSubmit(saveToDB)}>
        <ModalHeader>Nouvelle catégorie fresh</ModalHeader>
        <ModalBody className="modal-new-unit-body">
          <div className="div-input">
            <label>Catégorie</label>
            <input
              type="text"
              name="category"
              ref={register({ required: true })}
              disabled={isSave}
            />
          </div>
        </ModalBody>
        <ModalFooter className="modal-new-unit-footer">
          <button type="submit" className="btn-save" disabled={isSave}>
            {isSave ? <Spinner className="spinner" /> : "Sauvegarder"}
          </button>
          <button onClick={toggle} disabled={isSave}>
            Annuler
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

function Categoriesdish() {
  useFirestoreConnect(() => [
    { collection: "categories", orderBy: ["category", "asc"] },
  ]);
  const [isOpenNewCategoryForm, setisOpenNewCategoryForm] = useState(false);
  const categories = useSelector((state) => state.firestore.ordered.categories);
  const toggleNewCategoryForm = () => {
    setisOpenNewCategoryForm((prev) => !prev);
  };

  return (
    <div className="units-container">
      <div className="units-container-header">
        <h4>Catégories de repas du restaurant</h4>
        <button onClick={toggleNewCategoryForm}>Créer</button>
      </div>
      <ListGroup className="list-units">
        <ListGroupItem className="unit-item header">
          <CustomInput
            type="checkbox"
            id="header-check-city"
            className="mr-3"
          />
          <div className="unit">Catégorie</div>
        </ListGroupItem>
        {!isLoaded(categories) ? (
          <div className="loading-data-indicator">
            <p>En cours de chargement ...</p>
          </div>
        ) : isEmpty(categories) ? (
          <div className="empty-list-data">
            <p>Aucune catégorie trouvée!</p>
          </div>
        ) : (
          categories.map((category) => (
            <CategoryItem key={category.id} category={category}/>
            // <ListGroupItem key={category.id} className="unit-item py-1">
            //   <CustomInput
            //     type="checkbox"
            //     name="userCondition"
            //     className="mr-3"
            //     id={`check${category.id}`}
            //   />
            //   <div className="unit">{category.category}</div>
            // </ListGroupItem>
          ))
        )}
      </ListGroup>

      <NewCategoryForm
        isOpen={isOpenNewCategoryForm}
        toggle={toggleNewCategoryForm}
      />
    </div>
  );
}

export default Categoriesdish;
