import React from 'react'
import {ReactComponent as Launching} from "./launching.svg"
import "./styles.scss"

function PointOfSale() {
    return (
        <div className="container-point-of-sale">
            <Launching className="launching-image"/>
            <p>Fonctionalité à venir</p>
        </div>
    )
}

export default PointOfSale
