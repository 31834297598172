import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Form,
  Label,
  Progress,
  Spinner,
  Row,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faCheck } from "@fortawesome/free-solid-svg-icons";
import { useFirebase, useFirestore } from "react-redux-firebase";
import ToastMessage from "../toast/toast";
import "./galery.scss";

const NewPlanImage = ({ isOpen, toggle, categories }) => {
  const [urlImage, seturlImage] = useState();
  const db = useFirestore();
  const firebase = useFirebase();
  const [listCategories, setlistCategories] = useState([]);
  const [imageUrl, setimageUrl] = useState("");
  const [uploadImageProgress, setuploadImageProgress] = useState(0);
  const [isUpload, setisUpload] = useState(false);
  const [isSave, setisSave] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [showDanger, setshowDanger] = useState(false);

  const addToListCategories = (valueCategory) => {
    if (!listCategories.includes(valueCategory)) {
      setlistCategories([...listCategories, valueCategory]);
    }
  };
  const deleteFromListCategories = (valueCategory) => {
    setlistCategories(
      listCategories.filter((category) => category !== valueCategory)
    );
  };

  const handleShowSuccess = () => {
    setshowSuccess(!showSuccess);
  };
  const handleShowDanger = () => {
    setshowDanger(!showDanger);
  };

  const handleSubmit = () => {
    setisSave(true);
    db.add("galleryDishes", { imageUrl: imageUrl, categories: listCategories })
      .then((res) => {
        setlistCategories([]);
        setuploadImageProgress(0);
        setimageUrl("");
        setisSave(false);
        handleShowSuccess();
        toggle();
      })
      .catch((err) => {
        setisSave(false);
        handleShowDanger();
      });
  };

  const onDropImage = (files) => {
    setisUpload(true);
    let uploadTask = firebase
      .storage()
      .ref()
      .child("galleryDishes/" + Date.now())
      .put(files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        setuploadImageProgress(progress);
      },
      (error) => {
        setisUpload(false);
        console.log("Erreur d'upLoad");
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log(downloadURL);
          setimageUrl(downloadURL);
          setisUpload(false);
        });
      }
    );
  };
  return (
    <React.Fragment>
      <Modal isOpen={isOpen} className="new-modal--plan-image">
        <Form>
          <ModalHeader toggle={toggle}>Nouvelle image</ModalHeader>
          <ModalBody>
            <Label className="my-0 font-weight-bold" size="sm">
              Image{" "}
              {imageUrl !== "" && (
                <FontAwesomeIcon icon={faCheck} className="text-success" />
              )}
            </Label>
            <div className="img-div">
              {isUpload ? (
                // <div className="spinner-upload-div">
                <Spinner color="light" className="spinner-loading mb-4" />
              ) : (
                /* </div> */
                <img
                  src={
                    imageUrl !== ""
                      ? imageUrl
                      : "/assets/images/placeholder.png"
                  }
                  width="100%"
                />
              )}
            </div>
            <Progress
              className="my-1"
              color="success"
              value={uploadImageProgress}
              size="sm"
              style={{ height: "4px" }}
            />
            <Dropzone
              onDrop={onDropImage}
              accept="image/png,image/jpeg,image/gif"
              multiple={false}
            >
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragReject,
              }) => (
                <div
                  {...getRootProps()}
                  style={{ width: "80px" }}
                  className="avatar-form-author"
                >
                  <input
                    {...getInputProps()}
                    disabled={isSave || isUpload ? true : false}
                  />
                  <div className="parcourir-btn-file-upload py-0 my-1">
                    Parcourir
                  </div>
                </div>
              )}
            </Dropzone>
            <Label className="my-0 font-weight-bold" size="sm">
              Catégorie (s)
            </Label>
            <Row form className="list-categories-added">
              {listCategories.map((category) => (
                <div
                  key={category}
                  className="list-categories-added-item mr-1 px-3 mb-1"
                >
                  <span>{category}</span>
                  <Button
                    close
                    className="ml-1"
                    size="sm"
                    disabled={isSave ? true : false}
                    onClick={deleteFromListCategories.bind(this, category)}
                  />
                </div>
              ))}

              <UncontrolledButtonDropdown direction="down">
                <DropdownToggle
                  className="btn-add-category px-3"
                  caret
                  size="sm"
                  disabled={isSave ? true : false}
                >
                  <FontAwesomeIcon icon={faPlusCircle} /> Ajouter{" "}
                </DropdownToggle>
                <DropdownMenu className="py-0">
                  {categories &&
                    categories.map((category) => (
                      <DropdownItem
                        key={category.id}
                        onClick={addToListCategories.bind(
                          this,
                          category.category
                        )}
                      >
                        {category.category}
                      </DropdownItem>
                    ))}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </Row>
          </ModalBody>
          <ModalFooter className="text-right footer-modal-new-plan-img">
            <Button
              className="font-weight-bold px-3"
              size="sm"
              color="success"
              disabled={isSave ? true : false}
              onClick={handleSubmit}
            >
              {!isSave ? (
                "Enregistrer"
              ) : (
                <Spinner color="light" className="spinner-isSaving" />
              )}
            </Button>
            <Button
              className="font-weight-bold px-3"
              size="sm"
              disabled={isSave ? true : false}
              onClick={toggle}
            >
              Annuler
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <ToastMessage
        isShow={showSuccess}
        toggle={handleShowSuccess}
        message="Enregistré"
        delay={4000}
        icon="check"
        className="bg-success text-white pr-5 mr-3"
      />
      <ToastMessage
        isShow={showDanger}
        toggle={handleShowDanger}
        message="Ouf, une erreur est survenue!"
        delay={5000}
        icon="check"
        className="bg-danger text-white pr-5"
      />
    </React.Fragment>
  );
};

export default NewPlanImage;
