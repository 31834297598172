import React from "react";
import {
  Modal,
  ModalBody,
  Alert,
  ModalFooter,
  Button,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
function ModalDeleteDishImage({
  isOpen,
  deleteDish,
  dish,
  setisModalDeleteDish,
  isDeleting,
}) {
  const toggle = () => {
    setisModalDeleteDish(false);
  };

  return (
    <Modal isOpen={isOpen} centered>
      <ModalHeader className="py-1" toggle={toggle}>
        Alert
      </ModalHeader>
      <ModalBody style={{ display: "flex" }}>
        <img src={dish && dish.imageUrl} width="100px" height="80px" />
        <div className="ml-2">
          <h5 className="font-weight-normal">
            Voulez-vous supprimer cette image?
          </h5>
          <Alert color="info" className="" style={{ fontSize: "0.8rem" }}>
            <FontAwesomeIcon icon={faInfoCircle} /> En supprimant l'image, elle
            ne sera plus accessible!
          </Alert>
        </div>
      </ModalBody>
      <ModalFooter className="py-1 ">
        <Button
          size="sm"
          color="success"
          onClick={dish && deleteDish.bind(this, dish.id)}
          className="font-weight-bold px-3"
          disabled={isDeleting ? true : false}
        >
          {isDeleting && (
            <Spinner
              style={{ height: "16px", width: "16px" }}
              className="mr-1"
            />
          )}
          Oui
        </Button>
        <Button
          size="sm"
          onClick={toggle}
          className="font-weight-bold px-3"
          disabled={isDeleting ? true : false}
        >
          Non
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalDeleteDishImage;
