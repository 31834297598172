import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartArrowDown,
  faTimes,
  faUtensils,
  faCaretDown,
 faInfoCircle,
  faPrint,
  faMapMarkerAlt,
  faBiking,
  faWrench, faCheck
} from "@fortawesome/free-solid-svg-icons";
import { ListGroup, ListGroupItem, Modal, Badge, Spinner, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert} from "reactstrap";
import moment from "moment";
import "moment/locale/fr";
import {useReactToPrint} from "react-to-print"
import {
  useFirestoreConnect,
  useFirestore,
  isEmpty,
  isLoaded,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import EmptyDataList from "../loadingIndicator/emptyDataList";
import LoadingIndicator from "../loadingIndicator/loadingIndicator";
import {useForm} from "react-hook-form"
import TimerOrderDelivery from "./timerOrderDelivery";
import Bill from "../orders/bill";
import {isValidURL} from "../../utils"

function FreeBikerItem({ biker, order, toggle, toggleFreeBikers }){
  const db = useFirestore();
  const [isDelegating, setisDelegating] = useState(false);
  const notifySuccess = (message) => toast.success(message);

  const setOrderStatus = (status) => {
    setisDelegating(true);
    db.update(`orders/${order.id}`, {
      status,
      rider: { ...biker, uid: biker.id },
    })
      .then((res) => {
        setisDelegating(false);
        notifySuccess(`Commande #${order.orderNumber} à cours livraison`);
        toggleFreeBikers();
        toggle();
      })
      .catch((err) => {
        setisDelegating(false);
      });
  };

  return (
    <div
      className="card-ready-biker"
      onClick={setOrderStatus.bind(this, "delegated")}
    >
      {!isDelegating?<img src={biker.avatar} alt=""/>:<Spinner className="spinner"/>}
      <div>
        <h6>{biker.name}</h6>
        <p>{biker.phone}</p>
      </div>
    </div>
  );
};

function PreparingOrderItem({ order }) {
  useFirestoreConnect(() => [
    {
      collection: "users",
      where: [
        ["city", "==", localStorage.getItem("city")],
        ["allowed", "==", true],
        ["status", "==", "free"],
      ],
      storeAs: "freeBikers",
    },
  ]);
  const db =useFirestore()
 

  
  const {register, handleSubmit} =useForm()
  const [orderItems, setorderItems] = useState([]);
  const [isOpen, setisOpen] = useState(false);
  const [isPrinting, setisPrinting] = useState(false)
  const [isOpenOnlineBikers, setisOpenOnlineBikers] = useState(false);
  const [isOpenRejectModal, setisOpenRejectModal] = useState(false)
  const [isRejecting, setisRejecting] = useState(false)
  const [closeAll, setCloseAll] = useState(false);
  const freeBikers = useSelector((state) => state.firestore.ordered.freeBikers);
  const billRef = useRef()

  const toggle = () => {
    setisOpen((prev) => !prev);
  };

  const toggleFreeBikers = () => {
    setisOpenOnlineBikers((prev) => !prev);
  };

  const toggleRejectOrderModal = () => {
    setisOpenRejectModal((prev) => !prev);
  };
  const printReal = useReactToPrint({content: () => billRef.current, onAfterPrint:()=>setisPrinting(false)})
  const notifySuccess=(message)=>toast.success(message)
  const printBill=()=>{
    setisPrinting(true)
    setTimeout(()=>{
      printReal()
    }, 100)
  }

  const rejectOrder = (data) => {
    setisRejecting(true)
    db.update(`orders/${order.id}`, {
      status: "rejected",
      rejectReason: data.rejectReason + ". " + data.info,
    })
      .then((res) => {
        setisRejecting(false)
        notifySuccess(`Commande #${order.orderNumber} annulée`);
        toggleRejectOrderModal();
        toggle();
      })
      .catch((err) => {setisRejecting(false)});
  };

  useEffect(() => {
    let orderItems = [];
    
    order.package.forEach((item) => {
      orderItems.push(JSON.parse(item));
    });
    setorderItems(orderItems);
   
    return () => {};
  }, []);

  return (
    <ListGroupItem className="order-item" onClick={toggle}>
      <div className="order-info">
        <div className="div-icon">
          <FontAwesomeIcon icon={faUtensils} />
        </div>
        <div className="">
          <h6>
            #{order.orderNumber} <Badge className={`badge ${order.payed && "paid"}`}>{order.payed ? "Payée":"Non payée"}</Badge>
          </h6>
          <p>le {moment(order.createdAt).format("DD-MM-YYYY à HH:mm")}</p>
        </div>
      </div>
      {/* <div className="order-user">
        <img src="/assets/images/avatar.jpg" />
        <div>
          <h6>{order.user.name}</h6>
          <p>{order.user.phone}</p>
        </div>
      </div> */}
      <TimerOrderDelivery createdAt={order.createdAt}/>
      <Modal
        isOpen={isOpen}
        size="md"
        centered
        contentClassName="modal-content"
        backdropClassName="modal-backdrop"
      >
        <div className="modal-header">
          <h5>
            <FontAwesomeIcon icon={faUtensils} /> En cours de préparation <Badge className={`badge-payement ${order.payed && "paid"}`}>{order.payed ? "Payée":"Non payée"}</Badge>
          </h5>
          <button onClick={toggle}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="modal-body">
          <div className="body-content-header">
            <div className="client-content">
              <img src={`${isValidURL(order.user.avatar)?order.user.avatar:"/assets/images/avatar.png"}`} alt="" />
              <div>
                <h6>{order.user.name}</h6>
                <p className="badge-phone-number">{order.user.phone}</p>
              </div>
            </div>
            <div className="order-info">
              <h6>
                <Badge className="badge">#{order.orderNumber}</Badge>
              </h6>
              <p>Le {moment(order.createdAt).format("DD-MM-YYYY")}</p>
            </div>
          </div>
          <ListGroup className="body-content-body" flush>
            <h6>
              <FontAwesomeIcon icon={faCartArrowDown} className="icon-cart" />
              Panier
            </h6>
            <div className="list-of-items">
              {orderItems.map((item) => (
                <ListGroupItem key={item.product.name} className="cart-item">
                  <span className="quantity">{item.count}</span>
                  <span className="product-name">{item.product.name}</span>
                  <span className="price">
                    {item.product.price * item.count}
                  </span>
                  <span className="currency">Fc</span>
                </ListGroupItem>
              ))}
            </div>
            <ListGroupItem className="card-prices">
              <div className="row-price product-div-price">
                <span className="price-text">Produit:</span>
                <span className="price-value">{order.totalPrice} Fc</span>
              </div>
              <div className="row-price delivery-div-price">
                <span className="price-text">Frais de livraison: </span>
                <span className="price-value">{order.deliveryCost??0} Fc</span>
              </div>
              <div className="row-price total-div-price">
                <span className="price-text">Total:</span>
                <span className="price-value">{order.totalPrice+(order.deliveryCost??0)} Fc</span>
              </div>
            </ListGroupItem>
          </ListGroup>
          <div className="card-delivery-address">
            <h6>
              <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
              Adresse de livraison
            </h6>
            <p>{order.user.address}</p>
          </div>

          <Modal
            isOpen={isOpenOnlineBikers}
            size="md"
            onClosed={closeAll ? toggle : undefined}
          >
            <div className="modal-header">
              <h5>
                <FontAwesomeIcon icon={faBiking} /> Livreurs en ligne
              </h5>
              <button onClick={toggleFreeBikers}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <div className="modal-body">
              <p>Un seul peut servir</p>
              <div className="row">
                {!isLoaded(freeBikers) ? (
                  <LoadingIndicator />
                ) : isEmpty(freeBikers) ? (
                  <EmptyDataList message="Aucun liveur n'est dispobible!" />
                ) : (
                  freeBikers.map((biker) => (
                    <div className="col-4" key={biker.id}>
                      <FreeBikerItem biker={biker} order={order} toggle={toggle} toggleFreeBikers={toggleFreeBikers}/>
                    </div>
                  ))
                )}
              </div>
            </div>
            <div className="modal-footer"></div>
          </Modal>
        </div>
        <Modal isOpen={isOpenRejectModal}>
          <form onSubmit={handleSubmit(rejectOrder)}>
            <div className="modal-header">
              <h5>
                <FontAwesomeIcon icon={faTimes} /> Annuler la commande
              </h5>
              <button onClick={toggleRejectOrderModal} disabled={isRejecting}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <div className="modal-body">
              <Alert color="info">
                <FontAwesomeIcon icon={faInfoCircle} /> Il faut avoir une bonne
                raison d'annuler une commande. Veillez fournir la{" "}
                <span className="font-weight-bold text-dark">raison</span> afin
                de notifier le client{" "}
                <span className="font-weight-bold text-dark">{order.name}</span>{" "}
                de l'annulation de sa commande.
              </Alert>

              <div className="div-input">
                <label>Raison d'annulation</label>
                <select name="rejectReason" ref={register({ required: true })}>
                  <option></option>
                  <option value="L'adresse de livraison hors zone">
                    Adresse de livraison hors zone d'intervention
                  </option>
                  <option value="L'adresse de livraison n'est pas précise">
                    Adresse de livraison non precise
                  </option>
                  <option value="Produit non disponible en stock">
                    Produit non disponible en stock
                  </option>
                  <option value="Le climat est non favorable">
                    Climat non favorable.
                  </option>
                  <option value="Le client est non sûr et mal noté">
                  Client non sûr et mal noté
                  </option>
                </select>
              </div>
              <div className="div-input">
                <label>Autre précision</label>
                <textarea name="info" ref={register} />
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" disabled={isRejecting}>{!isRejecting ? <span><FontAwesomeIcon icon={faCheck}/> Confirmer l'annulation</span> :<Spinner className="spinner"/>}</button>
            </div>
          </form>
        </Modal>
        <div className="modal-footer">
          <button onClick={toggleFreeBikers}>
            <FontAwesomeIcon icon={faBiking} className="icon" />
            Déléguer
          </button>
          <button className="btn-cancel" onClick={printBill}><FontAwesomeIcon icon={faPrint}/></button>
          {/* <button className="btn-cancel">
            <FontAwesomeIcon icon={faCaretDown} />
          </button> */}
          <UncontrolledDropdown direction="down">
            <DropdownToggle  className="btn-cancel">
            <FontAwesomeIcon icon={faCaretDown} />
            </DropdownToggle>
            <DropdownMenu className="dropmenu-container">
              <DropdownItem className="btn-drop-item" onClick={notifySuccess.bind(this,"Oops! Fonctionalité à venir!")}><FontAwesomeIcon icon={faWrench} className="mr-1"/> Ajuster le prix</DropdownItem>
              <DropdownItem className="btn-drop-item" onClick={toggleRejectOrderModal}><FontAwesomeIcon icon={faTimes} className="mr-1"/> Annuler la commande</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        {isPrinting&&<Bill ref={billRef} order={order} orderItems={orderItems}/>}
      </Modal>
    </ListGroupItem>
  );
}

export default PreparingOrderItem;
