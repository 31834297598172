import React, { useState } from "react";
import "./upbar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import {
  faBars,
  faPlus,
  faBell,
  faCaretDown,
  faSignOutAlt,
  faUser,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useFirebase } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactComponent as Mango } from "../sidebar/mango.svg";
function Upbar({ setisShowSidebar, isShowSidebar }) {
  const firebase = useFirebase();
  const history = useHistory();
  const [isPopoverProfile, setisPopoverProfile] = useState(false);
  const profile = useSelector(({ firebase: { profile } }) => profile);

  const toggleSidebar = () => {
    setisShowSidebar((prev) => !prev);
  };

  const togglePopoverProfile = () => {
    setisPopoverProfile((prev) => !prev);
  };

  const logOut = () => {
    firebase.logout().then((res) => {
      localStorage.removeItem("city");
      history.push("/");
    });
  };

  return (
    <header className={`${isShowSidebar && "hide"}`}>
      <button className="btn-hamburger" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={faBars} className="icon" />
      </button>
      {isShowSidebar && <Mango height="24px" />}
      {isShowSidebar && (
        <h1>
          Tayari<span>food</span>
        </h1>
      )}
      <div className="empty-space" />
      <div className="div-left">
        {/* <button><FontAwesomeIcon icon={faPlus}/></button>
        <button><FontAwesomeIcon icon={faBell}/></button> */}
        {/* <button>
          <FontAwesomeIcon icon={faCaretDown} />
        </button> */}
        {/* <div
          className="img-avatar"
          id="popover-profile"
          onClick={togglePopoverProfile}
        >
          <div className="img"><FontAwesomeIcon icon={faUser}/></div>
          <p>{profile.role === "agent" ? "Agent" : "Admin"}</p>
        </div> */}
        {/* <Popover
          placement="bottom"
          isOpen={isPopoverProfile}
          target="popover-profile"
          toggle={togglePopoverProfile}
        >
          <PopoverHeader>{profile.name}</PopoverHeader>
          <PopoverBody>
            <button onClick={logOut} className="btn-deconnect">
              <FontAwesomeIcon icon={faSignOutAlt} /> Déconnecter
            </button>
          </PopoverBody>
        </Popover> */}
      </div>
    </header>
  );
}

export default Upbar;
