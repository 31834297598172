import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore' // <- needed if using firestore
import { createStore, combineReducers, compose } from 'redux'
import {firebaseReducer } from 'react-redux-firebase'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore' // <- needed if using firestore

const firebaseConfig = {
  apiKey: "AIzaSyC0ML-XNXoiNU1CnsyLl_wTAQB2HDuLeBc",
  authDomain: "tayarifood.firebaseapp.com",
  databaseURL: "https://tayarifood.firebaseio.com",
  projectId: "tayarifood",
  storageBucket: "tayarifood.appspot.com",
  messagingSenderId: "650916852078",
  appId: "1:650916852078:web:7e347f149791443c31917f",
  measurementId: "G-BM4FCP8ZCM"
}

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true
}

// Initialize firebase instance
firebase.initializeApp(firebaseConfig)

// Initialize other services on firebase instance
firebase.firestore() // <- needed if using firestore

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer // <- needed if using firestore
})

// Create store with reducers and initial state
const initialState = {}
export const store = createStore(rootReducer, initialState)

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
}
