import React from 'react'

function Security() {
    return (
        <div className="container-settings-security">
            
        </div>
    )
}

export default Security
