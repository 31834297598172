import React from "react";
import "./clients.scss";

import ListClients from "./clientsList";

function Clients() {
  return (
    <div>
      <ListClients />
    </div>
  );
}

export default Clients;
