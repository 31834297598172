import React from 'react'
import './burgerButton.scss'

const burgerButton = (props)=>{
    return (
        <div className="burger-btn mx-3" onClick={props.toggleSidebar}>
            <div className="burger-btn-bar"/>
            <div className="burger-btn-bar"/>
            <div className="burger-btn-bar"/>
        </div>
    )
}

export default burgerButton
