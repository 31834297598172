import React, { useState } from "react";
import { ListGroupItem, CustomInput, Modal, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import {useFirestore} from "react-redux-firebase"
import {toast} from "react-toastify"
import { isValidURL } from "../../utils";

function ClientItem({ client }) {
  const db =useFirestore()
  const [isOpen, setisOpen] = useState(false);
  const [isSave, setisSave] = useState(false)

  const toggle = () => {
    setisOpen((prev) => !prev);
  };

  const notifySuccess=(message)=> toast.success(message)

  const allowUserToPay = () => {
    setisSave(true);
    db.update(`users/${client.id}`, {
      cashOnDelivery: client.cashOnDelivery ? false : true,
    })
      .then((res) => {
        setisSave(false);
        notifySuccess(`Client ${client.name} ${client.cashOnDelivery ? "bloqué de payer à la livraison" : " autorisé à payer à la livraison"}`);
        toggle();
      })
      .catch((err) => {
        setisSave(false);
      });
  };

  return (
    <ListGroupItem className="client-item" onClick={toggle}>
      <CustomInput
        type="checkbox"
        id={`header-${client.id}`}
        className="mr-3"
      />
      <div className="name">
        <img
          src={`${
            isValidURL(client.avatar) ? client.avatar : "/assets/images/avatar.png"
          }`}
          alt=""
        />
        {client.name}
      </div>
      <div className="phoneNumber">
        <p className="badge">{client.phone}</p>
      </div>
      <div className="city">
        <p className="badge client">{`${client.address.slice(0, 20)} ${
          client.address.length > 20 ? "..." : ""
        }`}</p>
      </div>
      <Modal isOpen={isOpen} centered>
        <div className="modal-header">
          <h5>Client</h5>{" "}
          <button onClick={toggle} disabled={isSave}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="modal-body">
          <div className="div-user-details-info">
            <img
              src={`${
                isValidURL(client.avatar)
                  ? client.avatar
                  : "/assets/images/avatar.png"
              }`}
              alt=""
            />
            <div>
              <h6>{client.name}</h6>
              <p>{client.phone}</p>
            </div>
          </div>
          <div className="div-user-details-address">
            <h6>Adresse {client.city}</h6>
            <p>{client.address}</p>
          </div>
          <div className="div-user-details-payment">
            <h6>Paiement</h6>
            {client.cashOnDelivery?<p>Ce client peut payer à la livraison</p>:<p>Ce client ne peut pas payer à la livraison</p>}
          </div>
        </div>
        <div className="modal-footer">
          <button onClick={allowUserToPay} disabled={isSave}>
            {isSave?<Spinner className="spinner mr-1"/>:<FontAwesomeIcon icon={faCheck} className="icon" />}
            {client.cashOnDelivery?"Bloquer les paiements à la livraison":"Autoriser les paiements à la livraison"}
          </button>
          <button disabled={isSave} className="btn-cancel" onClick={toggle}><FontAwesomeIcon icon={faTimes}/> Fermer</button>
        </div>
      </Modal>
    </ListGroupItem>
  );
}

export default ClientItem;
