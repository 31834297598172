import React, {useState} from "react";
import { useFirestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import NewSellerForm from "./newSellerForm";

import "./styles.scss"
import SellerItem from "./sellerItem";
import { Badge } from "reactstrap";

function Sellers() {
  useFirestoreConnect(() => [
    {
      collection: "sellers",
      orderBy: ["name", "asc"],
    },
  ]);

  const [isOpenNewSellerForm, setisOpenNewSellerForm] = useState(false)

  const sellers = useSelector((state) => state.firestore.ordered.sellers);

  const toggleNewSellerForm=()=>{
      setisOpenNewSellerForm(prev => !prev)
  }
  return (
    <div className="container-sellers">
        <div className="sellers-header">
        <h4><Badge>{sellers&&sellers.length}</Badge> Vendeurs & Fournisseurs</h4>
        <button onClick={toggleNewSellerForm}>Créer</button>
        </div>
        <div className="container-fluid">
      <div className="row mt-2">
        {!isLoaded(sellers) ? (
          "Chargement en cours ..."
        ) : isEmpty(sellers) ? (
          "Aucun vendeur"
        ) : 
         
            sellers.map(seller=>(
                <div key={seller.id} className="col-6 col-md-3 col-sm-4">
                    <SellerItem seller={seller}/>
                </div>
            ))
        }
      </div>
      </div>
      <NewSellerForm isOpen={isOpenNewSellerForm} toggle={toggleNewSellerForm}/>
    </div>
  );
}

export default Sellers;
