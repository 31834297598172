import React, { useState } from "react";
import {
  ListGroupItem,
  ListGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  CustomInput,
} from "reactstrap";
import { useForm } from "react-hook-form";
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
  isEmpty,
} from "react-redux-firebase";
import { useSelector } from "react-redux";

const NewCityForm = ({ isOpen, toggle }) => {
  const db = useFirestore();
  const { register, handleSubmit } = useForm();
  const [isSave, setisSave] = useState(false);

  const saveToDB = (data) => {
    setisSave(true);
    db.add("cities", {
      ...data,
      available: false,
      createdAt: db.Timestamp.now().toMillis(),
    })
      .then((res) => {
        setisSave(false);
        toggle();
      })
      .catch((err) => {
        setisSave(false);
      });
  };

  return (
    <Modal isOpen={isOpen} centered className="modal-new-unit">
      <form onSubmit={handleSubmit(saveToDB)}>
        <ModalHeader>Nouvelle ville</ModalHeader>
        <ModalBody className="modal-new-unit-body">
          <div className="div-input">
            <label>Nom de la ville</label>
            <input
              type="text"
              name="city"
              ref={register({ required: true })}
              disabled={isSave}
            />
          </div>
        </ModalBody>
        <ModalFooter className="modal-new-unit-footer">
          <button type="submit" className="btn-save" disabled={isSave}>
            {isSave ? <Spinner className="spinner" /> : "Sauvegarder"}
          </button>
          <button onClick={toggle} disabled={isSave}>
            Annuler
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

function Cities() {
  useFirestoreConnect(() => [
    { collection: "cities", orderBy: ["city", "asc"] },
  ]);
  const [isOpenNewCityForm, setisOpenNewCityForm] = useState(false);
  const cities = useSelector((state) => state.firestore.ordered.cities);
  const toggleNewCityForm = () => {
    setisOpenNewCityForm((prev) => !prev);
  };
  return (
    <div className="units-container">
      <div className="units-container-header">
        <h4>Villes</h4>
        <button onClick={toggleNewCityForm}>Créer</button>
      </div>
      <ListGroup className="list-units">
        <ListGroupItem className="unit-item header">
          <CustomInput
            type="checkbox"
            id="header-check-city"
            className="mr-3"
          />
          <div className="unit">Ville</div>
          <div className="category">store</div>
        </ListGroupItem>
        {!isLoaded(cities) ? (
          <div className="loading-data-indicator">
            <p>En cours de chargement ...</p>
          </div>
        ) : isEmpty(cities) ? (
          <div className="empty-list-data">
            <p>Aucune ville trouvée!</p>
          </div>
        ) : (
          cities.map((city) => (
            <ListGroupItem key={city.id} className="unit-item py-1">
              <CustomInput
                type="checkbox"
                name="userCondition"
                className="mr-3"
                id={`check${city.id}`}
              />
              <div className="unit">{city.city}</div>
              <div className="category">{city.available ? "Oui" : "Non"}</div>
            </ListGroupItem>
          ))
        )}
      </ListGroup>

      <NewCityForm isOpen={isOpenNewCityForm} toggle={toggleNewCityForm} />
    </div>
  );
}

export default Cities;
