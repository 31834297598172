import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartArrowDown,
  faTimes,
  faCheck,
  faMapMarkerAlt,
  faInfoCircle,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { ListGroup, ListGroupItem, Modal, Badge, Alert, Spinner } from "reactstrap";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import moment from "moment";
import "moment/locale/fr";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {useReactToPrint} from "react-to-print"
import {useParams} from "react-router-dom"
import TimerOrderDelivery from "./timerOrderDelivery";
import Bill from "../orders/bill";
import {isValidURL} from "../../utils"


function NewOrderItem({ order }) {
  const db = useFirestore();
  const {storeId} = useParams()

  useFirestoreConnect(() => [
    {
      collection: "stores",
      doc: storeId,
    },
  ]);
  const { register, handleSubmit } = useForm();
  const [isOpen, setisOpen] = useState(false);
  const [isPrinting, setisPrinting] = useState(false)
  const [isOpenRejectModal, setisOpenRejectModal] = useState(false);
  const [orderItems, setorderItems] = useState([]);
  const [isAccepting, setisAccepting] = useState(false)
  const [isRejecting, setisRejecting] = useState(false)
  const billRef= useRef()


  const notifySuccess = (message) => toast.success(message);

  const printReal = useReactToPrint({content: () => billRef.current, onAfterPrint:()=>setisPrinting(false)})

  const printBill=()=>{
    setisPrinting(true)
    setTimeout(()=>{
      printReal()
    }, 100)
  }

  const toggle = () => {
    setisOpen((prev) => !prev);
  };
  const toggleRejectOrderModal = () => {
    setisOpenRejectModal((prev) => !prev);
  };

  const setOrderStatus = (status) => {
    setisAccepting(true)
    db.update(`orders/${order.id}`, {
      status,
      timeBefore: order.createdAt + localStorage.getItem("deliveryMinTime"),
    })
      .then((res) => {
        setisAccepting(false)
        notifySuccess(`Commande #${order.orderNumber} acceptée`);
        toggle();
      })
      .catch((err) => {setisAccepting(false)});
  };
  const rejectOrder = (data) => {
    setisRejecting(true)
    db.update(`orders/${order.id}`, {
      status: "rejected",
      rejectReason: data.rejectReason + ". " + data.info,
    })
      .then((res) => {
        setisRejecting(false)
        notifySuccess(`Commande #${order.orderNumber} rejetée`);
        toggleRejectOrderModal();
        toggle();
      })
      .catch((err) => {setisRejecting(false)});
  };

  useEffect(() => {
    let orderItems = [];
    order.package.forEach((item) => {
      orderItems.push(JSON.parse(item));
    });
    setorderItems(orderItems);
    return () => {};
  }, []);

  return (
    <ListGroupItem className="order-item grid" onClick={toggle}>
      <div className="order-info">
        {/* <div className="div-icon">
          <FontAwesomeIcon icon={faCartArrowDown} />
        </div> */}
        <TimerOrderDelivery createdAt={order.createdAt}/>
        <div className="ml-3">
          <h6>
            #{order.orderNumber} <Badge className={`badge ${order.payed && "paid"}`}>{order.payed ? "Payée":"Non payée"}</Badge>
          </h6>
          <p>le {moment(order.createdAt).format("DD-MM-YYYY à HH:mm")}</p>
        </div>
      </div>
      {/* <div className="order-user">
        <img src="/assets/images/avatar.jpg" />
        <div>
          <h6>{order.user.name}</h6>
          <p>{order.user.phone}</p>
        </div>
      </div> */}
      
      <Modal
        isOpen={isOpen}
        size="md"
        centered
        contentClassName="modal-content"
        backdropClassName="modal-backdrop"
      >
        <div className="modal-header">
          <h5>Nouvelle commande <Badge className={`badge-payement ${order.payed && "paid"}`}>{order.payed ? "Payée":"Non payée"}</Badge></h5>
          <button onClick={toggle} disabled={isAccepting}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="modal-body">
          <div className="body-content-header">
            <div className="client-content">
              <img src={`${isValidURL(order.user.avatar) ? order.user.avatar : "/assets/images/avatar.png"}`} alt="" />
              <div>
                <h6>{order.user.name}</h6>
                <p className="badge-phone-number">{order.user.phone}</p>
              </div>
            </div>
            <div className="order-info">
              <h6>
                <Badge className="badge">#{order.orderNumber}</Badge>
              </h6>
              <p>Le {moment(order.createdAt).format("DD-MM-YYYY")}</p>
            </div>
          </div>
          <ListGroup className="body-content-body" flush>
            <h6>
              <FontAwesomeIcon icon={faCartArrowDown} className="icon-cart" />
              Panier
            </h6>
            <div className="list-of-items">
              {orderItems.map((item) => (
                <ListGroupItem key={item.product.name} className="cart-item">
                  <span className="quantity">{item.count}</span>
                  <span className="product-name">{item.product.name}</span>
                  <span className="price">
                    {item.product.price * item.count}
                  </span>
                  <span className="currency">Fc</span>
                </ListGroupItem>
              ))}
            </div>
            <ListGroupItem className="card-prices">
              <div className="row-price product-div-price">
                <span className="price-text">Produit:</span>
                <span className="price-value">{order.totalPrice} Fc</span>
              </div>
              <div className="row-price delivery-div-price">
                <span className="price-text">Frais de livraison: </span>
                <span className="price-value">{order.deliveryCost??0} Fc</span>
              </div>
              <div className="row-price total-div-price">
                <span className="price-text">Total:</span>
                <span className="price-value">{order.totalPrice+(order.deliveryCost??0)} Fc</span>
              </div>
            </ListGroupItem>
          </ListGroup>
          <div className="card-delivery-address">
            <h6>
              <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
              Adresse de livraison
            </h6>
            <p>{order.user.address}</p>
          </div>
        </div>
        <Modal isOpen={isOpenRejectModal}>
          <form onSubmit={handleSubmit(rejectOrder)}>
            <div className="modal-header">
              <h5>
                <FontAwesomeIcon icon={faTimes} /> Refus d'une commande
              </h5>
              <button onClick={toggleRejectOrderModal} disabled={isRejecting}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <div className="modal-body">
              <Alert color="danger">
                <FontAwesomeIcon icon={faInfoCircle} /> Il faut avoir une bonne
                raison de refuser une commande. Veillez fournir la{" "}
                <span className="font-weight-bold text-dark">raison</span> afin
                de notifier le client{" "}
                <span className="font-weight-bold text-dark">{order.name}</span>{" "}
                du refus de sa commande.
              </Alert>

              <div className="div-input">
                <label>Raison</label>
                <select name="rejectReason" ref={register({ required: true })}>
                  <option></option>
                  <option value="L'adresse de livraison hors zone">
                    Adresse de livraison hors zone d'intervention
                  </option>
                  <option value="Produit non disponible en stock">
                    Produit non disponible en stock
                  </option>
                  <option value="L'adresse de livraison n'est pas précise">
                    Adresse de livraison n'est pas precise
                  </option>
                </select>
              </div>
              <div className="div-input">
                <label>Autre précision</label>
                <textarea name="info" ref={register} />
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" disabled={isRejecting}>{!isRejecting ? "Exécuter" :<Spinner className="spinner"/>}</button>
            </div>
          </form>
        </Modal>
        <div className="modal-footer">
          <button onClick={setOrderStatus.bind(this, "accepted")} disabled={isAccepting}>
            {!isAccepting ? <FontAwesomeIcon icon={faCheck} className="icon" />:<Spinner className="spinner"/>}
            Accepter
          </button>
          <button className="btn-cancel" onClick={toggleRejectOrderModal} disabled={isAccepting}>
            <FontAwesomeIcon icon={faTimes} className="icon" />
            Rejeter
          </button>
          <button className="btn-cancel" onClick={printBill}><FontAwesomeIcon icon={faPrint}/></button>
        </div>
        {isPrinting&& <Bill ref={billRef} order={order} orderItems={orderItems} />}
      </Modal>
    </ListGroupItem>
  );
}

export default NewOrderItem;
