import React, { useState } from "react";
import {useParams} from "react-router-dom";
import { useForm } from "react-hook-form";
import { Spinner,Modal } from "reactstrap";
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
  isEmpty,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoadingIndicator from "../loadingIndicator/loadingIndicator";
import EmptyDataList from "../loadingIndicator/emptyDataList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios"

function Payment() {

  const {storeId} = useParams()

  useFirestoreConnect(() => [
    {
      collection: "stores",
      doc: storeId,
    },
  ]);

  const [isOpenModal, setisOpenModal] = useState(false);
  const [isOpenViewSales, setisOpenViewSales] = useState(false);
  const db = useFirestore();
  const { register, handleSubmit } = useForm();
  const { register: register1, handleSubmit: handleSubmit1 } = useForm();
  const [isSave, setisSave] = useState(false);
  const [isGetSales, setisGetSales] = useState(false);
  const [salesValue, setsalesValue] = useState();


  const store = useSelector(
    ({ firestore: { data } }) =>
      data.stores && data.stores[storeId]
  );
  const notifySuccess = (message) => toast.success(message);
  
  const toggleModal = () => {
    setisOpenModal((prev) => !prev);
  };

  const toggleViewSalesValue = () => {
    setisOpenViewSales((prev) => !prev);
    setsalesValue();
  };
  
  const saveToDB = (data) => {
    setisSave(true);
    db.update(`stores/${storeId}`, {
      dlpsNumber:data.dlpsNumber,
      deliveryCost: parseInt(data.deliveryCost),
      minAmount:parseInt(data.minAmount)
    })
      .then((res) => {
        notifySuccess("Sauvegardé");
        setisSave(false);
      })
      .catch((err) => {
        setisSave(false);
      });
  };

  const getCurrentSales = async (data) => {
    setisGetSales(true);
    try {
      const res = await axios.post(
        "https://dlp-service-5qvdijzm7q-ew.a.run.app/login",
        { phone: store.dlpsNumber, password: data.pin }
      );
      const res1 = await axios.get(
        `https://dlp-service-5qvdijzm7q-ew.a.run.app/api/accounts/${store.dlpsNumber}`,
        { headers: { Authorization: `Bearer ${res.data.token}` } }
      );
      if (res1.data) {
        setsalesValue(res1.data.balance);
        setisGetSales(false);
        setisOpenModal(false);
        setisOpenViewSales(true);
      }
    } catch (err) {
      setisGetSales(false);
      notifySuccess("Oops! Une erreur inattendue s'est produite. Veuillez réessayer!");
    }
  };

  return (
    <div className="container-fluid container-settings-category">
      {!isLoaded(store) ? (
        <LoadingIndicator />
      ) : isEmpty(store) ? (
        <EmptyDataList message="Rien à afficher!" />
      ) : (
        <form onSubmit={handleSubmit(saveToDB)}>
          <div className="form-container-content">
            <h3>Paramètres des paiements</h3>
            <div className="div-input">
              <label>Numéro de réception</label>
              <input
                type="text"
                name="dlpsNumber"
                defaultValue={store.dlpsNumber}
                ref={register({required:true, pattern: /\+243+([0-9]{9})/,})}
                disabled={isSave}
              />
            </div>
            <p className="view-balance-link" onClick={toggleModal}>
              Voir balance
            </p>
            <p className="edit-dlps-number-info">
              <FontAwesomeIcon icon={faInfoCircle} /> Si vous voulez changer le
              numéro de réception, veuillez contacter l'administrateur.
            </p>
            <div className="div-delivery-cost">
              <div className="div-input">
                <label>Frais de livraison</label>
                <input
                  type="number"
                  name="deliveryCost"
                  defaultValue={store.deliveryCost}
                  ref={register({ required: true })}
                  disabled={isSave}
                />
              </div>
              <label className="label-currency">CDF</label>
            </div>
            <div className="div-input">
                <label>Montant minimal d'une commande</label>
                <input
                  type="number"
                  name="minAmount"
                  defaultValue={store.minAmount}
                  ref={register({ required: true })}
                  disabled={isSave}
                />
              </div>
            <div className="text-center mt-4">
              <button
                type="submit"
                className="btn-fresh-login"
                disabled={isSave}
              >
                {isSave ? (
                  <Spinner className="spinner" />
                ) : (
                  <span>Sauvegarder</span>
                )}
              </button>
            </div>
          </div>
        </form>
      )}

<Modal isOpen={isOpenModal} centered size="sm">
        <form onSubmit={handleSubmit1(getCurrentSales)}>
          <div className="modal-header">
            <h5 className="">Vérification</h5>{" "}
            <button
              type="button"
              className=""
              onClick={toggleModal}
              disabled={isGetSales}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="modal-body">
            <div className="div-input">
              <input
                placeholder="Code PIN"
                disabled={isGetSales}
                name="pin"
                ref={register1({ required: true, minLength: 4, maxLength: 4 })}
              />
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="btn-fresh-login"
                disabled={isGetSales}
              >
                {isGetSales ? <Spinner /> : "Go"}
              </button>
            </div>
          </div>
        </form>
      </Modal>

      <Modal isOpen={isOpenViewSales} size="sm" centered>
        <div className="modal-header">
          <h5>Balance</h5>
        </div>
        <div className="modal-body">
          <div className="text-center">
            <h4>
              {salesValue} <span style={{fontWeight:"100", fontSize:"1.2rem"}}>CDF</span>
            </h4>
          </div>
          <div className="text-center mt-4">
            <button className="btn-fresh-login" onClick={toggleViewSalesValue}>
              OK
            </button>
          </div>
        </div>
      </Modal>

    </div>
  );
}

export default Payment;
