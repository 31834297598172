import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLayerGroup,
  faList,
  faTh,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory, useParams } from "react-router-dom";
import { useFirestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoadingIndicator from "../loadingIndicator/loadingIndicator";
import EmptyDataList from "../loadingIndicator/emptyDataList";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

function ListProducts() {
  const {storeId} =useParams()
  useFirestoreConnect(() => [
    {
      collection: "stores",
      doc: storeId,
      subcollections: [
        {
          collection: "products",
          where: ["isFresh", "==", true],
          orderBy: ["name", "asc"],
        },
      ],
      storeAs: "products",
    },
    {
      collection: "units",
      orderBy: ["unit", "asc"],
    },
    {
      collection: "freshCategories",
      orderBy: ["category", "asc"],
    },
  ]);
  const history = useHistory();
  const [searchString, setsearchString] = useState("")
  const products = useSelector((state) => state.firestore.ordered.products);
  const categories = useSelector(
    (state) => state.firestore.ordered.freshCategories
  );
  const [currentProducts, setcurrentProducts] = useState([]);
  const goTo = (path) => {
    history.push(path);
  };

  const notifyAsComingFeature = () =>
    toast.success("Oops! Fonctionalité à venir!");

  const onSearch = (e) => {
    const value = e.target.value.replace("+","");
    setsearchString(value)
    if (value != "") {
      const regexToSearch = RegExp(value, "i");
      const searchResult = products.filter(
        (product) =>
          product.name.match(regexToSearch) ||
          product.category.match(regexToSearch)
      );
      setcurrentProducts(searchResult);
    }else{
      setcurrentProducts(products)
    }
  };

  const searchByCategory = (category) => {
    setsearchString(category)
    const searchResult = products.filter((product) =>
      product.category.match(category)
    );
    setcurrentProducts(searchResult);
  };

  const viewAllProducts =()=>{
    products&&setcurrentProducts(products)
    setsearchString("Tous")
  }

  const viewUnCategorizedProducts=()=>{
    const searchResult = products.filter((product) =>
      product.category===""
    );
    setcurrentProducts(searchResult);
    setsearchString("Non categorisé")
  }

  useEffect(() => {
    products && setcurrentProducts(products);
    return () => {};
  }, [products]);

  return (
    <div className="container-list-products">
      <div className="container-list-products-header">
        <div className="div-left">
          <h3>Produits {products && `(${products.length})`}</h3>
          <div className="div-btns-actions">
            <button
              className="btn-create"
              onClick={goTo.bind(this, `/store/${storeId}/products/new`)}
            >
              Créer
            </button>
            <button onClick={notifyAsComingFeature}>Importer</button>
            <button onClick={notifyAsComingFeature}>Exporter</button>
          </div>
        </div>
        <div className="div-right">
          <div className="div-btns">
            <div className="filters-btn">
              {/* <button onClick={notifyAsComingFeature}>
                <FontAwesomeIcon icon={faFilter} className="left-icon" />
                Filtres <FontAwesomeIcon icon={faCaretDown} />
              </button> */}
              {/* <button onClick={notifyAsComingFeature}>
                <FontAwesomeIcon icon={faLayerGroup} className="left-icon" />
                Categorie <FontAwesomeIcon icon={faCaretDown} />
              </button> */}
              <UncontrolledDropdown>
                <DropdownToggle className="btn-dropdown-toggle" caret>
                  <FontAwesomeIcon icon={faLayerGroup} className="left-icon" />{" "}
                  Categorie
                </DropdownToggle>
                <DropdownMenu className="dropmenu-list">
                  
                  <DropdownItem className="category-item fixe" onClick={viewAllProducts}>Tous</DropdownItem>
                  <DropdownItem className="category-item fixe" onClick={viewUnCategorizedProducts}>Non categorisé</DropdownItem>
                  {categories &&
                    categories.map((category) => (
                      <DropdownItem
                        className="category-item"
                        onClick={searchByCategory.bind(this, category.category)}
                      >
                        {/* <FontAwesomeIcon icon={faObjectGroup}/> */}
                        <img src={category.imageUrl}/>{category.category}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <div>
              <button onClick={notifyAsComingFeature}>
                <FontAwesomeIcon icon={faList} />
              </button>
              <button onClick={notifyAsComingFeature}>
                <FontAwesomeIcon icon={faTh} />
              </button>
            </div>
          </div>
          <div className="search-bar">
            <form>
              <FontAwesomeIcon icon={faSearch} className="search-icon" />
              <input
                type="search"
                name="productToSearch"
                placeholder="Rechercher ici ..."
                onChange={onSearch}
                value={searchString}
              />
            </form>
          </div>
        </div>
      </div>
      <div className="container-list-products-body">
        <div className="container-fluid">
          <div className="row">
            {!isLoaded(products) ? (
              <LoadingIndicator />
            ) : isEmpty(products) ? (
              <EmptyDataList message="Aucun produit trouvé!" />
            ) : (
              currentProducts.length!=0?currentProducts.map((product) => (
                <div
                  key={product.id}
                  className="col-card-product col-sm-6 col-md-4 col-lg-3"
                  onClick={goTo.bind(
                    this,
                    `/store/${storeId}/products/edit/${product.id}`
                  )}
                >
                  <div className="card-product">
                    <div
                      className={`product-available ${
                        product.available && "available"
                      }`}
                    />
                    <img src={product.imageUrl} />
                    <div className="product-info">
                      <h6>{product.name}</h6>
                      <p>Prix: {product.price} CDF</p>
                      <p>
                        Stock: {product.stock} {product.unit}
                      </p>
                    </div>
                  </div>
                </div>
              )):<EmptyDataList message="Aucun resultat de recherche!"/>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListProducts;
