import React, { useState } from "react";
import { ListGroupItem, Form, Input, Button, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import {useFirestore} from "react-redux-firebase"
import ToastMessage from "../toast/toast"

const NewGaleryCategoryForm = () => {
  const { register, handleSubmit } = useForm();
  const [isVisible, setisVisible] = useState(false)
  const [isSave, setisSave] = useState(false)
  const [showSuccess, setshowSuccess] = useState(false);
  const [showDanger, setshowDanger] = useState(false);
  const db = useFirestore()

  const handleForm = () => {
    setisVisible(true);
  };
  const handleShowSuccess = () => {
    setshowSuccess(!showSuccess);
  };
  const handleShowDanger = () => {
    setshowDanger(!showDanger);
  };
  const onSubmit = data => {
    setisSave(true)
    db.add("categories",{category:data.category, createAt:db.Timestamp.now().toMillis()} )
      .then(res => {
        setisSave(false)
        setisVisible(false)
        handleShowSuccess()
      })
      .catch(err => {
        setisSave(false)
        handleShowDanger()
      });
  };

  return (
    <React.Fragment>
      <ListGroupItem className="list-categories-footer text-center text-muted">
        <p className="my-0" onClick={handleForm}>
          <FontAwesomeIcon icon={faPlusCircle} /> Ajouter une categorie
        </p>
        {isVisible && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="pt-3">
              <Input
                bsSize="sm"
                name="category"
                placeholder="Nom de la catégorie"
                disabled={isSave ? true : false}
                innerRef={register({
                  required: true,
                  minLength: 2
                })}
              />
              <Button
                type="submit"
                size="sm"
                className="ml-1 font-weight-bold"
                disabled={isSave ? true : false}
              >
                {!isSave ? (
                  "Enregistrer"
                ) : (
                  <Spinner color="light" className="spinner-isSaving" />
                )}
              </Button>
            </div>
          </Form>
        )}
      </ListGroupItem>
      <ToastMessage
        isShow={showSuccess}
        toggle={handleShowSuccess}
        message="Enregistré"
        delay={4000}
        icon="check"
        className="bg-success text-white pr-5 mr-3"
      />
      <ToastMessage
        isShow={showDanger}
        toggle={handleShowDanger}
        message="Ouf, une erreur est survenue!"
        delay={5000}
        icon="check"
        className="bg-danger text-white pr-5"
      />
    </React.Fragment>
  );
};

export default NewGaleryCategoryForm;
